import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button, Tooltip, TablePagination, TextField } from "@mui/material";
import { FaCheck, FaSearch, FaCopy } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import PageHeader from "../../components/molecules/PageHeader";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { get_industry_drd } from "../../features/IndustrySlice";
import { get_batchlist } from "../../features/batchListSlice";
import api from "../../services/api";
import { toast } from "react-toastify";
import { DotsLoader, Loader } from "../../components";
import AutoRenewModal from "./AutoRenewModal";
import AddGSCEmailsModal from "./AddGSCEmailsModal";
import exportDomainReport from "../Domains/exportDomainReport";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import VendorsListModal from "./VendorsList";
import LeadJobListModal from "./LeadJobList";
import dayjs from "dayjs";
import {
  MdAddCircle,
  MdAddCircleOutline,
  MdDelete,
  MdDownload,
  MdRefresh,
  MdUpdate,
} from "react-icons/md";
import CreateBatchModal from "./CreateBatchModal";
import SaveFilterModal from "./SaveFilterModal";
import FiltersDropdown from "./FiltersDropdown";
import _ from "lodash";
import { domain_number_set_pending } from "../../services/domainCenter";
import { BiPurchaseTag } from "react-icons/bi";
import AddSeoBatches from "./AddSeoBatches";
import { TbSeo } from "react-icons/tb";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";
import { useLocation } from "react-router-dom";
import UpdateDNSModal from "./UpdateDNSModal";
import DNSVerificationResultModal from "./DNSVerificationResultModal";
import ResetDomainCloudflare from "./ResetDomainCloudflare";
import CheckAuctionData from "./CheckAuctionData";
const DomainsReport = () => {
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const dispatch = useDispatch();
  const columnsRef = useRef();
  const [selectedFilter, setSelectedFilter] = useState("total_domains");
  const [searchText, setSearchText] = useState("");
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialColumns = [
    "checkbox",
    "counter",
    "domain",
    "phone",
    "state",
    "city",
    "generate_domain_by",
    "location",
    "population",
    "purchase_date",
    "expiration_date",
    "vendors",
    "inNet_vendors",
    "outNet_vendors",
    "in_area",
    "state_code",
    "domain_indexed",
    "auto_renew",
    "is_expired",
    "check_indexing",
    "pk_latest_position",
    "job_count",
    "lead_count",
    "available_to_repurchase",
    "cc_did_info",
    "industry_id",
    "start_phone_purchase",
    "phone_purchase_log",
    "SEO_batch_id",
    "inteliquent_phone_status",
  ];
  const [queryOptions, setQueryOptions] = useState({
    groupOp: "AND",
    rules: [
      {
        field: "is_expired",
        op: "eq",
        data: false,
      },
      ...(queryParams.get("domain")
        ? [
            {
              field: "domain",
              op: "cn",
              data: queryParams.get("domain"),
            },
          ]
        : []),
      ...(queryParams.get("industry_id")
        ? [
            {
              field: "industry_id",
              op: "eq",
              data: queryParams.get("industry_id"),
            },
            {
              field: "generate_domain_by",
              op: "not",
              data: "nation",
            },
          ]
        : []),
    ],
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  const [isBatch, setIsBatch] = React.useState(false);
  const [renewStatus, setRenewStatus] = useState(1);
  const [isFilterSave, setIsFilterSave] = useState(false);
  const [renewModal, setRenewModal] = useState(false);
  const [gscEmailModal, setGscEmailModal] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const [vendors, setVendors] = useState(null);
  const [countModal, setCountModal] = useState(null);
  const [selectedColumns, setSelectedColumns] = useState(initialColumns);
  const [isLoader, setIsLoader] = useState(false);
  const { industryDrd } = useSelector((state) => state.industry);
  const { batchListDrd } = useSelector((state) => state.batchList);
  const { isLoading } = useSelector((state) => state.domainCenter);
  const [loading, setLoading] = useState(false);
  const [verificationResult, setVerificationResult] = useState(null);
  const [domains, setDomains] = useState(null);
  const [isSeoBatches, setIsSeoBatches] = React.useState(false);
  const [isDNSserverIp, setDNSserverIp] = React.useState({
    isDNSserverIp: false,
    method: "",
  });
  const [isResetDomainCloud, setResetDomainCloud] = React.useState(false);
  const [isCheckAuctionData, setCheckAuctionData] = React.useState(false);
  const generateColumnVisibilityModel = (columns, selectedColumns) => {
    return columns.reduce((model, column) => {
      if (!selectedColumns.includes(column?.column) && column?.column !== "") {
        model[column.field] = false;
      }
      return model;
    }, {});
  };
  const statusOption = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const matchedOption = [
    { value: "NotChecked", label: "NotChecked" },
    { value: "Matched", label: "Matched" },
    { value: "NotMatched", label: "NotMatched" },
    { value: "NotInCC", label: "NotInCC" },
  ];
  const autoRenewOption = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" },
    {
      value: "Domain is not active, or does not belong to this user",
      label: "Domain is not active",
    },
  ];
  const deleteStatusOptions = [
    { value: "ActiveDomain", label: "Active Domain" },
    { value: "PendingToImgDelete", label: "Pending To Img Delete" },
    { value: "PendingToDataDelete", label: "Pending To Data Delete" },
    { value: "Deleted", label: "Deleted" },
  ];
  const calculateExpirationDate = (daysUntilExpiration, operator) => {
    const currentDate = new Date();
    const expiryDate = new Date(currentDate);
    expiryDate.setDate(currentDate.getDate() + +daysUntilExpiration);

    if (operator === "eq") {
      expiryDate.setDate(expiryDate.getDate() + 1);
    }

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    if (operator === "eq") {
      return {
        startWith: formatDate(currentDate),
        endWith: formatDate(expiryDate),
      };
    } else {
      return {
        data: formatDate(expiryDate),
      };
    }
  };

  const customOperators = [
    {
      label: "Yes",
      value: "arraySizeNotZero",
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.value) {
          return null;
        }
        return 0;
      },
    },
    {
      label: "No",
      value: "arraySize",
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.value) {
          return null;
        }
        return 0;
      },
    },
  ];
  const customPhoneDis = [
    {
      label: "Yes",
      value: "not",
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.value) {
          return null;
        }
        return 0;
      },
    },
    {
      label: "No",
      value: "is",
      getApplyFilterFn: (filterItem) => {
        if (!filterItem.value) {
          return null;
        }
        return 0;
      },
    },
  ];
  const getSelectedColumnDependencies = (selectedColumns, columnDefs) => {
    const dependencies = new Set();

    columnDefs.forEach((colDef) => {
      if (selectedColumns.includes(colDef.field) && colDef.dependency) {
        colDef.dependency.forEach((dep) => {
          if (!selectedColumns.includes(dep)) {
            dependencies.add(dep);
          }
        });
      }
    });

    return Array.from(dependencies);
  };
  const get_domains = async (filters) => {
    setLoading(true);
    let filter = { ...filters?.filters };
    if (filter?.rules && filter?.rules?.length > 0) {
      filter.rules.forEach((rule) => {
        if (rule.field === "latest_PK_ahref_keyword_volume.volume") {
          rule.field = "latest_PK_ahref_keyword_volume.volume";
          rule.data = rule.data === "-1" ? null : parseInt(rule.data);
        }
        if (rule.field === "latest_PK_ahref_keyword_difficulty.difficulty") {
          rule.field = "latest_PK_ahref_keyword_difficulty.difficulty";
          rule.data = rule.data === "-1" ? null : parseInt(rule.data);
        }
        if (rule.field === "pk_latest_position.position") {
          rule.field = "pk_latest_position.position";
          rule.data = parseInt(rule.data);
        }
        if (rule.field === "latest_auction_data" && rule.data === true) {
          rule.field = "latest_auction_data.data.currentBid";
          rule.op = "gt";
          rule.data = 0;
        }
        if (rule.field === "latest_auction_data" && rule.data === false) {
          rule.field = "latest_auction_data.data";
          rule.data = "No auction data found.";
        }
      });
    }
    let columns = (filters?.selected_columns || selectedColumns)?.filter(
      (val) =>
        val !== "cc_did_info.number" &&
        val !== "cc_did_info.merchant" &&
        val !== "cc_did_info.ring_group"
    );
    if (
      filter?.rules?.some(
        ({ field, op }) =>
          field === "in_area" ||
          field === "expiring_in_days" ||
          field === "domain_indexed" ||
          op === "arraySize"
      )
    ) {
      filter.rules = filter.rules.map((filter) => {
        if (filter.field === "in_area") {
          return {
            ...filter,
            field: "inNet_vendors",
            op: filter.data ? "arraySizeNotZero" : "arraySize",
            data: 0,
          };
        } else if (filter.field === "expiring_in_days" && filter.op === "eq") {
          return {
            ...filter,
            field: "expiration_date",
            op: "between",
            data: calculateExpirationDate(filter?.data, filter.op),
          };
        } else if (filter.field === "expiring_in_days" && filter.op !== "eq") {
          const newDate = calculateExpirationDate(filter?.data, filter.op);
          return {
            ...filter,
            field: "expiration_date",
            op: filter.op,
            data: newDate?.data,
          };
        } else if (filter.field === "domain_indexed") {
          let updatedFilter = { ...filter };
          if (filter.data === "Indexed") {
            updatedFilter = {
              ...filter,
              field: "domain_indexed.is_indexed",
              op: "eq",
              data: "Indexed",
            };
          } else if (filter.data === "NotIndexed") {
            updatedFilter = {
              ...filter,
              field: "domain_indexed.is_indexed",
              op: "eq",
              data: "NotIndexed",
            };
          } else if (filter.data === "Checked") {
            updatedFilter = {
              ...filter,
              field: "domain_indexed",
              op: "not",
              data: null,
            };
          } else if (filter.data === "NotChecked") {
            updatedFilter = {
              ...filter,
              field: "domain_indexed",
              op: "eq",
              data: null,
            };
          }
          return updatedFilter;
        } else if (filter.op === "arraySize") {
          return {
            ...filter,
            data: 0,
          };
        } else {
          return filter;
        }
      });
    }

    const dependencies = getSelectedColumnDependencies(columns, columnDefs);
    try {
      const res = await api.post(`/api/domains/list`, {
        ...filters,
        filters: filter?.rules?.length ? filter : null,
        selected_columns: [
          ...new Set([
            ...columns,
            "phone_disconnected",
            "delete_status",
            "DNS_status",
            ...dependencies,
          ]),
        ]
          ?.toString()
          ?.replaceAll(",", " ")
          ?.replaceAll("  ", " "),
      });
      if (res.status === 200) {
        setDomains(res.data);
      }
      setLoading(false);
    } catch (error) {
      console.log("🚀 ~ constget_domains=async ~ error:", error);
      setLoading(false);
    }
  };

  const handleNewFilter = () => {
    setFilterModel({
      items: queryOptions.rules?.map(({ field, op, data }) => ({
        field: field,
        operator: operator({ operator: op, field }),
        value: data,
      })),
      logicOperator: "and",
      quickFilterValues: [],
      quickFilterLogicOperator: "and",
    });
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });

    queryOptions.rules.forEach((rule) => {
      if (rule.field === "latest_PK_ahref_keyword_volume.volume") {
        rule.field = "latest_PK_ahref_keyword_volume.volume";
        rule.data = rule.data === "-1" ? null : parseInt(rule.data);
      }
      if (rule.field === "latest_PK_ahref_keyword_difficulty.difficulty") {
        rule.field = "latest_PK_ahref_keyword_difficulty.difficulty";
        rule.data = rule.data === "-1" ? null : parseInt(rule.data);
      }
      if (rule.field === "pk_latest_position.position") {
        rule.field = "pk_latest_position.position";
        rule.data = parseInt(rule.data);
      }
      if (rule.field === "latest_auction_data" && rule.data === true) {
        rule.field = "latest_auction_data.data.currentBid";
        rule.op = "gt";
        rule.data = 0;
      }
      if (rule.field === "latest_auction_data" && rule.data === false) {
        rule.field = "latest_auction_data.data";
        rule.data = "No auction data found.";
      }
    });

    get_domains({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
    });
  };
  const processFilters = (filters) => {
    return filters.map((filter) => {
      if (
        filter.operator === "isAnyOf" &&
        filter.value?.length &&
        filter.value[0]?.includes(",")
      ) {
        return {
          ...filter,
          value: filter.value[0].split(",").map((item) => item.trim()),
        };
      }
      return filter;
    });
  };
  const onFilterChange = React.useCallback((filterModel) => {
    const item = processFilters(filterModel.items);
    setFilterModel({
      ...filterModel,
      items: item,
    });
    if (filterModel?.items?.length === 0) {
      get_domains({
        ...sortingModel,
        filters: null,
        page: 1,
        size: paginationModel.pageSize,
      });
    }
    let ruless = [];
    // eslint-disable-next-line array-callback-return
    processFilters(filterModel.items)?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator === "onOrBefore"
              ? "lte"
              : rule.operator === "before"
              ? "lt"
              : rule.operator === "onOrAfter"
              ? "gte"
              : rule.operator === "after"
              ? "gt"
              : rule.operator,
          data:
            rule.field === "pk_latest_position.position"
              ? +rule.value
              : rule.value || rule.value === 0 || rule.value === false
              ? rule.value
              : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    get_domains({
      ...sortingModel,
      filters: queryOptions,
      page: paginationModel?.page,
      size: paginationModel.pageSize,
    });
    dispatch(get_industry_drd());
    // eslint-disable-next-line
  }, []);
  const domainList = domains?.data?.filter((e) => {
    return Object.keys(e)?.some(
      (key) =>
        e[key] &&
        e[key]?.toString()?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  });
  const getLatestIndex = async () => {
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;
    setLoading(true);
    const domains = selectedData?.map(({ domain }) => domain);
    try {
      const res = await api.post(`/api/domains/check_domain_indexing`, {
        domains,
      });
      if (res.status === 201) {
        toast.success(
          res?.data?.message || "Process start to get the latest index"
        );
      } else {
        toast.error(res?.data?.message || "Process couldn't be started");
      }
      setLoading(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.message || "Process couldn't be started"
      );
      console.log("🚀 ~ getLatestPosition ~ err:", err);
      setLoading(false);
    }
  };
  const verify_domain_dns = async () => {
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;
    setLoading(true);
    const domains = selectedData?.map(({ domain }) => domain);
    try {
      const res = await api.post(`/api/domains/verify_domain_dns`, {
        domains,
      });
      if (res.status === 201 || res.status === 200) {
        setVerificationResult(res.data);
      } else {
        toast.error(res?.data?.message || "DNS varification failed");
      }
      setLoading(false);
    } catch (err) {
      toast.error(err?.response?.data?.message || "DNS varification failed");
      console.log("🚀 ~ getLatestPosition ~ err:", err);
      setLoading(false);
    }
  };
  const checkForRepurchase = async () => {
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;
    setLoading(true);
    const domains = selectedData?.map(({ domain }) => domain);
    try {
      const res = await api.post(`/api/domains/check_for_repurchase`, {
        domains,
      });
      if (res.status === 201) {
        toast.success(
          res?.data?.message ||
            "The process has started to check if the domain is available for repurchase."
        );
      } else {
        toast.error(res?.data?.message || "Process couldn't be started");
      }
      setLoading(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.message || "Process couldn't be started"
      );
      console.log("🚀 ~ checkForRepurchase ~ err:", err);
      setLoading(false);
    }
  };
  const domainsRepurchase = async () => {
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;
    setLoading(true);
    const domains = selectedData?.map(({ domain }) => domain);
    try {
      const res = await api.post(`api/domains/repurchase`, {
        domains,
      });
      if (res.status === 201 || res.status === 200) {
        toast.success(
          res?.data?.message ||
            "The process has started to repurchase the domains."
        );
      } else {
        toast.error(res?.data?.message || "Process couldn't be started");
      }
      setLoading(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.message || "Process couldn't be started"
      );
      console.log("🚀 ~ domainsRepurchase ~ err:", err);
      setLoading(false);
    }
  };
  const purchasePhoneNumber = async () => {
    if (selectedData?.filter(({ phone }) => phone)?.length)
      return toast.error("Please select the domain without phone number");
    if (
      selectedData?.filter(({ start_phone_purchase }) => !!start_phone_purchase)
        ?.length
    )
      return toast.error("Some domains are already in purchase phone process");
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;
    setLoading(true);

    const domains = selectedData?.map(({ domain }) => domain);
    try {
      const res = await api.post(`api/domains/purchase_domains_phone`, {
        domains,
      });
      if (res.status === 201 || res.status === 200) {
        toast.success(
          res?.data?.message ||
            `${res?.data?.updatedCount} records have been updated.`
        );
        setSelectedData([]);
      } else {
        toast.error(res?.data?.message || "Process couldn't be started");
      }
      setLoading(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.message || "Process couldn't be started"
      );
      console.log("🚀 ~ domainsRepurchase ~ err:", err);
      setLoading(false);
    }
  };
  const disconnectNumbers = async () => {
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;
    setLoading(true);
    if (selectedData?.length > 100)
      return toast.error("Please select a maximum of 100 domains at a time.");
    const domains = selectedData?.map(({ domain }) => domain);
    try {
      const res = await api.post(`api/domains/phone_disconnect`, {
        domains,
      });
      if (res.status === 201 || res.status === 200) {
        toast.success("Phone Number disconnected successfully");
        setSelectedData([]);
      } else {
        toast.error(
          res?.data?.message ||
            res?.data?.error ||
            res?.data ||
            "Phone Number couldn't be disconnected"
        );
      }
      setLoading(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.message ||
          err?.response?.data?.error ||
          err?.response?.data ||
          "Phone Number couldn't be disconnected"
      );
      console.log("🚀 ~ domainsRepurchase ~ err:", err);
      setLoading(false);
    }
  };
  const getLatestPosition = async (domain) => {
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;
    setLoading(true);
    try {
      const res = await api.post(`/api/domains/check_pk_latest_position`, {
        domain,
      });
      if (res.status === 200) {
        toast.success(
          res?.data?.error ||
            res?.data?.message ||
            "Get the latest position successfully"
        );
      } else {
        toast.error(
          res?.data?.error ||
            res?.data?.message ||
            "Couldn't get the latest position"
        );
      }
      setLoading(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.error ||
          err?.response?.data?.message ||
          "Couldn't get the latest position"
      );
      console.log("🚀 ~ getLatestPosition ~ err:", err);
      setLoading(false);
    }
  };
  const startPhoneVerification = async () => {
    const c = window.confirm(
      "Are you sure want to start the Domain Phone number verification?"
    );
    if (!c) return;
    setLoading(true);
    try {
      const res = await api.get(`api/domains/start_domain_phone_verification`);
      if (res.status === 200) {
        toast.success(
          res?.data?.error ||
            res?.data?.message ||
            "Domain Phone number verification started"
        );
      } else {
        toast.error(
          res?.data?.error ||
            res?.data?.message ||
            "Domain Phone number verification couldn't be started"
        );
      }
      setLoading(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.error ||
          err?.response?.data?.message ||
          "Domain Phone number verification couldn't be started"
      );
      console.log("🚀 ~ getLatestPosition ~ err:", err);
      setLoading(false);
    }
  };
  const handleRenewDomains = async () => {
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;
    setLoading(true);
    const domains = selectedData?.map(({ domain }) => domain);
    try {
      const res = await api.post(`/api/domains/renew_domain`, {
        domains,
      });
      if (res.status === 200) {
        toast.success(res?.data?.error || res?.data || "Domain has been renew");
      } else {
        toast.error(
          res?.data?.error || res?.data || "Domain couldn't be renew"
        );
      }
      setLoading(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.error ||
          err?.response?.data?.message ||
          err?.response?.data ||
          "Domain couldn't be renew"
      );
      console.log("🚀 ~ getLatestPosition ~ err:", err);
      setLoading(false);
    }
  };
  const onSelectAll = (checked) => {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (checked) {
      const updatedArray = domainList?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };
  const isSelected = (item) => {
    if (selectedData?.length > 0) {
      if (
        selectedData?.filter(({ domain }) => domain === item.domain)?.length > 0
      ) {
        return true;
      }
    }
    return false;
  };
  const onSingleSelect = ({ checked, data }) => {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          const updatedSelectedData = [...prevSelectedData, data];
          return updatedSelectedData;
        });
      } else {
        setSelectedData((prevSelectedRecord) => {
          const updatedSelectedRecord = prevSelectedRecord?.filter(
            ({ domain }) => domain !== data?.domain
          );
          return updatedSelectedRecord;
        });
      }
    } catch (err) {
      console.log("🚀 ~ file: index.jsx:116 ~ onSingleSelect ~ err:", err);
    }
  };
  const calculateDaysUntilExpiration = (expirationDate) => {
    const currentDate = new Date();
    const expiryDate = new Date(expirationDate);

    const diffTime = expiryDate - currentDate;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); // Floor to get complete days

    return diffDays;
  };
  const updateNumber = async () => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await domain_number_set_pending();
        if (res.status === 200) {
          toast.success(res?.data?.message);
        }
      } catch (err) {
        console.log(
          "🚀 ~ file: UpdateNumberModal.jsx:45 ~ updateNumber ~ err:",
          err
        );
        toast.success("Getting an error");
      }
    }
  };
  const checkboxColumn = [
    {
      field: "checkbox",
      column: "",
      disableColumnMenu: true,
      renderHeader: () => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2 ml-1`}
          checked={selectedData?.length === domainList?.length}
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <div className="flex justify-center w-full items-center">
          {isSelected(params.row.records) && isLoader ? (
            <Loader />
          ) : (
            <input
              type="checkbox"
              checked={isSelected(params.row.records)}
              onChange={(e) =>
                onSingleSelect({
                  checked: e.target.checked,
                  data: params.row.records,
                })
              }
              className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
            />
          )}
        </div>
      ),
      hideable: false,
    },
  ];

  const CustomSelectInput = ({ item, applyValue }) => {
    const [value, setValue] = useState(item.value || []);

    const handleChange = (event) => {
      const selectedValues = event.target.value;
      setValue(selectedValues);
      applyValue({ ...item, value: selectedValues });
    };

    return (
      <FormControl fullWidth>
        <InputLabel>Value</InputLabel>
        <Select
          multiple
          value={value}
          onChange={handleChange}
          renderValue={(selected) => {
            const selectedOptions = batchListDrd.filter((option) =>
              selected.includes(option._id)
            );
            return selectedOptions
              .map((option) => option.batch_name)
              .join(", ");
          }}
          sx={{
            "& .MuiSelect-select": {
              border: "none",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        >
          {batchListDrd?.map((option) => (
            <MenuItem key={option._id} value={option._id}>
              {option.batch_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const createFilterOperator = (label, value, filterType) => ({
    label,
    value,
    InputComponent: CustomSelectInput,
    InputComponentProps: { type: "multipleSelect" },
    getApplyFilterFn: (filterItem) => {
      const filterValues = Array.isArray(filterItem.value)
        ? filterItem.value
        : [filterItem.value];
      return (cellValue) => {
        if (filterType === "anyOf") {
          return filterValues.includes(cellValue);
        } else if (filterType === "notAnyOf") {
          return !filterValues.includes(cellValue);
        }
        return false;
      };
    },
  });

  // Define the custom filter operators
  const isAnyOfOperator = createFilterOperator("is any of", "isAnyOf", "anyOf");
  const isNotAnyOfOperator = createFilterOperator(
    "is not any of",
    "isNotAnyOf",
    "notAnyOf"
  );

  const CustomTextInput = ({ item, applyValue }) => {
    const [value, setValue] = useState(item.value);
    return (
      <div fullWidth>
        <TextField
          value={value || ""}
          variant="standard"
          label="Value"
          onChange={(event) => {
            setValue(event.target.value);
            applyValue({ ...item, value: event.target.value });
          }}
          fullWidth
        />
      </div>
    );
  };
  const expiringDaysOperators = [
    {
      label: "=",
      value: "=",
      InputComponent: CustomTextInput,
      InputComponentProps: { type: "number" },
      getApplyFilterFn: (filterItem) => {
        return (params) => {
          const value = Number(params.value);
          const filterValue = Number(filterItem.value);
          return value === filterValue;
        };
      },
    },
    {
      label: ">",
      value: ">",
      InputComponent: CustomTextInput,
      InputComponentProps: { type: "number" },
      getApplyFilterFn: (filterItem) => {
        return (params) => {
          const value = Number(params.value);
          const filterValue = Number(filterItem.value);
          return value > filterValue;
        };
      },
    },
    {
      label: ">=",
      value: ">=",
      InputComponent: CustomTextInput,
      InputComponentProps: { type: "number" },
      getApplyFilterFn: (filterItem) => {
        return (params) => {
          const value = Number(params.value);
          const filterValue = Number(filterItem.value);
          return value >= filterValue;
        };
      },
    },
    {
      label: "<",
      value: "<",
      InputComponent: CustomTextInput,
      InputComponentProps: { type: "number" },
      getApplyFilterFn: (filterItem) => {
        return (params) => {
          const value = Number(params.value);
          const filterValue = Number(filterItem.value);
          return value < filterValue;
        };
      },
    },
    {
      label: "<=",
      value: "<=",
      InputComponent: CustomTextInput,
      InputComponentProps: { type: "number" },
      getApplyFilterFn: (filterItem) => {
        return (params) => {
          const value = Number(params.value);
          const filterValue = Number(filterItem.value);
          return value <= filterValue;
        };
      },
    },
  ];

  const columnDefs = [
    ...checkboxColumn,
    {
      field: "counter",
      column: "",
      headerName: "#",
      type: "string",
      minWidth: 60,
      maxWidth: 80,
      filterable: false,
      hideable: false,
    },
    {
      field: "domain",
      column: "domain",
      dependency: ["generate_domain_by", "delete_status"],
      headerName: "Domain",
      hideable: false,
      renderCell: (params) => {
        return (
          <div className="flex flex-col">
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`https://${params?.value}`}
              className="text-black no-underline hover:underline cursor-poi"
            >
              {params?.value}
            </a>
            {params.row.generate_domain_by !== "nation" &&
              (params.row.delete_status === "Deleted" ? (
                <small className="text-red-600">
                  {
                    deleteStatusOptions?.find(
                      ({ value }) => value === params.row.delete_status
                    )?.label
                  }
                </small>
              ) : params.row.delete_status === "ActiveDomain" ? (
                <small className="text-green-600">
                  {
                    deleteStatusOptions?.find(
                      ({ value }) => value === params.row.delete_status
                    )?.label
                  }
                </small>
              ) : (
                <small>
                  {
                    deleteStatusOptions?.find(
                      ({ value }) => value === params.row.delete_status
                    )?.label
                  }
                </small>
              ))}
          </div>
        );
      },
      flex: 1,
      minWidth: 250,
    },
    {
      headerName: "Phone Disconnected",
      column: "",
      field: "phone_disconnected",
      flex: 1,
      minWidth: 120,
      filterOperators: customPhoneDis,
      type: "singleSelect",
    },
    {
      field: "phone",
      column: "phone",
      headerName: "Phone",
      dependency: ["cc_did_info", "phone_disconnected"],
      type: "string",
      flex: 1,
      minWidth: 130,
      renderCell: (params) => (
        <div className="flex flex-col">
          <span>{params?.row?.phone}</span>
          {`1${params.row.phone}` !== params?.row?.cc_did_info?.number &&
          selectedColumns?.includes("cc_did_info") &&
          params.row.delete_status !== "Deleted" &&
          params.row.generate_domain_by !== "nation" ? (
            <small className="text-red-500">CC Number not matched</small>
          ) : params.row.delete_status === "Deleted" &&
            params.row.phone_disconnected?.on_date ? (
            <small className="text-red-500">{`Disconnected on: ${dayjs(
              params.row.phone_disconnected?.on_date
            ).format("MM-DD-YYYY")}`}</small>
          ) : null}
        </div>
      ),
    },
    {
      field: "inteliquent_phone_status",
      column: "",
      headerName: "Inteliquent Status",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: [
        { value: "Not-Checked", label: "Not-Checked" },
        { value: "Not-Exists", label: "Not-Exists" },
        { value: "Checked", label: "Checked" },
      ],
      renderCell: (params) => params.value?.inteliquent_phone_status,
      valueGetter: (params) => params.row?.inteliquent_phone_status,
      valueFormatter: (params) => params.value?.inteliquent_phone_status,
    },
    {
      field: "state",
      column: "state",
      headerName: "State",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "city",
      column: "city",
      headerName: "City",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Generated By",
      column: "generate_domain_by",
      field: "generate_domain_by",
      flex: 1,
      minWidth: 110,
      type: "singleSelect",
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        generate_domain_by: value?.value,
      }),
      valueOptions: [
        { value: "city", label: "City" },
        { value: "city_alias", label: "City Alias" },
        { value: "nation", label: "National" },
      ],
    },
    {
      headerName: "Domain Location",
      column: "location",
      field: "location",
      flex: 1,
      minWidth: 120,
    },
    {
      headerName: "Population",
      column: "population",
      field: "population",
      flex: 1,
      type: "number",
      minWidth: 100,
    },
    {
      headerName: "Purchased date",
      column: "purchase_date",
      field: "purchase_date",
      flex: 1,
      type: "date",
      minWidth: 120,
      valueGetter: (params) => new Date(params.row.purchase_date),
      renderCell: (params) => {
        const rawDate = params.row.purchase_date;

        if (rawDate) {
          return rawDate;
        }

        return "";
      },
    },
    {
      headerName: "Expiration",
      column: "expiration_date",
      field: "expiration_date",
      flex: 1,
      type: "date",
      minWidth: 120,
      valueGetter: (params) => new Date(params.row.expiration_date),
      renderCell: (params) => {
        const rawDate = params.row.expiration_date;

        if (rawDate) {
          return rawDate;
        }

        return "";
      },
    },
    {
      headerName: "Vendor Count",
      column: "vendors",
      field: "vendors",
      flex: 1,
      minWidth: 120,
      filterOperators: customOperators,
      renderCell: (params) =>
        params?.row?.vendors?.length ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() =>
              setVendors({
                vendors: params?.row?.vendors,
                domain: params?.row["domain"],
                industry_id: params?.row?.records?.industry_id,
              })
            }
          >
            {params?.row?.vendors?.length ?? 0}
          </span>
        ) : (
          <span>0</span>
        ),
    },
    {
      headerName: "InNet Vendors",
      column: "inNet_vendors",
      field: "inNet_vendors",
      flex: 1,
      minWidth: 120,
      filterOperators: customOperators,
      renderCell: (params) =>
        params?.row?.inNet_vendors?.length ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() =>
              setVendors({
                vendors: params?.row?.inNet_vendors,
                domain: params?.row["domain"],
                industry_id: params?.row?.records?.industry_id,
              })
            }
          >
            {params?.row?.inNet_vendors?.length ?? 0}
          </span>
        ) : (
          <span>0</span>
        ),
    },
    {
      headerName: "OutNet Vendors",
      column: "outNet_vendors",
      field: "outNet_vendors",
      flex: 1,
      minWidth: 120,
      filterOperators: customOperators,
      renderCell: (params) =>
        params?.row?.outNet_vendors?.length ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() =>
              setVendors({
                vendors: params?.row?.outNet_vendors,
                domain: params?.row["domain"],
                industry_id: params?.row?.records?.industry_id,
              })
            }
          >
            {params?.row?.outNet_vendors?.length ?? 0}
          </span>
        ) : (
          <span>0</span>
        ),
    },
    {
      headerName: "In/Out Area",
      column: "in_area",
      field: "in_area",
      flex: 1,
      minWidth: 100,
      type: "singleSelect",
      dependency: ["inNet_vendors"],
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        in_area: value?.value,
      }),
      valueOptions: [
        { value: true, label: "In Area" },
        { value: false, label: "Out Area" },
      ],
      renderCell: (params) => (
        <span>
          {params?.row?.inNet_vendors?.length ? "In Area" : "Out Area"}
        </span>
      ),
    },
    {
      field: "state_code",
      column: "state_code",
      headerName: "State Code",
      flex: 1,
      minWidth: 95,
    },
    {
      field: "server_ip",
      column: "server_ip",
      headerName: "GSC IP",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "gsc_server_sync_status",
      column: "gsc_server_sync_status",
      headerName: "GSC Status",
      flex: 1,
      minWidth: 120,
    },

    {
      field: "auth_email",
      column: "auth_email",
      headerName: "Cloudflare Email",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "cloudflare_zone_id",
      column: "cloudflare_zone_id",
      headerName: "Zone ID",
      flex: 1,
      minWidth: 200,
    },
    {
      field: "cloudflare_verification_status",
      column: "cloudflare_verification_status",
      headerName: "Cloudflare Verify",
      flex: 1,
      minWidth: 200,
      type: "singleSelect",
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        cloudflare_verification_status: value?.value,
      }),
      valueOptions: [
        { value: "Pending", label: "Pending" },
        { value: "Done", label: "Done" },
        { value: "Error", label: "Error" },
      ],
    },

    {
      headerName: "Indexed",
      column: "domain_indexed",
      field: "domain_indexed",
      dependency: ["check_indexing"],
      flex: 1,
      minWidth: 100,
      type: "singleSelect",
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        is_indexed: value?.value,
      }),
      valueOptions: [
        { value: "Indexed", label: "Indexed" },
        { value: "NotIndexed", label: "Not Indexed" },
        { value: "Checked", label: "Checked" },
        { value: "NotChecked", label: "Not Checked" },
      ],
      renderCell: (params) =>
        params.row?.domain_indexed ? (
          params.row?.domain_indexed?.is_indexed === "NotIndexed" ||
          params.row?.domain_indexed?.is_indexed === "NotChecked" ? (
            <div className="flex flex-col">
              <span
              // className="text-blue-600 hover:underline cursor-pointer"
              // onClick={() => getLatestIndex(params.row.domain)}
              >
                {params?.row?.domain_indexed?.is_indexed}
              </span>
              {params?.row?.records?.check_indexing ? (
                <span>{"In Process"}</span>
              ) : (
                <span>
                  {dayjs(params?.row?.domain_indexed?.on_date).format(
                    "MM-DD-YYYY HH:mm"
                  )}
                </span>
              )}
            </div>
          ) : (
            <div className="flex flex-col">
              <span>{params?.row?.domain_indexed?.is_indexed}</span>
              {params?.row?.records?.check_indexing ? (
                <span>{"In Process"}</span>
              ) : (
                <span>
                  {dayjs(params?.row?.domain_indexed?.on_date).format(
                    "MM-DD-YYYY HH:mm"
                  )}
                </span>
              )}
            </div>
          )
        ) : (
          <span>{"Not Checked"}</span>
        ),
    },
    {
      field: "expiring_in_days",
      column: "expiring_in_days",
      headerName: "Expiring in Days",
      flex: 1,
      minWidth: 100,
      type: "number",
      renderCell: (params) => (
        <span>
          {calculateDaysUntilExpiration(params?.row?.records?.expiration_date)}
        </span>
      ),
      filterOperators: expiringDaysOperators,
    },
    {
      field: "is_expired",
      column: "is_expired",
      headerName: "Expired",
      dependency: ["available_to_repurchase"],
      flex: 1,
      minWidth: 100,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      valueOptions: statusOption,
      valueGetter: (params) => params.row.is_expired,
      valueFormatter: (params) => params.value,
      renderCell: (params) => {
        return (
          <div className="flex flex-col">
            <span>{params.row.is_expired}</span>
            <span>
              {params?.row?.available_to_repurchase
                ? params?.row?.available_to_repurchase.isAvailable
                  ? `Available in  $${
                      params?.row?.available_to_repurchase.price
                    } on ${dayjs(
                      params?.row?.available_to_repurchase.on_date
                    ).format("MM-DD-YYYY HH:mm")}`
                  : `Not Availabe on ${dayjs(
                      params?.row?.available_to_repurchase.on_date
                    ).format("MM-DD-YYYY HH:mm")}`
                : null}
            </span>
          </div>
        );
      },
    },
    {
      field: "available_to_repurchase.isAvailable",
      column: "available_to_repurchase",
      headerName: "Available to Repurhcase",
      flex: 1,
      minWidth: 80,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      valueOptions: statusOption,
      valueGetter: (params) =>
        params.row["available_to_repurchase.isAvailable"],
      valueFormatter: (params) => params.value,
    },
    {
      field: "auto_renew",
      column: "auto_renew",
      headerName: "Auto Renew",
      flex: 1,
      minWidth: 120,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      valueOptions: autoRenewOption,
      valueGetter: (params) => params.row.auto_renew,
      valueFormatter: (params) => params.value,
    },
    {
      field: "industry_id",
      headerName: "Industry",
      column: "industry_id",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      getOptionValue: (value) => value?._id,
      getOptionLabel: (value) => value.name,
      valueOptions: industryDrd,
      valueGetter: (params) => params.row.industry_id,
      renderCell: (params) => params.row.industry_name,
      valueSetter: (params) => {
        const industry = industryDrd.find((ind) => ind._id === params.value);
        return { ...params.row, industry_id: industry ? industry._id : "" };
      },
    },
    {
      headerName: "DR",
      column: "latest_ahref_domain_DR",
      field: "latest_ahref_domain_DR",
      dependency: ["ahref_domain_DR", "ahref_domain_DR_status"],
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
      type: "number",
      valueGetter: (params) => params?.row?.ahref_domain_DR?.length, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => v1 - v2, // Provide a sorting function
      renderCell: (params) =>
        params?.row?.ahref_domain_DR?.length > 0 ? (
          <div>
            <div className="flex justify-between items-center w-full">
              <span>
                {params?.row?.latest_ahref_domain_DR &&
                params?.row?.latest_ahref_domain_DR !== -1
                  ? params?.row?.latest_ahref_domain_DR
                  : "N/A"}
              </span>
              <Tooltip
                title={
                  <div className="flex flex-col text-sm w-[full]">
                    {params?.row?.ahref_domain_DR?.map((item, index) => (
                      <span key={index}>
                        {`${index + 1}. Date: ${dayjs(item?.log_date).format(
                          "ddd, MMM D, YYYY h:mm:ss A"
                        )}, DR: ${item["DR"] || "N/A"}`}
                      </span>
                    ))}
                  </div>
                }
              >
                <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
                  {params?.row?.ahref_domain_DR?.length}
                </span>
              </Tooltip>
            </div>
            {params.row.ahref_domain_DR_status !== "Complete" ? (
              <span>{params.row.ahref_domain_DR_status}</span>
            ) : null}
          </div>
        ) : (
          <span>
            {params?.row?.latest_ahref_domain_DR &&
            params?.row?.latest_ahref_domain_DR !== -1
              ? params?.row?.latest_ahref_domain_DR
              : "N/A"}
          </span>
        ),
    },
    {
      field: "phone_matched_with_cc_status",
      column: "phone_matched_with_cc_status",
      headerName: "CC Number Mismatch",
      flex: 1,
      minWidth: 80,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      valueOptions: matchedOption,
      valueGetter: (params) => params.row.phone_matched_with_cc_status,
      valueFormatter: (params) => params.value,
    },
    {
      headerName: "CC Number",
      column: "cc_did_info",
      field: "cc_did_info.number",
      flex: 1,
      minWidth: 130,
      renderCell: (params) => (
        <div className="flex flex-col">
          <span>{params.row["cc_did_info.number"]}</span>
          {`1${params.row.phone}` !== params.row.cc_did_info?.number ? (
            <small className="text-red-500">Phone not matched</small>
          ) : null}
        </div>
      ),
    },
    {
      headerName: "CC Merchant",
      column: "cc_did_info",
      field: "cc_did_info.merchant",
      flex: 1,
      minWidth: 130,
    },
    {
      headerName: "CC Ring Group",
      column: "cc_did_info",
      field: "cc_did_info.ring_group",
      flex: 1,
      minWidth: 130,
    },
    {
      headerName: "Domain Backlinks",
      column: "latest_ahref_domain_Backlink",
      field: "latest_ahref_domain_Backlink",
      dependency: ["ahref_domain_Backlink", "ahref_domain_Backlink_status"],
      flex: 1,
      align: "center",
      type: "number",
      headerAlign: "center",
      minWidth: 150,
      valueGetter: (params) => params?.row?.ahref_domain_Backlink?.length, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => v1 - v2, // Provide a sorting function
      renderCell: (params) =>
        params?.row?.ahref_domain_Backlink?.length > 0 ? (
          <div className="w-auto min-w-[100px]">
            <div className="flex justify-between items-center w-full">
              <span>
                {params?.row?.latest_ahref_domain_Backlink &&
                params?.row?.latest_ahref_domain_Backlink !== -1
                  ? params?.row?.latest_ahref_domain_Backlink
                  : "N/A"}
              </span>
              <Tooltip
                title={
                  <div className="flex flex-col text-sm w-[full]">
                    {params?.row?.ahref_domain_Backlink?.map((item, index) => (
                      <span key={index}>
                        {`${index + 1}. Date: ${dayjs(item?.log_date).format(
                          "ddd, MMM D, YYYY h:mm:ss A"
                        )}, Backlinks: ${item["Backlinks"] || "N/A"}`}
                      </span>
                    ))}
                  </div>
                }
              >
                <span className="bg-[#93c5fd] py-1 px-2 !rounded hover:cursor-pointer ml-3">
                  {params?.row?.ahref_domain_Backlink?.length}
                </span>
              </Tooltip>
            </div>
            {params.row.ahref_domain_Backlink_status !== "Complete" ? (
              <span>{params.row.ahref_domain_Backlink_status}</span>
            ) : null}
          </div>
        ) : (
          <span>
            {params?.row?.latest_ahref_domain_Backlink &&
            params?.row?.latest_ahref_domain_Backlink !== -1
              ? params?.row?.latest_ahref_domain_Backlink
              : "N/A"}
          </span>
        ),
    },
    {
      headerName: "PK Difficulty",
      column: "latest_PK_ahref_keyword_difficulty",
      field: "latest_PK_ahref_keyword_difficulty.difficulty",
      dependency: ["ahref_PK_keyword_difficulty"],
      flex: 1,
      align: "center",
      type: "number",
      headerAlign: "center",
      minWidth: 120,
      valueGetter: (params) => params?.row?.ahref_PK_keyword_difficulty, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => v1 - v2, // Provide a sorting function
      renderCell: (params) =>
        params?.row?.latest_PK_ahref_keyword_difficulty ? (
          <div className="flex flex-col">
            <span>
              {params?.row?.latest_PK_ahref_keyword_difficulty?.difficulty
                ? params?.row?.latest_PK_ahref_keyword_difficulty?.difficulty
                : "-1"}
            </span>
            <span>
              {params?.row?.latest_PK_ahref_keyword_difficulty?.log_date
                ? dayjs(
                    params?.row?.latest_PK_ahref_keyword_difficulty?.log_date
                  ).format("MM-DD-YYYY HH:mm")
                : null}
            </span>
          </div>
        ) : (
          <span>{"N/A"}</span>
        ),
    },
    {
      headerName: "PK Volume",
      column: "latest_PK_ahref_keyword_volume",
      field: "latest_PK_ahref_keyword_volume.volume",
      dependency: ["ahref_PK_keyword_volume"],
      flex: 1,
      align: "center",
      type: "number",
      headerAlign: "center",
      minWidth: 120,
      valueGetter: (params) => params?.row?.ahref_PK_keyword_volume, // Provide a function to get the sorting value
      sortComparator: (v1, v2, row1, row2) => v1 - v2, // Provide a sorting function
      renderCell: (params) =>
        params?.row?.latest_PK_ahref_keyword_volume ? (
          <div className="flex flex-col">
            <span>
              {params?.row?.latest_PK_ahref_keyword_volume?.volume
                ? params?.row?.latest_PK_ahref_keyword_volume?.volume
                : "-1"}
            </span>
            <span>
              {params?.row?.latest_PK_ahref_keyword_volume?.log_date
                ? dayjs(
                    params?.row?.latest_PK_ahref_keyword_volume?.log_date
                  ).format("MM-DD-YYYY HH:mm")
                : null}
            </span>
          </div>
        ) : (
          <span>{"N/A"}</span>
        ),
    },
    {
      headerName: "Primary Keyword",
      column: "primary_keyword",
      field: "primary_keyword",
      flex: 1,
      minWidth: 130,
    },
    {
      field: "delete_status",
      column: "",
      headerName: "Delete Status",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      getOptionValue: (value) => value?.value,
      getOptionLabel: (value) => value.label,
      valueOptions: deleteStatusOptions,
      renderCell: (params) => params.value?.delete_status,
      valueGetter: (params) => params.row?.delete_status,
      valueFormatter: (params) => params.value?.delete_status,
    },
    {
      field: "runner_name",
      column: "runner_name",
      headerName: "Runner Name",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "county",
      column: "county",
      headerName: "County",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Address",
      column: "address",
      field: "address",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "zip_count",
      column: "zip_count",
      headerName: "Zip Count",
      type: "number",
      flex: 1,
      minWidth: 100,
      align: "center",
      headerAlign: "center",
    },

    {
      headerName: "Latest Position",
      column: "pk_latest_position",
      field: "pk_latest_position.position",
      flex: 1,
      type: "number",
      align: "left",
      headerAlign: "left",
      minWidth: 150,
      renderCell: (params) => (
        <div>
          <div className="flex flex-row gap-x-1 justify-between w-full">
            <span>{params?.row?.pk_latest_position?.position}</span>
            <button
              onClick={() => getLatestPosition(params?.row?.domain)}
              className="float-right"
            >
              <MdDownload />
            </button>
          </div>
          <span>
            {params?.row?.pk_latest_position
              ? dayjs(params?.row?.pk_latest_position?.on_date).format(
                  "MM-DD-YYYY HH:mm"
                )
              : null}
          </span>
        </div>
      ),
    },
    {
      headerName: "DNS Server IP",
      column: "DNSServerIP",
      field: "DNSServerIP",
      flex: 1,
      minWidth: 120,
      renderCell: (params) => (
        <div>
          <div className="flex flex-row gap-x-1 justify-between w-full">
            <span>{params?.row?.DNSServerIP}</span>
          </div>
          {params?.row?.DNS_status ? (
            <span
              className={`text-sm font-medium py-0.5
          ${
            params.row.DNS_status === "Error"
              ? "text-red-600 dark:text-red-600"
              : params.row.DNS_status === "Pending"
              ? "text-yellow-500 dark:text-yellow-500"
              : params.row.DNS_status === "Done"
              ? "text-green-600 dark:text-green-600"
              : ""
          }`}
            >
              {params.row.DNS_status}
            </span>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      headerName: "DNS Status",
      column: "DNS_status",
      field: "DNS_status",
      flex: 1,
      minWidth: 110,
      type: "singleSelect",
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        generate_domain_by: value?.value,
      }),
      valueOptions: [
        { value: "Pending", label: "Pending" },
        { value: "Done", label: "Done" },
        { value: "Error", label: "Error" },
        { value: "ReadyToVerify", label: "ReadyToVerify" },
        { value: "Verified", label: "Verified" },
        { value: "ErrorInVerification", label: "ErrorInVerification" },
      ],
    },
    {
      headerName: "Positioning Status",
      column: "positioning_status",
      field: "positioning_status",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "deployed_on_host",
      column: "deployed_on_host",
      headerName: "Deployed on",
      type: "string",
      flex: 1,
      minWidth: 100,
    },
    {
      headerName: "Search Volume",
      column: "search_volume",
      field: "search_volume",
      flex: 1,
      minWidth: 120,
    },

    {
      field: "expiration_update_date",
      column: "expiration_update_date",
      headerName: "Expiration Update",
      flex: 1,
      minWidth: 95,
    },
    {
      headerName: "Leads",
      column: "lead_count",
      field: "lead_count",
      flex: 1,
      type: "number",
      sortable: true,
      valueGetter: (params) =>
        params?.row["lead_count"] ? params?.row["lead_count"] : 0,
      sortComparator: (v1, v2, row1, row2) => v1 - v2,
      renderCell: (params) =>
        params?.row["lead_count"] ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() =>
              setCountModal({
                phone: params?.row["phone"],
                type: "Leads",
                domain: params?.row["domain"],
              })
            }
          >
            {params?.row["lead_count"] ? params?.row["lead_count"] : 0}
          </span>
        ) : (
          <span>0</span>
        ),
      minWidth: 100,
    },
    {
      headerName: "Jobs",
      column: "job_count",
      field: "job_count",
      flex: 1,
      type: "number",
      sortable: true,
      valueGetter: (params) =>
        params?.row["job_count"] ? params?.row["job_count"] : 0,
      sortComparator: (v1, v2, row1, row2) => v1 - v2,
      renderCell: (params) =>
        params?.row["job_count"] ? (
          <span
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={() =>
              setCountModal({
                phone: params?.row["phone"],
                type: "Jobs",
                domain: params?.row["domain"],
              })
            }
          >
            {params?.row["job_count"] ? params?.row["job_count"] : 0}
          </span>
        ) : (
          <span>0</span>
        ),
      minWidth: 100,
    },
    {
      field: "SEO_batch_id",
      headerName: "SEO Batch",
      column: "SEO_batch_id",
      type: "singleSelect",
      flex: 1,
      minWidth: 150,
      //valueOptions: batchListDrd,
      renderCell: (params) => params.row.SEO_batch_name,
      filterOperators: [
        isAnyOfOperator,
        isNotAnyOfOperator,
        {
          label: "is empty",
          value: "isEmpty",
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return 0;
          },
        },
        {
          label: "is not empty",
          value: "isNotEmpty",
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.value) {
              return null;
            }
            return 0;
          },
        },
      ],
    },
    {
      field: "latest_auction_data",
      column: "latest_auction_data",
      headerName: "Has Any Bid",
      dependency: ["latest_auction_data"],
      minWidth: 200,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      valueOptions: statusOption,
      valueGetter: (params) => params.row.is_expired,
      valueFormatter: (params) => params.value,
      renderCell: (params) => {
        const latest_auction_data = params?.row?.latest_auction_data?.data;
        if (!latest_auction_data || params?.row?.latest_auction_data === null) {
          return "N/A";
        }
        if (
          typeof latest_auction_data === "object" &&
          !Array.isArray(latest_auction_data)
        ) {
          return (
            <div className="flex flex-col">
              <span className="mt-1 bg-primary-100 text-white text-xs font-medium me-2 px-1.5 py-0.5 rounded border">
                On:{" "}
                {params?.row?.latest_auction_data?.date
                  ? dayjs(params?.row?.latest_auction_data?.date).format(
                      "MM-DD-YYYY HH:mm"
                    )
                  : "n/a"}
              </span>
              {latest_auction_data?.currentBid ? (
                <span className="mt-1 bg-blue-100 text-blue-800 text-xs font-medium me-2 px-1.5 py-0.5 rounded border">
                  Current Bid: {latest_auction_data?.currentBid}
                </span>
              ) : null}
              {latest_auction_data?.auctionEndsOnUtc ? (
                <span className="mt-1 bg-blue-100 text-blue-800 text-xs font-medium me-2 px-1.5 py-0.5 rounded border">
                  Auction Ends On:{" "}
                  {latest_auction_data?.auctionEndsOnUtc
                    ? dayjs(latest_auction_data?.auctionEndsOnUtc).format(
                        "MM-DD-YYYY HH:mm"
                      )
                    : "n/a"}
                </span>
              ) : null}
            </div>
          );
        } else {
          return (
            <div className="flex flex-col">
              <span className="mt-1 bg-primary-100 text-white text-xs font-medium me-2 px-1.5 py-0.5 rounded border">
                On:{" "}
                {dayjs(params?.row?.latest_auction_data?.date).format(
                  "MM-DD-YYYY HH:mm"
                )}
              </span>
              <span className="mt-1 bg-blue-100 text-blue-800 text-xs font-medium me-2 px-1.5 py-0.5 rounded border">
                {params?.row?.latest_auction_data?.data}
              </span>
            </div>
          );
        }
      },
    },
    {
      headerName: "Auction Status",
      column: "auction_status",
      field: "auction_status",
      flex: 1,
      minWidth: 110,
      type: "singleSelect",
      valueGetter: (value) => value?.value,
      valueSetter: (value) => ({
        ...value?.row,
        generate_domain_by: value?.value,
      }),
      valueOptions: [
        { value: "Pending", label: "Pending" },
        { value: "Error", label: "Error" },
        { value: "Completed", label: "Completed" },
      ],
    },
  ];
  const [orderedColumns, setOrderedColumns] = useState(columnDefs);
  const [updateselectedFilter, setUpdateSelectedFilter] = useState({});
  const [visibleColumns, setVisibleColumns] = useState(
    generateColumnVisibilityModel(
      columnDefs?.map((column) => column),
      selectedColumns
    )
  );
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize, page: 1 });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    console.log("👊 ~ handlePageChange ~ queryOptions:", queryOptions);
    get_domains({
      ...sortingModel,
      filters: queryOptions,
      page: +params.page + 1,
      size: +params.pageSize,
    });
  };
  const handleTopPageChange = (page) => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: +page + 1 });
    get_domains({
      ...sortingModel,
      filters: queryOptions,
      page: +page + 1,
      size: paginationModel.pageSize,
    });
  };
  const handleTopPageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
    get_domains({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      size: newPageSize,
    });
  };
  const scrollRef = useRef(null);
  const handleSelectColumn = (column) => {
    const scrollTop = scrollRef.current.scrollTop;
    setSelectedColumns((prevSelected) =>
      prevSelected.includes(column)
        ? prevSelected.filter((col) => col !== column)
        : [...prevSelected, column]
    );
    setTimeout(() => {
      scrollRef.current.scrollTop = scrollTop;
    }, 0);
  };
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setAnchorEl(false);
    }
  };
  const handleClickOutside = (event) => {
    if (columnsRef.current && !columnsRef.current.contains(event.target)) {
      setAnchorEl(false);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  const handleDeleteFilters = async (id) => {
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;

    setLoading(true);
    try {
      const res = await api.delete(`api/report_filter_columns/${id}`);
      if (res.status === 200) {
        toast.success(
          res?.data?.error || res?.data?.message || "Filter saved successfully"
        );
        setDomains((prevState) => ({
          ...prevState,
          userFiltersAndColumns: prevState.userFiltersAndColumns.filter(
            (filter) => filter._id !== id
          ),
        }));
      } else {
        toast.error(
          res?.data?.error || res?.data?.message || "Filter couldn't saved"
        );
      }
      setLoading(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.error ||
          err?.response?.data?.message ||
          "Filter couldn't saved"
      );
      console.log("🚀 ~ getLatestPosition ~ err:", err);
      setLoading(false);
    }
  };
  const fetchKeywords = async () => {
    if (!queryOptions?.rules?.length)
      return toast.error("Please select the filters");
    const c = window.confirm(
      "Are you sure want to fetch the Keyword difficulties & volumes?"
    );
    if (!c) return;
    setLoading(true);
    try {
      const res = await api.post(
        "api/domains/fetch_domain_keyword_difficulty_and_vol",
        { filters: queryOptions }
      );
      if (res.status === 200 || res.status === 201) {
        toast.success("Keywords and Volume fetched successfully");
        await get_domains({
          ...sortingModel,
          filters: queryOptions,
          page: paginationModel?.page,
          size: paginationModel.pageSize,
        });
      } else {
        toast.error("Keywords and Volume couldn't be fetched");
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Keywords and Volume couldn't be fetched");
      console.log("🚀 ~ fetchKeywords ~ err:", err);
    }
  };

  const fetchAhref = async (param) => {
    let apiUrl;
    let successMessage;
    let errorMessage;
    switch (param) {
      case "fetch_dr":
        apiUrl = "api/domains/fetch_pk_DR_by_filter";
        successMessage = "Domain ratings fetched successfully";
        errorMessage = "Domain ratings couldn't be fetched";
        break;
      case "fetch_backlink_status":
        apiUrl = "api/domains/fetch_pk_backlink_status_by_filter";
        successMessage = "Backlink status fetched successfully";
        errorMessage = "Backlink status couldn't be fetched";
        break;
      default:
        toast.error("Invalid parameter provided");
        return;
    }
    if (!queryOptions?.rules?.length) {
      toast.error("Please select the filters");
      return;
    }
    const confirmFetch = window.confirm(
      "Are you sure you want to fetch the data?"
    );
    if (!confirmFetch) return;
    setLoading(true);
    try {
      const res = await api.post(apiUrl, { filters: queryOptions });
      if (res.status === 200 || res.status === 201) {
        await get_domains({
          ...sortingModel,
          filters: queryOptions,
          page: paginationModel?.page,
          size: paginationModel.pageSize,
        });
        toast.success(successMessage);
      } else {
        toast.error(errorMessage);
      }
    } catch (err) {
      toast.error(errorMessage);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="!text-[#042a42]">
        <div className="flex justify-between items-center w-full">
          <div className="flex items-center">
            <div className="relative">
              <Button
                variant="text"
                onClick={handleMenuOpen}
                startIcon={<ViewColumnIcon fontSize="small" />}
                sx={{ fontSize: "0.8125rem" }}
                className="!text-[#042a42]"
              >
                Columns
              </Button>
              {anchorEl ? (
                <div
                  className="absolute shadow bg-white z-10 "
                  ref={columnsRef}
                >
                  <div
                    ref={scrollRef}
                    className="h-[350px] overflow-y-auto flex flex-col p-2 min-w-[350px] w-auto"
                  >
                    {(columnDefs?.filter(({ column }) => !!column) || []).map(
                      ({ column, headerName }, index) => (
                        <div key={index}>
                          <div
                            className={`flex items-center !py-1.5 !px-2.5 `}
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              handleSelectColumn(column);
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={selectedColumns?.includes(column)}
                              className={`form-checkbox h-5 w-5 text-primary-100 rounded-sm focus:ring-0 cursor-pointer mr-2 disabled:text-gray-200 disabled:cursor-not-allowed`}
                              disabled={column === "domain"}
                            />
                            <label
                              className={`text-base text-[#495057] cursor-pointer `}
                              htmlFor={column}
                            >
                              {headerName}
                            </label>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <div className="text-blue-600 p-2 flex justify-between shadow-md border-t">
                    <div className="gap-x-3 flex w-full">
                      <button
                        onClick={() =>
                          setSelectedColumns([
                            ...([...columnDefs, "cc_did_info"] || []).map(
                              ({ column }) => column
                            ),
                            "check_indexing",
                          ])
                        }
                      >
                        Select All
                      </button>
                      <button onClick={() => setSelectedColumns(["domain"])}>
                        Clear
                      </button>
                    </div>
                    <button
                      onClick={() => {
                        setVisibleColumns(
                          generateColumnVisibilityModel(
                            columnDefs?.map((column) => column),
                            selectedColumns
                          )
                        );
                        setAnchorEl(false);
                        get_domains({
                          ...sortingModel,
                          filters: queryOptions,
                          page: paginationModel.page,
                          size: paginationModel.pageSize,
                        });
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="w-fit flex items-center">
              <GridToolbarFilterButton
                ref={setFilterButtonEl}
                className="!text-[#042a42]"
              />
              <Button
                variant="text"
                onClick={handleNewFilter}
                startIcon={<FaSearch size={16} />}
                sx={{ fontSize: "0.8125rem" }}
                className="!text-[#042a42]"
              >
                Apply filter
              </Button>
              <Dropdown drop="down">
                <Dropdown.Toggle
                  id="dropdown-basic"
                  className="rounded-0 w-full md:w-auto py-1. px- align-middle border-0 bg-transparent text-black focus:!shadow-none text-[13px] font-medium hover:text-black active:text-black"
                  style={{ color: "black" }}
                  // className="rounded-0 w-full h-0 md:w-auto py-1. px-2 align-middle border-0 bg-transparent text-black focus:!shadow-none text-[13px] font-medium mt2"
                >
                  ACTIONS
                </Dropdown.Toggle>
                <Dropdown.Menu className="w-fit min-w-[400px] max-h-[300px] overflow-y-auto transform -translate-x-1/2 flex flex-col px-1.5">
                  <button
                    className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-black"
                    onClick={getLatestIndex}
                  >
                    <div className="flex flex-row items-center pl-1">
                      <MdDownload size={18} />
                      <span className="ml-2">Get Latest Index</span>
                    </div>
                  </button>
                  <button
                    className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
                    onClick={checkForRepurchase}
                  >
                    <div className="flex flex-row items-center pl-1">
                      <MdDownload size={18} />
                      <span className="ml-2">Check for Repurchase</span>
                    </div>
                  </button>
                  <button
                    className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
                    onClick={domainsRepurchase}
                  >
                    <div className="flex flex-row items-center pl-1">
                      <MdDownload size={18} />
                      <span className="ml-2">Repurchase Domains</span>
                    </div>
                  </button>
                  <button
                    className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
                    onClick={updateNumber}
                  >
                    <div className="flex flex-row items-center pl-1">
                      <MdUpdate size={18} />
                      <span className="ml-2">Update Domain Numbers</span>
                    </div>
                  </button>

                  <button
                    className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
                    onClick={handleRenewDomains}
                  >
                    <div className="flex flex-row items-center pl-1">
                      <MdRefresh size={18} />
                      <span className="ml-2">Renew Domains</span>
                    </div>
                  </button>
                  <button
                    className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
                    onClick={() => {
                      const alreadyAdded = selectedData?.filter(
                        ({ gsc_email_account_id }) => !!gsc_email_account_id
                      );
                      if (!selectedData?.length) {
                        return toast.error("Please select the domains");
                      } else if (
                        alreadyAdded?.length === selectedData?.length
                      ) {
                        return toast.error(
                          "All domains were already added to the email."
                        );
                      } else if (alreadyAdded?.length) {
                        const c = window.confirm(
                          `${alreadyAdded?.length} of the selected domains are already attached with email address, do you want to proceed for rest of the domains?`
                        );
                        if (!c) return;
                        setSelectedData((prevState) =>
                          prevState.filter(
                            ({ gsc_email_account_id }) => !gsc_email_account_id
                          )
                        );
                        toast.info(
                          `${alreadyAdded?.length} domains removed that were already attached with email address.`
                        );
                      }
                      setGscEmailModal(true);
                    }}
                  >
                    <div className="flex flex-row items-center pl-1">
                      <MdAddCircle size={18} />
                      <span className="ml-2">Attach to GSC</span>
                    </div>
                  </button>

                  <button
                    className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
                    onClick={async () => {
                      setLoading(true);
                      const newRules = [
                        ...queryOptions.rules,
                        // { field: "valueserp_BatchID", op: "eq", data: null },
                      ];
                      await get_domains({
                        ...sortingModel,
                        filters: { ...queryOptions, rules: newRules },
                        page: 1,
                        size: paginationModel.pageSize,
                      });
                      setLoading(false);
                      setIsBatch(true);
                    }}
                  >
                    <div className="flex flex-row items-center pl-1">
                      <MdAddCircleOutline size={18} />
                      <span className="ml-2">
                        Batch For Position - Filtered Records{" "}
                      </span>
                    </div>
                  </button>
                  <button
                    className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
                    onClick={fetchKeywords}
                  >
                    <div className="flex flex-row items-center pl-1">
                      <MdDownload size={18} />
                      <span className="ml-2">Fetch KD & KV by filter</span>
                    </div>
                  </button>
                  <button
                    className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
                    onClick={(e) => fetchAhref("fetch_dr")}
                  >
                    <div className="flex flex-row items-center pl-1">
                      <MdDownload size={18} />
                      <span className="ml-2">Fetch Ahref DR by filter</span>
                    </div>
                  </button>
                  <button
                    className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
                    onClick={(e) => fetchAhref("fetch_backlink_status")}
                  >
                    <div className="flex flex-row items-center pl-1">
                      <MdDownload size={18} />
                      <span className="ml-2">
                        Fetch Ahref backlink status by filter
                      </span>
                    </div>
                  </button>
                  <button
                    className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
                    onClick={disconnectNumbers}
                  >
                    <div className="flex flex-row items-center pl-1">
                      <MdDelete size={18} />
                      <span className="ml-2">Disconnect Phone</span>
                    </div>
                  </button>
                  <button
                    className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
                    onClick={purchasePhoneNumber}
                  >
                    <div className="flex flex-row items-center pl-1">
                      <BiPurchaseTag size={18} />
                      <span className="ml-2">Purchase Phone Number</span>
                    </div>
                  </button>
                  <button
                    className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
                    onClick={startPhoneVerification}
                  >
                    <div className="flex flex-row items-center pl-1">
                      <BiPurchaseTag size={18} />
                      <span className="ml-2">
                        Start Domain Phone verification
                      </span>
                    </div>
                  </button>
                  <button
                    className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
                    onClick={openSeoBatchFormHandler(0)}
                  >
                    <div className="flex flex-row items-center pl-1">
                      <TbSeo size={20} />
                      <span className="ml-2">Add / Update Seo Batches</span>
                    </div>
                  </button>
                  <button
                    className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
                    onClick={() => updateDnsServerIp("update_dns_serverip")}
                  >
                    <div className="flex flex-row items-center pl-1">
                      <MdAddCircleOutline size={18} />
                      <span className="ml-2">Update DNS server IP</span>
                    </div>
                  </button>
                  <button
                    className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
                    onClick={() => updateDnsServerIp("update_domains_runner")}
                  >
                    <div className="flex flex-row items-center pl-1">
                      <MdAddCircleOutline size={18} />
                      <span className="ml-2">Update Domain's runner</span>
                    </div>
                  </button>
                  <button
                    className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-black"
                    onClick={verify_domain_dns}
                  >
                    <div className="flex flex-row items-center pl-1">
                      <MdDownload size={18} />
                      <span className="ml-2">Verify Domain DNS</span>
                    </div>
                  </button>
                  <button
                    className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
                    onClick={() => resetDomainCloudflare()}
                  >
                    <div className="flex flex-row items-center pl-1">
                      <MdAddCircleOutline size={18} />
                      <span className="ml-2"> Reset Domains Cloudflare</span>
                    </div>
                  </button>
                  <button
                    className="cursor-pointer mt-1 w-full px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
                    onClick={() => checkAuctionData()}
                  >
                    <div className="flex flex-row items-center pl-1">
                      <MdAddCircleOutline size={18} />
                      <span className="ml-2">Check for Auction Data</span>
                    </div>
                  </button>
                </Dropdown.Menu>
              </Dropdown>
              <Button
                variant="text"
                onClick={copyToRoboanywhere}
                startIcon={<FaCopy size={16} />}
                sx={{
                  fontSize: "0.8125rem",
                  color: !selectedData?.length ? "#6B7280" : "#042a42",
                  pointerEvents: !selectedData?.length ? "none" : "auto",
                }}
                disabled={!selectedData?.length}
              >
                Copy To Roboanywhere
              </Button>
            </div>
          </div>
          <div className="flex w-fit gapx-2 items-center">
            <TablePagination
              color="primary"
              shape="rounded"
              size="medium"
              showFirstButton
              showLastButton
              count={
                domains?.totalItems
                  ? domains?.totalItems
                  : domainList?.length || 0
              }
              page={paginationModel.page - 1 || 0}
              onPageChange={(e, page) => handleTopPageChange(page)}
              rowsPerPage={paginationModel.pageSize || 0}
              onRowsPerPageChange={(e) =>
                handleTopPageSizeChange(e.target.value)
              }
              component="div"
              rowsPerPageOptions={[
                25,
                50,
                75,
                100,
                250,
                500,
                1000,
                1500,
                2000,
                domains?.totalItems
                  ? domains?.totalItems
                  : domainList?.length || 0,
              ]}
            />
            <Dropdown drop="down">
              <Dropdown.Toggle
                id="dropdown-basic"
                className="rounded-0 w-full md:w-auto py-1. px- align-middle border-0 bg-transparent text-black focus:!shadow-none text-[13px] font-medium hover:text-black"
              >
                EXPORT
              </Dropdown.Toggle>
              <Dropdown.Menu className="w-fit min-w-[200px] transform -translate-x-1/2 flex flex-col px-1.5">
                <button
                  className="cursor-pointer mt-1 w-48 px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
                  onClick={exportToExcel}
                >
                  <div className="flex flex-row items-center pl-1">
                    <MdDownload size={18} />
                    <span className="ml-2">Export</span>
                  </div>
                </button>
                <button
                  className="cursor-pointer mt-1 w-48 px-1.5 flex flex-col hover:bg-primary-100 hover:text-white"
                  onClick={exportDidToExcel}
                >
                  <div className="flex flex-row items-center pl-1">
                    <MdDownload size={18} />
                    <span className="ml-2">Export -Import DID Excel</span>
                  </div>
                </button>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </GridToolbarContainer>
    );
  }

  async function sendAutoRenewRequests() {
    if (isLoader) {
      return toast.error("Please wait until the previous process is complete.");
    }
    if (selectedData?.length === 0) {
      return toast.error("Please select the domain");
    }
    setIsLoader(true);
    setRenewModal(false);
    const selectedDataCopy = [...selectedData];
    for (const item of selectedDataCopy) {
      try {
        const response = await api.post(
          "api/vendors/domain_prime_time/set_auto_renew_domain",
          { domain: `${item?.domain}`, set_auto_renew: renewStatus }
        );
        if (response.status === 200) {
          toast.success(`${response?.data}`);
          setSelectedData((prevState) =>
            prevState.filter(({ domain }) => domain !== item?.domain)
          );
        } else {
          toast.error(`"${item?.domain}" ${response.data}`);
          break;
        }
      } catch (error) {
        console.log("🚀 ~ file: index.jsx:252 ~ sendRequests ~ error:", error);
        toast.error(
          `"${item?.domain}" ${error?.response?.data || "Getting an error"}`
        );
        break;
      }
    }
    setIsLoader(false);
  }
  const [anchorEl, setAnchorEl] = useState(false);

  const handleMenuOpen = () => {
    setAnchorEl(!anchorEl);
  };
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: getSortField(params[0]?.field),
      sort_by: params[0]?.sort || "default",
    });
    get_domains({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
      sort_field: getSortField(params[0]?.field),
      sort_by: params[0]?.sort || "default",
    });
  };

  const getSortField = (field) => {
    let sort_field = field?.includes("_in")
      ? field.replaceAll("_in", "")
      : field;
    switch (field) {
      case "pk_latest_position":
        sort_field = "pk_latest_position.position";
        break;
      case "latest_PK_ahref_keyword_difficulty":
        sort_field = "latest_PK_ahref_keyword_difficulty.difficulty";
        break;
      case "latest_PK_ahref_keyword_volume":
        sort_field = "latest_PK_ahref_keyword_volume.volume";
        break;
      default:
        break;
    }

    return sort_field;
  };
  const exportToExcel = () => {
    const getFilteredValues = (selectedColumns, columnDefs, key) => {
      const orderedColumnsArr = columnDefs
        .filter(
          (def) =>
            selectedColumns.includes(def.column) &&
            def?.column !== "" &&
            def?.column !== "available_to_repurchase" &&
            def?.column !== "phone_matched_with_cc_status" &&
            def?.column !== "phone_disconnected"
        )
        .map((def) => def[key]);

      return orderedColumnsArr;
    };

    const parentHeader = [
      "Sr",
      ...getFilteredValues(selectedColumns, orderedColumns, "headerName"),
    ];
    const fieldValues = [
      "sr",
      ...getFilteredValues(selectedColumns, orderedColumns, "field"),
    ];
    const items = domainList.map((domainData, index) => {
      const {
        domain = " ",
        phone = " ",
        state = " ",
        city = " ",
        location = " ",
        population = " ",
        purchase_date = " ",
        expiration_date = " ",
        vendors = [],
        inNet_vendors = [],
        outNet_vendors = [],
        state_code = " ",
        domain_indexed = " ",
        is_expired = " ",
        auto_renew = " ",
        check_indexing = " ",
        generate_domain_by = " ",
        industry_id = " ",
        latest_ahref_domain_DR = " ",
        latest_ahref_domain_Backlink = " ",
        latest_PK_ahref_keyword_difficulty = " ",
        latest_PK_ahref_keyword_volume = " ",
        primary_keyword = " ",
        delete_status = " ",
        runner_name = " ",
        county = " ",
        address = " ",
        zip_count = " ",
        pk_latest_position = { position: 0 },
        DNSServerIP = " ",
        positioning_status = " ",
        deployed_on_host = " ",
        search_volume = " ",
        expiration_update_date = " ",
        lead_count = 0,
        job_count = 0,
        cloudflare_zone_id = " ",
        cloudflare_verification_status = " ",
        cloudflare_account_id = { auth_email: " " },
        GSC_server_id = { server_ip: " " },
        gsc_server_sync_status = " ",
        cc_did_info = null,
        SEO_batch_id = "",
        latest_auction_data = "",
        auction_status = "",
      } = domainData;

      const addNAIfEmpty = (value) =>
        value === undefined || value === null || value === "" ? "" : value;
      let auction_data_obj = "";

      if (!latest_auction_data || latest_auction_data === null) {
        auction_data_obj = "N/A";
      } else {
        const auctionArr = {
          On: dayjs(latest_auction_data?.date).format("MM-DD-YYYY HH:mm"),
          ...(typeof latest_auction_data?.data === "object" &&
          latest_auction_data?.data !== null
            ? {
                currentBid: latest_auction_data?.data?.currentBid,
                auctionEndsOnUtc: dayjs(
                  latest_auction_data?.data?.auctionEndsOnUtc
                ).format("MM-DD-YYYY HH:mm"),
              }
            : { Message: `${latest_auction_data?.data}` }),
        };

        auction_data_obj = Object.entries(auctionArr)
          .map(([key, value]) => `${key} : ${value} `)
          .join("\n");
      }

      return {
        sr: (index + 1).toString(),
        domain: addNAIfEmpty(domain),
        phone: addNAIfEmpty(phone),
        state: addNAIfEmpty(state),
        city: addNAIfEmpty(city),
        location: addNAIfEmpty(location),
        population: addNAIfEmpty(population),
        purchase_date: addNAIfEmpty(purchase_date),
        expiration_date: addNAIfEmpty(expiration_date),
        vendors: vendors?.length ?? 0,
        inNet_vendors: inNet_vendors?.length ?? 0,
        outNet_vendors: outNet_vendors?.length ?? 0,
        in_area: inNet_vendors?.length ? "In Area" : "Out Area",
        state_code: addNAIfEmpty(state_code),
        domain_indexed: domain_indexed
          ? domain_indexed.is_indexed === "NotIndexed" ||
            domain_indexed.is_indexed === "NotChecked"
            ? `${domain_indexed.is_indexed}  ${
                check_indexing
                  ? "In Process"
                  : dayjs(domain_indexed.on_date).format("MM-DD-YYYY HH:mm")
              }`
            : `${domain_indexed.is_indexed}  ${
                check_indexing
                  ? "In Process"
                  : dayjs(domain_indexed.on_date).format("MM-DD-YYYY HH:mm")
              }`
          : "Not Checked",
        is_expired: is_expired ? "Expired" : "No",
        auto_renew: addNAIfEmpty(auto_renew),
        generate_domain_by: addNAIfEmpty(generate_domain_by),
        expiring_in_days: addNAIfEmpty(
          calculateDaysUntilExpiration(expiration_date)
        ),
        industry_id: addNAIfEmpty(
          industryDrd.find((ind) => ind._id === industry_id)?.name
        ),
        latest_ahref_domain_DR:
          latest_ahref_domain_DR === null ? "N/A" : latest_ahref_domain_DR,
        latest_ahref_domain_Backlink:
          latest_ahref_domain_Backlink === null
            ? "N/A"
            : latest_ahref_domain_Backlink,
        "latest_PK_ahref_keyword_difficulty.difficulty":
          latest_PK_ahref_keyword_difficulty
            ? latest_PK_ahref_keyword_difficulty?.difficulty
              ? latest_PK_ahref_keyword_difficulty?.difficulty
              : "-1" +
                ", " +
                dayjs(latest_PK_ahref_keyword_difficulty?.log_date).format(
                  "MM-DD-YYYY HH:mm"
                )
            : "N/A",
        "latest_PK_ahref_keyword_volume.volume": latest_PK_ahref_keyword_volume
          ? latest_PK_ahref_keyword_volume?.volume
            ? latest_PK_ahref_keyword_volume?.volume
            : "-1" +
              ", " +
              dayjs(latest_PK_ahref_keyword_volume?.log_date).format(
                "MM-DD-YYYY HH:mm"
              )
          : "N/A",
        primary_keyword: addNAIfEmpty(primary_keyword),
        delete_status: addNAIfEmpty(delete_status),
        runner_name: addNAIfEmpty(runner_name),
        county: addNAIfEmpty(county),
        address: addNAIfEmpty(address),
        zip_count: addNAIfEmpty(zip_count),
        pk_latest_position: addNAIfEmpty(pk_latest_position?.position),
        DNSServerIP: addNAIfEmpty(DNSServerIP),
        positioning_status: addNAIfEmpty(positioning_status),
        deployed_on_host: addNAIfEmpty(deployed_on_host),
        search_volume: addNAIfEmpty(search_volume),
        expiration_update_date: addNAIfEmpty(expiration_update_date),
        lead_count: addNAIfEmpty(lead_count),
        job_count: addNAIfEmpty(job_count),
        auth_email: addNAIfEmpty(cloudflare_account_id?.auth_email),
        server_ip: addNAIfEmpty(GSC_server_id?.server_ip),
        cloudflare_zone_id: addNAIfEmpty(cloudflare_zone_id),
        cloudflare_verification_status: addNAIfEmpty(
          cloudflare_verification_status
        ),
        gsc_server_sync_status: addNAIfEmpty(gsc_server_sync_status),
        "cc_did_info.number": addNAIfEmpty(cc_did_info?.number),
        "cc_did_info.merchant": addNAIfEmpty(cc_did_info?.merchant),
        "cc_did_info.ring_group": addNAIfEmpty(cc_did_info?.ring_group),
        "pk_latest_position.position": addNAIfEmpty(
          pk_latest_position?.position
        ),
        SEO_batch_id: addNAIfEmpty(
          batchListDrd.find((seo) => seo._id === SEO_batch_id)?.batch_name
        ),
        latest_auction_data: auction_data_obj,
        auction_status: addNAIfEmpty(auction_status),
      };
    });

    const filteredItems = items.map((item) => {
      const filtered = {};
      fieldValues.forEach((key) => {
        if (item[key] !== undefined) {
          filtered[key] = item[key];
        }
      });
      return filtered;
    });

    exportDomainReport(
      filteredItems,
      parentHeader,
      "domain_reports",
      "Domain Report"
    );
  };
  const exportDidToExcel = () => {
    const Header = ["DID", "Domain", "Industry Id", "Industry Name"];
    const file_name = `${
      industryDrd.find((ind) => ind._id === domainList[0].industry_id)?.name
    } - ${domainList.length}`;
    const items = domainList.map((domainData, index) => {
      const { domain = " ", phone = " ", industry_id = " " } = domainData;

      const addNAIfEmpty = (value) =>
        value === undefined || value === null || value === "" ? "" : value;
      const add1IfPhoneNotEmpty = (phone) =>
        phone === undefined || phone === null || phone === ""
          ? ""
          : "1" + phone;
      return {
        phone: add1IfPhoneNotEmpty(phone),
        domain: addNAIfEmpty(domain),
        industry_id: addNAIfEmpty(industry_id),
        industry_name: addNAIfEmpty(
          industryDrd.find((ind) => ind._id === industry_id)?.name
        ),
      };
    });

    exportDomainReport(items, Header, file_name, "Import DIDs Report");
  };

  const areObjectsEqual = (selectedFilter) => {
    return _.isEqual(selectedFilter, queryOptions);
  };

  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;
  // const findMatchingObject = () => {
  //   return _.find(
  //     domains?.userFiltersAndColumns?.data?.filters || domains?.userFiltersAndColumns,
  //     (item) => _.isEqual(item.data, queryOptions)
  //   );
  // };
  // const findMatchingObject = () => {
  //   return _.find(
  //     domains?.userFiltersAndColumns?.data?.filters ||
  //       domains?.userFiltersAndColumns,
  //     (item) => {
  //       const itemData = item.data.filters || item.data;
  //       const queryData = queryOptions.filters || queryOptions;

  //       return _.isEqual(itemData, queryData);
  //     }
  //   );
  // };
  const findMatchingObject = () => {
    return _.find(domains?.userFiltersAndColumns, (item) => {
      if (item.data.filters && item.data.columns) {
        return (
          _.isEqual(item.data.filters, queryOptions) &&
          _.isEqual(item.data.columns, selectedColumns)
        );
      } else {
        return _.isEqual(item.data, queryOptions);
      }
    });
  };

  // const findMatchingObject = () => {
  //   return _.find(domains?.userFiltersAndColumns, queryOptions);
  // };
  // const isAllObjectMatched = () => {
  //   return domains?.userFiltersAndColumns.find((obj) => {
  //     return Object.keys(queryOptions).every((key) =>
  //       obj.data.rules.some(
  //         (rule) => rule.field === key && rule.data === queryOptions[key]
  //       )
  //     );
  //   });
  // };
  const isAllObjectMatched = () => {
    return domains?.userFiltersAndColumns.some((obj) => {
      return (
        obj.data.groupOp === queryOptions.groupOp &&
        queryOptions.rules.every((rule) =>
          obj.data.rules.some(
            (objRule) =>
              objRule.field === rule.field &&
              objRule.op === rule.op &&
              objRule.data === rule.data
          )
        )
      );
    });
  };
  const sortUserFilters = (filters) => {
    if (!Array.isArray(filters)) {
      console.error("Expected an array but got:", filters);
      return [];
    }
    return filters.sort((a, b) => {
      const aStartsWithAsterisk = a.note.startsWith("**");
      const bStartsWithAsterisk = b.note.startsWith("**");

      if (aStartsWithAsterisk && !bStartsWithAsterisk) return -1;
      if (!aStartsWithAsterisk && bStartsWithAsterisk) return 1;
      return 0;
    });
  };
  const CustomComponent = () => {
    return (
      <div className="mb-2 ">
        <div className="w-full md:w-1/2 lg:w-64 flex items-center gap-x-2">
          <FiltersDropdown
            options={sortUserFilters(domains?.userFiltersAndColumns || [])}
            value={findMatchingObject()}
            areObjectsEqual={(data) => areObjectsEqual(data)}
            handleDeleteFilters={(id) => handleDeleteFilters(id)}
            onRemove={() => {
              setFilterModel({
                items: [{ field: "is_expired", operator: "is", value: false }],
                logicOperator: "and",
                quickFilterValues: [],
                quickFilterLogicOperator: "and",
              });
              setQueryOptions({
                groupOp: "AND",
                rules: [
                  {
                    field: "is_expired",
                    op: "eq",
                    data: false,
                  },
                ],
              });
              setPaginationModel({
                pageSize: paginationModel.pageSize,
                page: 1,
              });
              get_domains({
                ...sortingModel,
                page: 1,
                size: paginationModel.pageSize,
              });
            }}
            onChange={(data, item) => {
              const canEdit = item?.user_id === user?._id;
              setUpdateSelectedFilter(canEdit ? item : {});
              const filters = data?.groupOp
                ? data
                : data?.filters
                ? data.filters
                : { groupOp: "AND", rules: [] };
              const columns = data?.columns ? data?.columns : selectedColumns;
              setFilterModel({
                items: filters?.rules?.map(({ field, op, data }) => ({
                  field: field,
                  operator: operator({ operator: op, field }),
                  value: data,
                })),
                logicOperator: "and",
                quickFilterValues: [],
                quickFilterLogicOperator: "and",
              });
              setQueryOptions(filters);
              setPaginationModel({
                pageSize: paginationModel.pageSize,
                page: 1,
              });
              setVisibleColumns(
                generateColumnVisibilityModel(
                  columnDefs?.map((column) => column),
                  columns
                )
              );
              setSelectedColumns(columns);
              get_domains({
                ...sortingModel,
                filters: filters,
                selected_columns: columns,
                page: 1,
                size: paginationModel.pageSize,
              });
            }}
          />
          {queryOptions?.rules?.length && !isAllObjectMatched() ? (
            <div className="flex flex-row space-x-1">
              <button
                className="bg-primary-100 text-white w-[25px] h-[25px] rounded-full flex justify-center items-center"
                onClick={() => setIsFilterSave(true)}
              >
                <FaCheck />
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  };
  const operator = ({ operator, field }) => {
    const isSelect =
      columnDefs?.find((item) => item.field === field)?.type === "singleSelect";
    const isNumber =
      columnDefs?.find((item) => item.field === field)?.type === "number";
    const isDate =
      columnDefs?.find((item) => item.field === field)?.type === "date";
    return operator === "cn"
      ? "contains"
      : operator === "eq" && !isSelect && !isNumber && !isDate
      ? "equals"
      : operator === "eq" && isSelect && !isNumber && !isDate
      ? "is"
      : operator === "eq" && !isSelect && isNumber && !isDate
      ? "="
      : operator === "not" && !isSelect && isNumber && !isDate
      ? "!="
      : operator === "eq" && !isSelect && !isNumber && isDate
      ? "is"
      : operator === "gt" && isDate
      ? "after"
      : operator === "gte" && isDate
      ? "onOrAfter"
      : operator === "lt" && isDate
      ? "before"
      : operator === "lte" && isDate
      ? "onOrBefore"
      : operator === "gt"
      ? ">"
      : operator === "gte"
      ? ">="
      : operator === "lt"
      ? "<"
      : operator === "lte"
      ? "<="
      : operator;
  };
  const [filterModel, setFilterModel] = React.useState({
    items: queryOptions.rules?.map(({ field, op, data }) => ({
      field,
      operator: operator({ operator: op, field }),
      value: data,
      // data === true ? 1 : data === false ? 0 : data,
    })),
    logicOperator: "and",
    quickFilterValues: [],
    quickFilterLogicOperator: "and",
  });
  const onColumnOrderChange = React.useCallback(
    (params) => {
      const newOrder = [...orderedColumns];
      const [movedColumn] = newOrder.splice(params.oldIndex, 1);
      newOrder.splice(params.targetIndex, 0, movedColumn);
      setOrderedColumns(newOrder);
    },
    [orderedColumns]
  );
  useEffect(() => {
    // Update the columnDefs to include the updated industryDrd
    if (industryDrd?.length && batchListDrd?.length) {
      const updatedColumnDefs = orderedColumns.map((colDef) => {
        if (colDef.field === "industry_id") {
          return {
            ...colDef,
            valueOptions: industryDrd,
          };
        }
        if (colDef.field === "SEO_batch_id") {
          return {
            ...colDef,
            filterOperators: [
              isAnyOfOperator,
              isNotAnyOfOperator,
              {
                label: "is empty",
                value: "isEmpty",
                getApplyFilterFn: (filterItem) => {
                  if (!filterItem.value) {
                    return null;
                  }
                  return 0;
                },
              },
              {
                label: "is not empty",
                value: "isNotEmpty",
                getApplyFilterFn: (filterItem) => {
                  if (!filterItem.value) {
                    return null;
                  }
                  return 0;
                },
              },
            ],
          };
        }
        return colDef;
      });
      setOrderedColumns(updatedColumnDefs);
    }
  }, [industryDrd, batchListDrd]);

  useEffect(() => {
    // Update the columnDefs to include the updated industryDrd
    const updatedColumnDefs = orderedColumns?.map((colDef) => {
      if (colDef.field === "checkbox") {
        return checkboxColumn[0];
      }
      return colDef;
    });
    setOrderedColumns(updatedColumnDefs);
  }, [selectedData]);

  const openSeoBatchFormHandler = () => () => {
    if (selectedData?.length) {
      setIsSeoBatches(true);
    } else {
      toast.error("Please select a domain.");
    }
  };
  const cancelFormHandler = () => {
    setIsSeoBatches(false);
    setDNSserverIp(false);
    get_domains({
      ...sortingModel,
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
    });
  };
  useEffect(() => {
    dispatch(get_batchlist());
  }, [dispatch]);

  const updateDnsServerIp = (method) => {
    if (selectedData?.length) {
      setDNSserverIp((prevState) => ({
        ...prevState,
        isDNSserverIp: !prevState.isDNSserverIp,
        method: method,
      }));
    } else {
      toast.error("Please select a domain.");
    }
  };
  const resetDomainCloudflare = (method) => {
    if (selectedData?.length) {
      setResetDomainCloud(true);
    } else {
      toast.error("Please select a domain.");
    }
  };

  const copyToRoboanywhere = async () => {
    const c = window.confirm(
      "Are you sure you want to copy these domains to Roboanywhere?"
    );
    if (!c) return;
    if (!selectedData?.length) {
      toast.error("Please select a domain.");
      return;
    }
    const domains = selectedData?.map(({ domain }) => domain);
    try {
      const res = await api.post(`/api/domains/move_to_robo`, {
        domains,
      });
      if (res.status === 200 || res.status === 201) {
        toast.success(
          res?.data?.message ||
            "Selected domains have been moved to Roboanywhere successfully."
        );
      } else {
        toast.error(res?.data?.message || "Domains couldn't be moved!");
      }
      setLoading(false);
    } catch (err) {
      toast.error(err?.response?.data?.message || "Domains couldn't be moved!");
      console.log(err);
      setLoading(false);
    }
  };

  const checkAuctionData = () => {
    if (selectedData?.length) {
      setCheckAuctionData(true);
    } else {
      toast.error("Please select a domain.");
    }
  };

  const getRowHeight = (params) => {
    const rowHeight = 40;
    const data = params?.model;
    const numberOfLines = data?.latest_auction_data
      ? Math.ceil(Object.keys(data?.latest_auction_data).length / 40)
      : 0;
    const auctionHeight = rowHeight + numberOfLines * 30 + 20;
    return auctionHeight;
  };

  return (
    <>
      <PageHeader
        route="Setting > Industries"
        className="col-span-12 w-full !justify-start items-center"
      />
      {verificationResult ? (
        <DNSVerificationResultModal
          onClose={() => setVerificationResult(null)}
          data={verificationResult}
        />
      ) : null}
      {isSeoBatches ? (
        <AddSeoBatches
          modalTitle="Add / Update Seo Batch"
          onCancelForm={cancelFormHandler}
          selectedData={selectedData}
        />
      ) : null}
      {isDNSserverIp?.isDNSserverIp ? (
        <UpdateDNSModal
          modalTitle={
            isDNSserverIp?.method === "update_dns_serverip"
              ? "Update DNS server IP"
              : "Update Domain's runner"
          }
          onCancelForm={cancelFormHandler}
          selectedData={selectedData}
          apiSlug={isDNSserverIp?.method}
        />
      ) : null}

      {isResetDomainCloud ? (
        <ResetDomainCloudflare
          modalTitle={"Reset Domains Cloudflare"}
          onCancelForm={() => {
            cancelFormHandler();
            setResetDomainCloud(false);
          }}
          selectedData={selectedData}
        />
      ) : null}

      {isCheckAuctionData ? (
        <CheckAuctionData
          modalTitle={"Check for Auction Data"}
          onCancelForm={() => {
            setCheckAuctionData(false);
          }}
          selectedData={selectedData}
          refreshPage={() => {
            setCheckAuctionData(false);
            setSelectedData([]);
            get_domains({
              ...sortingModel,
              filters: queryOptions,
              page: 1,
              size: paginationModel.pageSize,
            });
          }}
        />
      ) : null}
      {isBatch ? (
        <CreateBatchModal
          onClose={() => setIsBatch(false)}
          filters={queryOptions}
          totalItems={domains?.totalItems}
        />
      ) : null}
      {gscEmailModal ? (
        <AddGSCEmailsModal
          onClose={() => {
            setGscEmailModal(false);
          }}
          selectedData={selectedData}
          get_domain={() => {
            get_domains({
              ...sortingModel,
              filters: queryOptions,
              page: paginationModel.page,
              size: paginationModel.pageSize,
            });
            setSelectedData([]);
          }}
        />
      ) : null}
      {vendors?.vendors?.length ? (
        <VendorsListModal
          onCancelForm={() => setVendors(null)}
          data={vendors}
        />
      ) : null}
      {countModal?.phone ? (
        <LeadJobListModal
          onCancelForm={() => setCountModal(null)}
          data={countModal}
        />
      ) : null}
      {isLoading ? <DotsLoader /> : null}
      {renewModal ? (
        <AutoRenewModal
          onClose={() => {
            setRenewStatus(null);
            setRenewModal(false);
          }}
          setRenewStatus={setRenewStatus}
          renewStatus={renewStatus}
          handleConfirm={sendAutoRenewRequests}
        />
      ) : null}
      {isFilterSave ? (
        <SaveFilterModal
          onClose={() => {
            setIsFilterSave(false);
          }}
          filters={queryOptions}
          columns={selectedColumns}
          activeFilter={updateselectedFilter}
          setFilters={(data) =>
            setDomains((prevDomains) => ({
              ...prevDomains,
              userFiltersAndColumns: [
                ...(updateselectedFilter._id
                  ? prevDomains.userFiltersAndColumns.filter(
                      (filter) => filter._id !== updateselectedFilter._id
                    )
                  : prevDomains?.userFiltersAndColumns || []),
                data,
              ],
            }))
          }
        />
      ) : null}
      <div className="flex-cols md:flex flex-wrap justify-start space-x-2 mx-0.5">
        <button
          className={`flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[19%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
        ${
          selectedFilter === "total_domains"
            ? "border-l-2 border-primary-100"
            : ""
        }
        `}
          onClick={() => {
            const filter = {
              groupOp: "AND",
              rules: [
                {
                  field: "is_expired",
                  op: "eq",
                  data: false,
                },
              ],
            };
            setQueryOptions(filter);
            setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
            get_domains({
              ...sortingModel,
              filters: filter,
              page: 1,
              size: paginationModel.pageSize,
            });
            setSelectedFilter("total_domains");
          }}
        >
          <span className="text-sm font-pop">Active (InArea + OutArea)</span>
          <span className="text-base font-medium font-pop">
            {domains?.summary?.activeDomainCount
              ? domains?.summary?.activeDomainCount?.toLocaleString()
              : 0}
          </span>
        </button>
        <button
          className={`flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[19%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
        ${
          selectedFilter === "total_expired"
            ? "border-l-2 border-primary-100"
            : ""
        }
        `}
          onClick={() => {
            const filter = {
              groupOp: "AND",
              rules: [
                {
                  field: "is_expired",
                  op: "eq",
                  data: true,
                },
              ],
            };
            setQueryOptions(filter);
            setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
            get_domains({
              ...sortingModel,
              filters: filter,
              page: 1,
              size: paginationModel.pageSize,
            });
            setSelectedFilter("total_expired");
          }}
        >
          <span className="text-sm font-pop">Expired</span>
          <span className="text-base font-medium font-pop">
            {domains?.summary?.expiredDomainCount
              ? domains?.summary?.expiredDomainCount?.toLocaleString()
              : 0}
          </span>
        </button>

        <button
          className={`flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[19%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
        ${
          selectedFilter === "inAreaDomainCount"
            ? "border-l-2 border-primary-100"
            : ""
        }
        `}
          onClick={() => {
            const filter = {
              groupOp: "AND",
              rules: [
                {
                  field: "in_area",
                  op: "eq",
                  data: true,
                },
                {
                  field: "is_expired",
                  op: "eq",
                  data: false,
                },
              ],
            };
            setQueryOptions(filter);
            setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
            get_domains({
              ...sortingModel,
              filters: filter,
              page: 1,
              size: paginationModel.pageSize,
            });
            setSelectedFilter("inAreaDomainCount");
          }}
        >
          <span className="text-sm font-pop">Active InArea</span>
          <span className="text-base font-medium font-pop">
            {domains?.summary?.inAreaDomainCount
              ? domains?.summary?.inAreaDomainCount?.toLocaleString()
              : 0}
          </span>
        </button>

        <button
          className={`flex mt-2 flex-col justify-between items-center text-ml-3 !p-2 sm:w-[45%] md:w-[19%] hover:no-underline hover:text-black shadow-report rounded-[1px] bg-white transition-all
        ${
          selectedFilter === "outAreaDomainCount"
            ? "border-l-2 border-primary-100"
            : ""
        }
        `}
          onClick={() => {
            const filter = {
              groupOp: "AND",
              rules: [
                {
                  field: "in_area",
                  op: "eq",
                  data: false,
                },
                {
                  field: "is_expired",
                  op: "eq",
                  data: false,
                },
              ],
            };
            setQueryOptions(filter);
            setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
            get_domains({
              ...sortingModel,
              filters: filter,
              page: 1,
              size: paginationModel.pageSize,
            });
            setSelectedFilter("outAreaDomainCount");
          }}
        >
          <span className="text-sm font-pop">Active OutArea</span>
          <span className="text-base font-medium font-pop">
            {domains?.summary?.outAreaDomainCount
              ? domains?.summary?.outAreaDomainCount?.toLocaleString()
              : 0}
          </span>
        </button>
      </div>
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={orderedColumns}
          items={domainList?.map((record, index) => {
            const counter = index + 1;
            const {
              _id,
              domain,
              industry_id,
              primary_keyword,
              ahref_PK_keyword_volume,
              latest_PK_ahref_keyword_volume,
              ahref_PK_keyword_difficulty,
              latest_PK_ahref_keyword_difficulty,
              ahref_domain_DR_status,
              ahref_domain_DR,
              latest_ahref_domain_DR,
              ahref_domain_Backlink_status,
              ahref_domain_Backlink,
              latest_ahref_domain_Backlink,
              delete_status,
              runner_name,
              phone,
              city,
              state,
              county,
              zip_count,
              location,
              population,
              address,
              vendor_count,
              outNet_vendors,
              inNet_vendors,
              in_area,
              DNSServerIP,
              pk_latest_position,
              job_count,
              lead_count,
              search_volume,
              generate_domain_by,
              domain_indexed,
              positioning_status,
              deployed_on_host,
              purchase_date,
              expiration_date,
              expiration_update_date,
              is_expired,
              available_to_repurchase,
              valueserp_BatchID,
              state_code,
              vendors,
              auto_renew,
              cloudflare_account_id,
              GSC_server_id,
              cloudflare_zone_id,
              cloudflare_verification_status,
              gsc_server_sync_status,
              cc_did_info,
              phone_disconnected,
              SEO_batch_id,
              DNS_status,
              inteliquent_phone_status,
              auction_status,
              latest_auction_data,
            } = record;
            const sortByDate = (arr) =>
              (arr?.filter((pos) => pos !== null) || []).sort(
                (a, b) => new Date(b.log_date) - new Date(a.log_date)
              );
            return {
              records: { ...record },
              counter: offset + counter,
              _id,
              domain,
              industry_id,
              industry_name: industryDrd.find((ind) => ind._id === industry_id)
                ?.name,
              primary_keyword,
              ahref_domain_DR_status,
              latest_PK_ahref_keyword_volume,
              latest_PK_ahref_keyword_difficulty,
              latest_ahref_domain_DR,
              ahref_domain_Backlink_status,
              latest_ahref_domain_Backlink,
              delete_status,
              runner_name,
              phone,
              city,
              state,
              county,
              zip_count,
              server_ip: GSC_server_id?.server_ip,
              auth_email: cloudflare_account_id?.auth_email,
              ahref_PK_keyword_volume: sortByDate(ahref_PK_keyword_volume),
              ahref_PK_keyword_difficulty: sortByDate(
                ahref_PK_keyword_difficulty
              ),
              gsc_server_sync_status,
              ahref_domain_DR: sortByDate(ahref_domain_DR),
              ahref_domain_Backlink: sortByDate(ahref_domain_Backlink),
              location,
              population,
              address,
              vendor_count,
              outNet_vendors,
              inNet_vendors,
              in_area,
              vendors,
              DNSServerIP,
              pk_latest_position,
              job_count,
              lead_count,
              search_volume,
              generate_domain_by,
              domain_indexed,
              positioning_status,
              deployed_on_host,
              purchase_date,
              expiration_date,
              expiration_update_date,
              is_expired: is_expired ? "Yes" : "No",
              valueserp_BatchID,
              available_to_repurchase,
              state_code,
              auto_renew,
              cloudflare_zone_id,
              cloudflare_verification_status,
              cc_did_info,
              "cc_did_info.number": cc_did_info?.number,
              "cc_did_info.merchant": cc_did_info?.merchant,
              "cc_did_info.ring_group": cc_did_info?.ring_group,
              phone_disconnected,
              SEO_batch_id,
              SEO_batch_name:
                batchListDrd.find((seo) => seo._id === SEO_batch_id)
                  ?.batch_name || "N/A",
              DNS_status,
              inteliquent_phone_status,
              auction_status,
              latest_auction_data,
            };
          })}
          searchable="No"
          showCount="No"
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          totalItems={domains?.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={loading}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          columnVisibilityModel={{
            ...visibleColumns,
            "available_to_repurchase.isAvailable": false,
            phone_matched_with_cc_status: false,
            phone_disconnected: false,
            delete_status: false,
            DNS_status: true,
          }}
          height="100vh"
          maxHeight="90vh"
          density="standard"
          CustomComponent={CustomComponent}
          filterModel={filterModel}
          sortingMode="server"
          onSortModelChange={handleSortModelChange}
          onColumnOrderChange={onColumnOrderChange}
          disableColumnMenu="No"
          gridOptions={getRowHeight}
          // initialState = {{
          //   columns: {
          //     orderedFields: columnOrder,
          //   }
          // }}
        />
      </div>
    </>
  );
};

export default DomainsReport;
