import React, { useEffect } from "react";
import { Button, DotsLoader } from "../../components";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import api from "../../services/api";
import { toast } from "react-toastify";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import ExcelJS from "exceljs";
import { getFullName } from "../../util/common";

const VendorsLocationModal = ({ onCancelForm, data, industry_id }) => {
  const [filterConditions, setFilterConditions] = React.useState([]);
  const [locations, setLocations] = React.useState([]);
  const [isLoader, setIsLoader] = React.useState(false);
  const [filterData, setfilterData] = React.useState({
    industry_id: industry_id,
    vendor_ids: [data?._id],
  });
  const modalRef = React.useRef(null);
  //   const [searchText, setSearchText] = React.useState("");
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      onCancelForm();
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onCancelForm();
    }
  };
  // document.addEventListener("keydown", handleHideDropdown, true);
  // document.addEventListener("click", handleClickOutside, true);

  const locationCol = [
    { headerName: "State", field: "state_id", flex: 1, minWidth: 150 },
    { headerName: "City", field: "city_id", flex: 1, minWidth: 150 },
    { headerName: "Zip code", field: "zip", flex: 1, minWidth: 100 },
    { headerName: "Domains", field: "domains", flex: 1, minWidth: 100 },
  ];
  const getVendorsLocations = async () => {
    if (!filterData?.industry_id)
      return toast.error("Please select the industry");
    if (!filterData?.vendor_ids.length)
      return toast.error("Please select the vendor");
    setIsLoader(true);
    try {
      const res = await api.post(`api/vendors/vendors_locations_by_industry`, {
        ...filterData,
      });
      if (res.status === 200) {
        setLocations(res.data);
      }
    } catch (error) {
      console.log("👊 ~ getVendorsLocations ~ error:", error);
    }
    setIsLoader(false);
  };
  const handleFilterModelChange = (e) => {
    setFilterConditions(e?.items);
  };
  const handleFilterModelChanges = (filterConditions) => {
    const isAnyValueUndefined = filterConditions.some((condition) => {
      return condition.value === undefined && condition.operator !== "isEmpty";
    });

    if (isAnyValueUndefined) {
      return locations;
    }
    const filteredRows = locations?.filter((row) => {
      return filterConditions.every((condition) => {
        const { field, operator, value } = condition;
        let fieldValue;
        if (field === "state_id") {
          fieldValue = row.state_id?.name;
        } else if (field === "city_id") {
          fieldValue = row.city_id?.name;
        } else {
          fieldValue = row[field];
        }
        const fieldValueStr =
          typeof fieldValue === "string"
            ? fieldValue.toLowerCase()
            : fieldValue;
        const valueStr =
          typeof value === "string" ? value.toLowerCase() : value;

        // Apply filter conditions
        if (operator === "contains") {
          return fieldValueStr?.includes(valueStr);
        }
        if (operator === "equals") {
          return fieldValueStr === valueStr;
        }
        if (operator === "startsWith") {
          return fieldValueStr?.startsWith(valueStr);
        }
        if (operator === "endsWith") {
          return fieldValueStr?.endsWith(valueStr);
        }
        if (operator === "isEmpty") {
          return (
            fieldValueStr === null ||
            fieldValueStr === undefined ||
            (Array.isArray(fieldValueStr) && fieldValueStr.length === 0) ||
            (typeof fieldValueStr === "string" && fieldValueStr.trim() === "")
          );
        }
        if (operator === "isNotEmpty") {
          return !(
            fieldValueStr === null ||
            fieldValueStr === undefined ||
            (Array.isArray(fieldValueStr) && fieldValueStr.length === 0) ||
            (typeof fieldValueStr === "string" && fieldValueStr.trim() === "")
          );
        }
        if (operator === "isAnyOf") {
          return value?.includes(fieldValueStr);
        }

        return true;
      });
    });

    return filteredRows;
  };

  const filteredLocations = handleFilterModelChanges(filterConditions);
  function exportLocationDataToExcel() {
    const rows = [];
    const exportDateTime = new Date(); // Get current date and time
    rows.push([
      "Exported on:",
      dayjs(exportDateTime).format("ddd, MMM D, YYYY h:mm A"),
    ]);
    rows.push(["#", "State", "City", "Zip", "Domains"]);

    filteredLocations.forEach((item, index) => {
      const { state_id, city_id, zip, domains } = item;
      const stateName = state_id?.name || "";
      const cityName = city_id?.name || "";
      const domainString = domains ? domains.join("\n") : "";
      rows.push([index + 1, stateName, cityName, zip, domainString]);
    });

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Location Data with Domains");
    const exportRow = worksheet.addRow(rows[0]);
    exportRow.font = { bold: true };

    const headerRow = worksheet.addRow(rows[1]);
    headerRow.font = { bold: true };
    filteredLocations.forEach((item, index) => {
      const { state_id, city_id, zip, domains } = item;
      const stateName = state_id?.name || "";
      const cityName = city_id?.name || "";
      const domainString = domains ? domains.join("\n") : "";
      worksheet.addRow([index + 1, stateName, cityName, zip, domainString]);
    });

    for (let i = 1; i <= 1; i++) {
      worksheet.getColumn(i).alignment = {
        vertical: "middle",
        horizontal: "left",
      };
    }
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 20;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 10;
    worksheet.getColumn(5).width = 40;

    // Write to buffer
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: "application/octet-stream" });
      saveAs(
        blob,
        `location_report(${dayjs(exportDateTime).format("YYYY-M-D")}).xlsx`
      );
    });
  }
  const LineOne = () => {
    return (
      <div className="flex items-center gap-x-2 justify-end">
        <select
          value={filterData?.industry_id}
          onChange={(e) => {
            setfilterData({
              ...filterData,
              industry_id: e.target.value,
            });
          }}
          className="border p-1 rounded h-[35px] ml-2 min-w-[250px]"
        >
          <option value="">Select Industry</option>
          {(data?.industry_ids || []).map((option) => (
            <option
              key={option._id}
              value={option._id}
              className="flex justify-between"
            >
              {option?.name}
            </option>
          ))}
        </select>
        <Button
          text={"Get Locations"}
          className="py-1.5 px-3 align-middle mr-2 bg-primary-100 text-white"
          onClick={getVendorsLocations}
        />
        {filteredLocations?.length > 0 ? (
          <Button
            text={"Export"}
            className="py-1.5 px-3 align-middle mr-2 bg-menu text-white"
            onClick={exportLocationDataToExcel}
          />
        ) : null}
      </div>
    );
  };
  useEffect(() => {
    getVendorsLocations();
  }, []);

  return (
    <>
      <div className="fixed top-0 bottom-0 left-0 right-0 z-[100] flex justify-center items-center bg-black/60">
        {isLoader ? <DotsLoader /> : null}
        <div className="container-fluid mt-2  text-center">
          <div
            className="inline-block bg-white w-3/4 my-0 mx-auto p-4 bg-red-600 rounded  max-h-[87vh]"
            ref={modalRef}
          >
            <header className="flex items-center justify-center shadow-lg bg-primary-100 text-white h-[50px] mb-3 text-xl">
              <h1 className="text-2xl text-white m-4 font-medium">
                {`(${getFullName(data)}) `} Locations for{" "}
                {`${
                  data?.industry_ids?.find(({ _id }) => _id === industry_id)
                    ?.name || ""
                }`}
              </h1>
            </header>

            {/* <LineOne /> */}
            {/* <div className="flex flex-row justify-between">
              <input
                placeholder="Search"
                onChange={(e) => setSearchText(e.target.value)}
                className="!w-1/3 mb-2 placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md !py-2 px-2 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                value={searchText}
              />
              <span className="text-xl">
                {filteredLocations?.length} records found
              </span>
            </div> */}

            <MUIDataTable
              columnDefs={locationCol}
              items={filteredLocations?.map((data, index) => {
                const { city_id, state_id, zip, domains } = data;
                return {
                  records: { ...data },
                  city_id: city_id?.name,
                  state_id: state_id?.name,
                  zip,
                  domains: domains?.length ? domains : "",
                };
              })}
              pagination="No"
              searchable="No"
              hideFooter={true}
              // toolbar={isFull === "locations" ? "Yes" : "No"}
              height={"60vh"}
              displayCount="No"
              onFilterModelChange={handleFilterModelChange}
            />
            <Button
              text="Cancel"
              variant="btn_cancel"
              className="ml-3"
              onClick={onCancelForm}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default VendorsLocationModal;
