import React from "react";
import CustomerInformation from "./Components/CustomerInformation";
import { Button, DotsLoader } from "../../components";
import DynamicComponent from "./Components";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFormattedDate } from "../../util/common";
import {
  create_lead_initial,
  single_form_create_job,
  single_form_save_data,
} from "../../features/leadsSlice";
import errorMessage from "../../util/errorMessage";
import { toast } from "react-toastify";
import ConfirmationModal from "./Components/ConfirmationModal";
import SuccessMsg from "./Components/SuccessMsg";
import labels from "../Lead/Components/leads_labels.json";
import { FaCopy } from "react-icons/fa";
function AddUpdateLeadBetaPage({
  formik,
  vicidialId,
  setIsLoader,
  isLoader,
  callSellLeadToServiceDirect,
  checkServiceMess,
  checkServiceDirect,
}) {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading } = useSelector((state) => state.leads);
  const [errors, setErrors] = React.useState([]);
  const [confirmationModal, setConfirmationModal] = React.useState(false);

  const {
    job_number = "",
    name = "",
    extra_fields = {},
    phone,
    complete_pickup_address,
    call_payload,
    scheduled_end,
    scheduled_on,
    industry_name,
    pickup_city,
    pickup_state,
    pickup_zip,
    special_instruction,
  } = formik?.values || {};
  function validateForm(data) {
    const errors = [];
    if (!data.phone) {
      errors.push({ phone: "Phone is not allowed to be empty" });
      toast.error("Phone is not allowed to be empty");
      return errors;
    }
    if (!data.name) {
      errors.push({ name: '"name" is not allowed to be empty' });
      toast.error("name is not allowed to be empty");
      return errors;
    }
    if (!data.complete_pickup_address) {
      errors.push({ job_address: '"job_address" required' });
      toast.error("Job Address is required");
      return errors;
    }
    if (!data.pickup_city) {
      errors.push({ pickup_city: '"pickup_city" required' });
      toast.error("Pickup city is required");
      return errors;
    }
    if (!data.pickup_state) {
      errors.push({ pickup_state: '"pickup_state" required' });
      toast.error("Pickup state is required");
      return errors;
    }
    return errors;
  }
  const isUpdating = location.pathname.startsWith("/leads/update");
  const getElementIdsWithExtraFields = () => {
    const elements = document.querySelectorAll('[id*="extra_fields."]');
    const ids = Array.from(elements).map((el) => el.id); // Extract ids
    return ids;
  };
  const populateExtraFields = (userInput, fieldArray) => {
    const extraFields = {};
    // Helper function to determine if a field is boolean
    const isBooleanField = (fieldName) => {
      // Define the boolean fields or use logic to infer them
      const booleanFields = [
        "extra_fields.upholstery_cleaning.couch",
        "extra_fields.upholstery_cleaning.chair",
        "extra_fields.upholstery_cleaning.other",
        "extra_fields.rug_cleaning.rug_in_ft",
        "extra_fields.rug_cleaning.ad",
        "extra_fields.specific_spot",
        "extra_fields.is_inspection",
        "extra_fields.is_installation",
        "extra_fields.accessible",
        "extra_fields.damage",
      ];

      // Check if the fieldName matches any boolean field
      return booleanFields.some((booleanField) =>
        fieldName.endsWith(booleanField)
      );
    };

    fieldArray.forEach((field) => {
      const keys = field.split(".").slice(1); // Extract keys after "extra_fields"
      let currentField = extraFields;

      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          // If it's the last key, set the value
          if (isBooleanField(field)) {
            // Handle boolean fields: default to false if empty
            currentField[key] = !!userInput?.[keys.join(".")];
          } else {
            // Handle non-boolean fields: default to empty string if empty
            currentField[key] = userInput?.[keys.join(".")] ?? "";
          }
        } else {
          // If it's not the last key, ensure the key exists as an object
          currentField[key] = currentField[key] || {};
          currentField = currentField[key];
        }
      });
    });

    return extraFields;
  };

  const handleCreateJob = async (type) => {
    const errors = validateForm(formik.values);
    if (errors?.length === 0) {
      const fields = getElementIdsWithExtraFields();
      const populatedExtraFields = populateExtraFields(
        formik?.values?.extra_fields,
        fields
      );
      try {
        let leadId = formik.values.id;
        if (!isUpdating) {
          const initialLead = await dispatch(
            create_lead_initial({
              industry_id: formik.values.industry_id
                ? formik.values.industry_id
                : "6366126737c4d7c72204c6c0",
              // : industry?.industryDrd?.find(({ name }) => name === "Towing")
              //     ?._id,
              phone:
                formik?.values?.phone?.length === 10 &&
                !formik?.values?.phone?.includes("+")
                  ? `+1${formik?.values?.phone}`
                  : formik?.values?.phone?.length === 11 &&
                    !formik?.values?.phone?.includes("+") &&
                    formik?.values?.phone?.startsWith("1")
                  ? `+${formik?.values?.phone}`
                  : formik?.values?.phone,
              call_payload: null,
              call_type: "Manual",
              vicidial_id: null,
            })
          );
          if (initialLead?.payload?.status === 200) {
            leadId = initialLead?.payload?.data?._id;
          }
        }
        const action =
          type === "data" ? single_form_save_data : single_form_create_job;
        const res = await dispatch(
          action({
            id: leadId,
            phone: formik?.values.phone,
            name: formik?.values.name,
            email: formik?.values.email ? formik?.values.email : null,
            alternate_phone: formik?.values.alternate_phone
              ? formik?.values.alternate_phone
              : null,
            scheduled_on: formik?.values.scheduled_on,
            industry_id: formik.values.industry_id
              ? formik.values.industry_id
              : "6366126737c4d7c72204c6c0",
            complete_pickup_address: formik?.values.complete_pickup_address,
            pickup_country: formik?.values.pickup_country,
            pickup_city: formik?.values.pickup_city,
            pickup_state: formik?.values.pickup_state,
            pickup_zip: formik?.values.pickup_zip,
            pickup_latitude: formik?.values.pickup_latitude?.toString(),
            pickup_longitude: formik?.values.pickup_longitude?.toString(),
            special_instruction: formik?.values.special_instruction,
            scheduled_end: formik?.values.scheduled_end,
            payment_type: formik?.values.payment_type,
            extra_fields: populatedExtraFields,
          })
        );
        if (res?.payload?.status === 201 || res?.payload?.status === 200) {
          setConfirmationModal(false);
          if (type === "data") {
            navigate(`/leads/update/${res?.payload?.data?._id}`, {
              state: {
                name: formik.values.name,
                email: formik.values.email,
                alternate_phone: formik.values.alternate_phone,
                menualVicidialId: vicidialId,
              },
            });
            toast.success("Data updated successfully");
          } else {
            formik.setFieldValue("job_created", true);
            formik.setFieldValue(
              "job_number",
              res?.payload?.data?.job?.job_number
            );
            formik.setFieldValue("id", leadId);
            toast.success("Job created successfully");
          }
          // setResData(res?.payload?.data);
          // setIsLeadCreate(true);
          // dispatch(isJobComplete(true));
          // toast.success("Job create successfully");
          // navigate(`/jobs/update/${res?.payload?.data?.job?._id}`);
        } else {
          if (
            res.payload?.error?.length > 0 &&
            res.payload?.error[0]?.industry_id
          ) {
            toast.error("Please Select the industry again");
          }
          errorMessage({
            payload: res.payload?.error,
            setErrors: setErrors,
            action: "Job",
            msg: "created",
          });
        }
      } catch (error) {
        console.error(
          "🚀 ~ file: index.jsx:421 ~ handleCreateJob ~ error:",
          error
        );
        errorMessage({
          payload: error,
          setErrors: setErrors,
          action: "Job",
          msg: "created",
        });
      }
    } else {
      errorMessage({
        payload: errors,
        setErrors: setErrors,
        action: "Job",
        msg: "created",
      });
    }
  };

  const formatValue = (val) => {
    if (val === undefined) return "";
    if (val === "false" || val === false) {
      return "No";
    }
    if (typeof val !== "boolean" && !val) return "";
    if (typeof val === "boolean") {
      return val ? "Yes" : "No";
    }
    if (val && !val?.includes("_")) return val;
    return val
      ?.split("_")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const fields = getElementIdsWithExtraFields();
  const populatedExtraFields = populateExtraFields(
    formik?.values?.extra_fields,
    fields
  );
  const formatAddress = (completeAddress, city, state, zip) => {
    const normalizeValue = (value) => value?.toLowerCase().trim() || "";

    // Normalize inputs for case-insensitive comparison
    const normalizedAddress = normalizeValue(completeAddress);
    const normalizedCity = normalizeValue(city);
    const normalizedState = normalizeValue(state);
    const normalizedZip = normalizeValue(zip);

    let formattedAddress = completeAddress || "";

    // Handle zip: Check if the zip exists or if it's a substring (not the exact match)
    if (
      zip &&
      (!normalizedAddress.includes(normalizedZip) || // Zip isn't in the address
        !new RegExp(`\\b${normalizedZip}\\b`).test(normalizedAddress)) // Exact zip is not present as a separate word
    ) {
      formattedAddress += ` ${zip}`;
    }

    // Handle city: Append if not already in the address
    if (city && !normalizedAddress.includes(normalizedCity)) {
      formattedAddress += ` ${city}`;
    }

    // Handle state: Append if not already in the address
    if (state && !normalizedAddress.includes(normalizedState)) {
      formattedAddress += ` ${state}`;
    }

    return formattedAddress.trim();
  };
  const copyToClipboard = () => {
    const formattedText = [
      `Hi, New job ${job_number ? `${job_number}` : ""} - ${name || ""}`,
      `*Industry: ${industry_name || ""}*`,
      "",
      `*Customer Details*`,
      `Name: ${formatValue(name) || ""}`,
      `Phone: ${formatValue(phone) || ""}`,
      `Scheduled: ${scheduled_on ? `${getFormattedDate(scheduled_on)}` : ""}${
        scheduled_end ? ` - ${getFormattedDate(scheduled_end)}` : ""
      }`,
      `Address: ${
        formatAddress(
          complete_pickup_address,
          pickup_city,
          pickup_state,
          pickup_zip
        ) || ""
      }`,
      // `City: ${formatValue(pickup_city) || ""}`,
      // `State: ${formatValue(pickup_state) || ""}`,
      // `Zip: ${formatValue(pickup_zip) || ""}`,
      "",
      `*Job Details*`,
    ];

    // Handle extra fields

    if (populatedExtraFields || formik?.values?.extra_fields) {
      Object.keys(populatedExtraFields || formik?.values?.extra_fields).forEach(
        (key) => {
          const label = labels[key] || key;
          const innerObject =
            (populatedExtraFields && populatedExtraFields[key]) ||
            (formik?.values?.extra_fields && formik.values.extra_fields[key]) ||
            "";

          if (typeof innerObject === "object" && innerObject !== null) {
            formattedText.push("");
            formattedText.push(`*${label}*`);

            Object.keys(innerObject).forEach((innerKey) => {
              const innerLabel = labels[innerKey] || innerKey;
              formattedText.push(
                `${innerLabel?.endsWith(":") ? innerLabel : `${innerLabel}:`} ${
                  formatValue(innerObject[innerKey]) || ""
                }`
              );
            });
          } else {
            formattedText.push(
              `${label?.endsWith(":") ? label : `${label}:`} ${
                formatValue(innerObject) || ""
              }`
            );
          }
        }
      );
    }
    formattedText.push("");
    formattedText.push(
      `*Additional Information*: ${formatValue(special_instruction) || ""}`
    );

    // Join the array into a string and copy to clipboard
    navigator.clipboard.writeText(formattedText.join("\n")).then(() => {
      toast.success("Copied to clipboard!");
    });
  };

  const renderInfo = (label, value) => {
    const formattedLabel = label?.endsWith(":") ? label.slice(0, -1) : label;
    return (
      <div className="text-sm">
        {label ? (
          <label className="inline-block whitespace-nowrap">
            {formattedLabel}:
          </label>
        ) : null}
        <span className="inline-block ml-1 break-words">
          {formatValue(value)}
        </span>
      </div>
    );
  };

  const renderHeader = (label, value) => {
    const formattedLabel = label?.endsWith(":") ? label.slice(0, -1) : label;
    return label ? (
      <div className="flex gap-x-1 text-sm mt-2">
        <label className="font-semibold">
          {value ? `${formattedLabel}:` : formattedLabel}
        </label>
        {value && <span>{formatValue(value)}</span>}
      </div>
    ) : null;
  };
  const getValueOrDefault = (value, defaultValue) => {
    return value !== undefined && value !== null ? value : defaultValue;
  };
  return formik?.values?.job_number ? (
    <SuccessMsg
      formik={formik}
      resData={{
        _id: formik?.values?.id,
        job_number: formik?.values?.job_number,
      }}
    />
  ) : (
    <div>
      {isLoading || isLoader ? <DotsLoader /> : null}
      {confirmationModal ? (
        <ConfirmationModal
          onClose={() => setConfirmationModal(false)}
          handleCreateJob={handleCreateJob}
        />
      ) : null}
      <div className="md:borde md:bg-light md:p-2 rounded">
        <div className="px- mb-2">
          <CustomerInformation formik={formik} errors={errors} />
        </div>
      </div>
      <div className="grid grid-cols-5 px-2 gap-4">
        <div className="col-span-4">
          <DynamicComponent
            formik={formik}
            industryName={formik?.values?.industry_name}
            errors={errors}
            setIsLoader={setIsLoader}
            callSellLeadToServiceDirect={callSellLeadToServiceDirect}
            checkServiceMess={checkServiceMess}
            checkServiceDirect={checkServiceDirect}
          />
        </div>
        <div className="col-span-1 flex items-start justify-between">
          <div className="bg-[#F7F7F7] !p-3">
            <div className="flex items-center justify-between">
              <p className="text-base font-bold">Whats App Format</p>
              <button onClick={copyToClipboard}>
                <FaCopy />
              </button>
            </div>
            <p className="text-base">{`Hi, New job ${
              job_number ? `${job_number}` : ``
            } - ${name || ""}`}</p>
            <div className="pl-2 pt-2">
              {renderHeader(`Industry: ${industry_name || ""}`)}
              {renderHeader("Customer Details")}
              {renderInfo("Name", name || "")}
              {renderInfo("Phone", phone || "")}
              {renderInfo(
                "Scheduled",
                `${getFormattedDate(scheduled_on)}${
                  scheduled_end ? ` - ${getFormattedDate(scheduled_end)}` : ""
                }`
              )}
              {renderInfo(
                "Address",
                formatAddress(
                  complete_pickup_address,
                  pickup_city,
                  pickup_state,
                  pickup_zip
                ) || ""
              )}
              {/* {renderInfo("City", pickup_city || "")}
              {renderInfo("State", pickup_state || "")}
              {renderInfo("Zip", pickup_zip || "")} */}
              {populatedExtraFields || formik?.values?.extra_fields ? (
                <div>
                  {Object.keys(
                    populatedExtraFields || formik?.values?.extra_fields
                  )?.map((key) => {
                    const label = labels[key] || key;
                    const innerObject =
                      (populatedExtraFields &&
                        getValueOrDefault(populatedExtraFields[key], "")) ??
                      (formik?.values?.extra_fields &&
                        getValueOrDefault(formik.values.extra_fields[key], ""));

                    if (
                      typeof innerObject === "object" &&
                      innerObject !== null
                    ) {
                      return (
                        <div key={key}>
                          {renderHeader(label)}{" "}
                          {Object.keys(innerObject).map((innerKey) => {
                            const innerLabel = labels[innerKey] || innerKey;
                            const incrementedLabel =
                              !isNaN(innerLabel) && innerLabel.trim() !== ""
                                ? (parseInt(innerLabel) + 1).toString()
                                : innerLabel;
                            return renderInfo(
                              incrementedLabel,
                              innerObject[innerKey]
                            );
                          })}
                        </div>
                      );
                    }
                    return renderInfo(label, innerObject);
                  })}
                </div>
              ) : null}
              {renderHeader("Additional Information", "")}
              {renderInfo("", special_instruction || "")}
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-end px-2">
        <Button
          text="Proceed"
          className="bg-primary-100 text-white p-2"
          onClick={() => setConfirmationModal(true)}
        />
      </div>
    </div>
  );
}

export default AddUpdateLeadBetaPage;
