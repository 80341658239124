import React, { useEffect } from "react";
import {
  Backdrop,
  Button,
  DotsLoader,
  FormInput,
  FormSelectInput,
  FormTextArea,
} from "../../components";
import api from "../../services/api";

import { useFormik } from "formik";
import TagsInput from "../../components/atoms/CustomTagsInput";
import { useDispatch, useSelector } from "react-redux";
import {
  create_merchant,
  sync_merchant_cx,
  update_merchant,
  update_merchant_cx,
} from "../../features/merchantSlice";
import { toast } from "react-toastify";
import { get_call_centers } from "../../features/callCentersSlice";
import { AiFillCloseCircle } from "react-icons/ai";
import errorMessage from "../../util/errorMessage";
import { FaEye, FaEyeSlash, FaSync } from "react-icons/fa";
import { MultiSelect } from "primereact/multiselect";
import { filterPayload } from "../../util/filterPayload";
import MessageModal from "./MessageModal";
import {
  updateMerchantsBVS,
  updateThirdPartySetting,
} from "../../services/merchantService";
const AddUpdateForm = ({
  editingRecord,
  onCancelForm,
  modalTitle,
  connectcx,
  reloadData,
}) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.merchant);
  const { industryDrd } = useSelector((state) => state.industry);
  const [showPass, setShowPass] = React.useState(false);
  const [didList, setDidList] = React.useState([]);
  const [isLoader, setIsLoading] = React.useState(false);

  const [loading, setLoading] = React.useState(false);
  const [bridgingDids, setBridgingDids] = React.useState(
    editingRecord?.bridging_dids || []
  );
  const { countries, states } = useSelector((state) => state.countries);
  const [isCustom, setIsCustom] = React.useState(false);
  const jobCategory = useSelector((state) => state.jobCategory);
  const callCenters = useSelector((state) => state.callCenters);
  const [errors, setErrors] = React.useState([]);
  const [logoURL, setLogoURL] = React.useState(
    editingRecord ? editingRecord?.logo_url : ""
  );
  const { ccCards, ccProviders } = useSelector((state) => state.dids);

  let initialValues = {
    name: "",
    email: "",
    fax: "",
    country_id: "",
    state_id: "",
    city: "",
    zip: "",
    street: "",
    address: "",
    phone: "",
    username: "",
    company_name: "",
    industry_id: [],
    call_center_id: "",
    merchant_logo: "",
    pswrd: "",
    cc_apikey: "",
    website: "",
    sms_number_for_vendor: "",
    sms_number_for_other: "",
    enable_opt_sms: true,
  };
  let ids = [];
  if (editingRecord) {
    const {
      _id,
      name,
      email,
      fax,
      country_id,
      state_id,
      city,
      zip,
      street,
      address,
      username,
      company_name,
      industry_id,
      user_group_id,
      pswrd,
      cc_apikey,
      phone,
      conexcs_settings,
      website,
      sms_number_for_vendor,
      sms_number_for_other,
      sms_opt_settings,
      BVS_settings,
      third_party_setting,
    } = editingRecord;
    // const { cx, destination_ip, dial_out_prefix } = conexcs_settings;
    industry_id?.map((val) => {
      ids = [...ids, val?._id];
      return { ids };
    });

    initialValues = {
      id: _id,
      name,
      email,
      fax,
      country_id: country_id?._id,
      state_id: state_id?._id,
      city,
      zip,
      street,
      address,
      username,
      company_name,
      industry_id: ids,
      call_center_id: user_group_id?.call_center_id,
      pswrd,
      cc_apikey:
        cc_apikey === "undefined" || cc_apikey === "null" ? "" : cc_apikey,
      phone,
      cx: conexcs_settings?.cx || "",
      destination_ip: conexcs_settings?.destination_ip || "",
      dial_out_prefix: conexcs_settings?.dial_out_prefix || "",
      customer_card: conexcs_settings?.customer_card || "",
      provider: conexcs_settings?.provider || "",
      provider_card: conexcs_settings?.provider_card || "",
      website,
      sms_number_for_vendor,
      sms_number_for_other,
      enable_opt_sms: sms_opt_settings?.enable_opt_sms,
      bvs_settings: {
        cx: BVS_settings?.cx || "",
        destination_ip: BVS_settings?.destination_ip || "",
        dial_out_prefix: BVS_settings?.dial_out_prefix || "",
        customer_card: BVS_settings?.customer_card || "",
        provider: BVS_settings?.provider || "",
        provider_card: BVS_settings?.provider_card || "",
      },
      third_party_setting,
    };
  }

  const formik = useFormik({
    initialValues,
    validateOnBlur: true,

    onSubmit: async (values) => {
      const { enable_opt_sms, ...rest } = values;
      const sms_opt_settings = {
        enable_opt_sms,
      };
      const modifiedPayload = {
        ...rest,
        sms_opt_settings,
      };

      if (!isLoading) {
        const formattedPayload = filterPayload(modifiedPayload);
        delete formattedPayload.cx;
        delete formattedPayload.destination_ip;
        delete formattedPayload.dial_out_prefix;
        delete formattedPayload.customer_card;
        delete formattedPayload.provider;
        delete formattedPayload.provider_card;
        delete formattedPayload.bvs_settings;
        delete formattedPayload.third_party_setting;
        formattedPayload.bridging_dids = bridgingDids;
        if (!editingRecord) {
          try {
            const res = await dispatch(create_merchant(formattedPayload));
            if (res?.payload?.status === 201) {
              toast.success("Merchant created");
              reloadData();
              onCancelForm();
            } else {
              errorMessage({
                payload: res.payload,
                action: "Merchant",
                msg: "created",
                setErrors: setErrors,
              });
            }
          } catch (error) {
            console.error(
              "🚀 ~ file: AddUpdateForm.jsx:148 ~ onSubmit: ~ error:",
              error
            );
            errorMessage({
              payload: error,
              action: "Merchant",
              msg: "created",
              setErrors: setErrors,
            });
          }
        } else {
          try {
            delete formattedPayload.username;
            delete formattedPayload.call_center_id;
            const res = await dispatch(update_merchant(formattedPayload));
            if (res?.payload?.status === 200) {
              toast.success("Merchant updated");
              reloadData();
              onCancelForm();
            } else {
              errorMessage({
                payload: res.payload,
                action: "Merchant",
                msg: "update",
                setErrors: setErrors,
              });
            }
          } catch (error) {
            errorMessage({
              payload: error,
              action: "Merchant",
              msg: "update",
              setErrors: setErrors,
            });
          }
        }
      } else {
        toast.error("Please wait until previous action be completed");
      }
    },
  });

  const saveCXSetting = async () => {
    const {
      id,
      cx,
      destination_ip,
      dial_out_prefix,
      provider_card,
      provider,
      customer_card,
    } = formik.values;

    try {
      setLoading(true);
      const res = await dispatch(
        update_merchant_cx({
          id,
          cx,
          destination_ip,
          dial_out_prefix,
          provider_card,
          provider,
          customer_card,
        })
      );
      if (res.payload.status === 200) {
        toast.success("CX setting updated successfully");
        onCancelForm();
      } else {
        toast.error(`CX setting couldn't be updated`);
      }
      setLoading(false);
    } catch (err) {
      toast.error(`CX setting couldn't be updated`);
      setLoading(false);
      console.log("🚀 ~ getCX ~ err:", err);
    }
  };
  const syncCXSetting = async () => {
    const c = window.confirm("Are you sure want to sync the merchant?");
    if (!c) return;
    const { id, cx, destination_ip, dial_out_prefix } = formik.values;

    try {
      setLoading(true);
      const res = await dispatch(
        sync_merchant_cx({
          id,
          cx,
          destination_ip,
          dial_out_prefix,
        })
      );
      if (res.payload.status === 200) {
        toast.success("CX setting sync successfully");
        onCancelForm();
      } else {
        toast.error(`CX setting couldn't be synced`);
      }
      setLoading(false);
    } catch (err) {
      toast.error(`CX setting couldn't be synced`);
      setLoading(false);
      console.log("🚀 ~ getCX ~ err:", err);
    }
  };

  const saveBVSSetting = async () => {
    const { id, bvs_settings } = formik.values;

    try {
      setLoading(true);
      const payload = {
        id,
        cx: bvs_settings?.cx,
        destination_ip: bvs_settings?.destination_ip,
        dial_out_prefix: bvs_settings?.dial_out_prefix,
        provider_card: bvs_settings?.provider_card,
        provider: bvs_settings?.provider,
        customer_card: bvs_settings?.customer_card,
      };
      const res = await updateMerchantsBVS(payload);
      if (res.status === 200) {
        toast.success("BVS setting updated successfully");
        reloadData();
        onCancelForm();
      } else {
        toast.error(`BVS setting couldn't be updated`);
      }
      setLoading(false);
    } catch (err) {
      toast.error(`BVS setting couldn't be updated`);
      setLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    dispatch(get_call_centers());
    if (formik.values.id) {
      // getCX();
    }
    // eslint-disable-next-line
  }, []);
  const onFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      formik.setFieldValue("merchant_logo", file);
      setLogoURL("");
    }
  };
  const providerCardsOption = ccCards?.vendors?.filter(
    ({ direction, company_id }) =>
      direction === "origination" && company_id === +formik.values.provider
  );

  const bvsProviderCardsOption = formik?.values?.bvs_settings?.provider
    ? ccCards?.vendors?.filter(
        ({ direction, company_id }) =>
          direction === "origination" &&
          company_id === +formik?.values?.bvs_settings?.provider
      )
    : [];

  const saveThirdPartySetting = async () => {
    const { id, third_party_setting } = formik.values;
    let parsedSetting = {};
    try {
      parsedSetting = JSON.parse(third_party_setting);
    } catch (err) {
      parsedSetting = third_party_setting;
    }
    try {
      setLoading(true);
      const payload = {
        id,
        third_party_setting: parsedSetting,
      };
      const res = await updateThirdPartySetting(payload);
      if (res.status === 200) {
        toast.success("Third Party setting updated successfully");
        reloadData();
        onCancelForm();
      } else {
        toast.error(`Third Party setting couldn't be updated`);
      }
      setLoading(false);
    } catch (err) {
      toast.error(`Third Party setting couldn't be updated`);
      setLoading(false);
      console.log(err);
    }
  };

  const getTextareaValue = (value) => {
    if (typeof value === "object") {
      return JSON.stringify(value, null, 2);
    }
    return value;
  };

  // useEffect(() => {
  //   const getDidList = async () => {
  //     const payload = {
  //       phone_type: "SMS",
  //     };

  //     setIsLoading(true);
  //     try {
  //       const res = await api.post("/api/cc/dids/purchase_did_list", payload);
  //       if (res.status === 200) {
  //         setDidList(res?.data?.data?.data || []);
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //     setIsLoading(false);
  //   };
  //   // getDidList();
  // }, []);

  return (
    <>
      <div className="min-h-screen bg-white w-full z-[100] fixed top-0 left-0 overflow-y-auto">
        <div className="overflow-y-auto h-screen">
          <div className="flex items-center justify-between w-full text-client-50 py-2.5 px-5 bg-white shadow-client overflow-y-auto">
            <h4>
              {!!editingRecord
                ? modalTitle.replaceAll("Add", "Edit")
                : modalTitle}
            </h4>
            <Button
              text="X"
              className="mr-2"
              onClick={onCancelForm}
              variant="btn_danger"
            />
          </div>
          <Backdrop onClick={onCancelForm} />
          {isLoader || jobCategory?.isLoading || isLoading || loading ? (
            <DotsLoader />
          ) : null}

          <div className="!p-5 grid gap-5 md:grid-cols-2 mb-[15vh]">
            <div className="!py-5 !px-3 md:px-10 bg-white rounded-3xl shadow-client">
              <h5 className="!mb-3 text-client-50 pb-2.5 w-full border-b border-client-50">
                Merchant Information
              </h5>
              <div className="grid md:grid-cols-2 gap-x-5 !mt-5">
                <div className="!mb-2">
                  <FormInput
                    errors={errors}
                    name="name"
                    label="Name"
                    formik={formik}
                  />
                </div>
                <div className="!mb-2">
                  <FormInput
                    errors={errors}
                    name="company_name"
                    label="Company Name"
                    formik={formik}
                  />
                </div>
                <div className="mb-2">
                  <FormInput
                    errors={errors}
                    name="fax"
                    label="Fax"
                    formik={formik}
                  />
                </div>
                <div className="mb-2">
                  <FormInput
                    errors={errors}
                    name="phone"
                    label="Phone"
                    formik={formik}
                  />
                </div>

                <div className="mb-2">
                  <FormInput
                    errors={errors}
                    name="username"
                    label="Username"
                    formik={formik}
                    disabled={editingRecord ? true : false}
                  />
                </div>
                <div className="mb-2 relative">
                  <FormInput
                    errors={errors}
                    name="pswrd"
                    label="Password"
                    formik={formik}
                    type={showPass ? "text" : "password"}
                    // disabled={!editingRecord}
                  />
                  <div
                    className="absolute top-[8px] !right-2 border-l border-[#dee2e6] rounded flex justify-center items-center h-[25px] !px-1.5 cursor-pointer"
                    onClick={() => setShowPass(!showPass)}
                  >
                    {showPass ? (
                      <FaEye className="text-black" />
                    ) : (
                      <FaEyeSlash className="text-black" />
                    )}
                  </div>
                </div>
                <div className="mb-2">
                  <FormInput
                    errors={errors}
                    name="email"
                    label="Email"
                    formik={formik}
                    disabled={editingRecord ? true : false}
                  />
                </div>

                <div className="mb-3 md:mb-2">
                  <FormSelectInput
                    errors={errors}
                    name="call_center_id"
                    label="Call Center"
                    formik={formik}
                    options={callCenters?.record}
                    labelProp="url"
                    valueProp="_id"
                    disabled={editingRecord ? true : false}
                  />
                </div>

                <div className="mb-2">
                  <FormInput
                    errors={errors}
                    name="cc_apikey"
                    label="API Key"
                    formik={formik}
                  />
                </div>
                <div className="mb-2">
                  <MultiSelect
                    placeholder="Select Industries"
                    options={industryDrd}
                    optionLabel="name"
                    optionValue="_id"
                    filter
                    maxSelectedLabels={2}
                    value={formik.values.industry_id}
                    onChange={(e) =>
                      formik.setFieldValue("industry_id", e.value)
                    }
                    className="h-[38px] mb-2 w-full !rounded-[4px] focus:!shadow-none border-[#e5e7eb] hover:border-[#ced4da]"
                  />
                  {formik.values.industry_id?.length > 0 ? (
                    <small
                      style={{
                        fontSize: 12,
                        marginTop: 0,
                        paddingLeft: 16,
                        color: "#D32F2F",
                        background: "transparent",
                      }}
                    >
                      {`Total selected Industries: ${formik.values.industry_id?.length}`}
                    </small>
                  ) : null}
                </div>
                <div className="mb-3 md:mb-2">
                  <input type={"file"} onChange={onFileChange} />
                  {errors?.length > 0 &&
                    errors?.map((error, index) => {
                      return (
                        <>
                          {error.merchant_logo && (
                            // <div key={index} className="text-[#f00] text-sm mb-3.7 mt-1.5">{error[name].replaceAll(name,placeholder)}</div>
                            <small
                              style={{
                                fontSize: 12,
                                marginTop: -5,
                                paddingLeft: 16,
                                color: "#D32F2F",
                              }}
                              key={index}
                            >
                              {error.merchant_logo.replaceAll(
                                `"merchant_logo"`,
                                "Merchant Logo"
                              )}
                            </small>
                          )}
                        </>
                      );
                    })}
                </div>
                <div className="mb-2">
                  <FormInput
                    errors={errors}
                    name="website"
                    label="Website"
                    formik={formik}
                  />
                </div>
                <div className="mb-2">
                  <TagsInput
                    tags={bridgingDids}
                    setTags={setBridgingDids}
                    placeholder="Add Bridging Dids"
                    name="bridging_dids"
                  />
                </div>
                {formik.values.merchant_logo || logoURL ? (
                  <div className="mb-3 md:mb-2">
                    <AiFillCloseCircle
                      onClick={() => formik.setFieldValue("merchant_logo", "")}
                    />
                    <img
                      src={
                        logoURL
                          ? `${logoURL}`
                          : URL.createObjectURL(formik.values.merchant_logo)
                      }
                      alt="img"
                      className="m-2 max-h-[180px] max-w-[180px] text-center rounded-md cursor-pointer"
                      style={{
                        // width: 200,
                        // height: 200,
                        objectFit: "contain",
                        alignSelf: "center",
                      }}
                    />
                  </div>
                ) : null}
              </div>
              <h5 className="!mb-3 text-client-50 pb-2.5 w-full border-b border-client-50 mt-2">
                Message Center Numbers
              </h5>
              <div className="grid md:grid-cols-2 gap-x-5 !mt-5">
                <div className="mb-2">
                  <FormInput
                    errors={errors}
                    name="sms_number_for_vendor"
                    label="For Vendor"
                    formik={formik}
                  />
                  {/* <FormSelectInput
                    errors={errors}
                    name="sms_number_for_vendor"
                    label="For Vendor"
                    formik={formik}
                    options={(didList || [])?.map((item) => ({
                      value: item?.did,
                      label: item?.did,
                    }))}
                    labelProp={"label"}
                    valueProp={"value"}
                  /> */}
                </div>
                <div className="mb-2">
                  <FormInput
                    errors={errors}
                    name="sms_number_for_other"
                    label="For Others"
                    formik={formik}
                  />
                  {/* <FormSelectInput
                    errors={errors}
                    name="sms_number_for_other"
                    label="For Others"
                    formik={formik}
                    options={(didList || [])?.map((item) => ({
                      value: item?.did,
                      label: item?.did,
                    }))}
                    labelProp={"label"}
                    valueProp={"value"}
                  /> */}
                </div>
              </div>

              <h5 className="!mb-3 text-client-50 pb-2.5 w-full border-b border-client-50 mt-auto">
                SMS Compliance
              </h5>
              <div className="grid md:grid-cols-2 gap-x-5 !mt-5">
                <div className="mb-2">
                  <FormSelectInput
                    errors={errors}
                    name="enable_opt_sms"
                    label="Opt-In / Opt-Out"
                    formik={formik}
                    options={[
                      { value: "true", label: "Yes" },
                      { value: "false", label: "No" },
                    ]}
                    convertor={(value) => value === "true"}
                    valueProp="value"
                    labelProp="label"
                    value={formik.values?.enable_opt_sms}
                  />
                  <div className="py-1 leading-3 flex justify-center flex-col">
                    <button
                      type="button"
                      className="text-xs hover:underline text-blue-500 text-left"
                      onClick={() => setIsCustom(true)}
                    >
                      Click here to Trigger Test SMS
                    </button>
                  </div>
                </div>
              </div>

              {formik.values.id ? (
                <>
                  <h5 className="!mb-3 text-client-50 pb-2.5 w-full border-b border-client-50 mt-auto">
                    Third Party Setting
                  </h5>
                  <div className="w-full gap-x-5 !mt-5">
                    <div className="mb-2">
                      <FormTextArea
                        label="Third Party Setting"
                        rows={4}
                        name="third_party_setting"
                        value={getTextareaValue(
                          formik.values.third_party_setting
                        )}
                        formik={formik}
                        type="text"
                      />
                    </div>
                    <div className="col-span-2 my-2 flex justify-end items-center space-x-2">
                      <Button
                        text={"Save"}
                        className="py-1.5 px-3 align-middle bg-primary-100 text-white h-[40px]"
                        onClick={saveThirdPartySetting}
                      />
                    </div>
                  </div>
                </>
              ) : null}
            </div>
            <div className="!py-5 px-10 bg-white rounded-3xl shadow-client">
              <h5 className="!mb-3 text-client-50 pb-2.5 w-full border-b border-client-50">
                Address/Other Information
              </h5>
              <div className="grid md:grid-cols-2 gap-x-5 !mt-5">
                <div className="mb-2">
                  <FormInput
                    errors={errors}
                    name="address"
                    label="Address"
                    formik={formik}
                  />
                </div>
                <div className="mb-2">
                  <FormInput
                    errors={errors}
                    name="city"
                    label="City"
                    formik={formik}
                  />
                </div>
                <div className="mb-2">
                  <FormInput
                    errors={errors}
                    name="street"
                    label="Street"
                    formik={formik}
                  />
                </div>
                <div className="mb-2">
                  <FormSelectInput
                    errors={errors}
                    name="country_id"
                    label="Country"
                    formik={formik}
                    options={countries}
                    valueProp="_id"
                    labelProp="name"
                  />
                </div>
                <div className="mb-2">
                  <FormSelectInput
                    errors={errors}
                    name="state_id"
                    label="State"
                    formik={formik}
                    options={states}
                    valueProp="_id"
                    labelProp="name"
                  />
                </div>
                <div className="mb-2">
                  <FormInput
                    errors={errors}
                    name="zip"
                    label="Zip"
                    formik={formik}
                  />
                </div>
              </div>

              {formik.values.id ? (
                <div className="!py-1 bg-white">
                  <h5 className="!mb-3 text-client-50 pb-2.5 w-full border-b border-client-50">
                    Conexcs Settings
                  </h5>
                  <div className="grid md:grid-cols-2 gap-x-5 !mt-5">
                    <div className="mb-3 md:mb-2">
                      <FormSelectInput
                        errors={errors}
                        name="cx"
                        label="CX"
                        formik={formik}
                        options={connectcx}
                        labelProp="name"
                        valueProp="id"
                      />
                    </div>
                    <div className="mb-2">
                      <FormInput
                        errors={errors}
                        name="destination_ip"
                        label="Destination Server IP:Port "
                        formik={formik}
                      />
                    </div>

                    <div className="mb-2">
                      <FormInput
                        errors={errors}
                        name="dial_out_prefix"
                        label="Dial Out Prefix"
                        formik={formik}
                      />
                    </div>
                    <div>
                      <FormSelectInput
                        errors={errors}
                        name="customer_card"
                        label="Customer Card"
                        formik={formik}
                        options={[
                          ...ccCards?.customers?.filter(
                            ({ direction }) => direction === "origination"
                          ),
                        ]}
                        valueProp="id"
                        labelProp="name"
                      />
                    </div>
                    <div>
                      <FormSelectInput
                        errors={errors}
                        name="provider"
                        label="Provider"
                        formik={formik}
                        options={ccProviders}
                        valueProp="id"
                        labelProp="name"
                      />
                    </div>
                    <div>
                      <FormSelectInput
                        errors={errors}
                        name="provider_card"
                        label="Provider Card"
                        formik={formik}
                        options={providerCardsOption}
                        valueProp="id"
                        labelProp="name"
                      />
                    </div>
                    <div className="col-span-2 my-2 flex justify-end items-center space-x-2">
                      <Button
                        // text={"Save"}
                        className="py-1.5 px-3 align-middle bg-menu text-white h-[40px]"
                        onClick={syncCXSetting}
                      >
                        <FaSync />
                      </Button>
                      <Button
                        text={"Save"}
                        className="py-1.5 px-3 align-middle bg-primary-100 text-white h-[40px]"
                        onClick={saveCXSetting}
                      />
                    </div>
                  </div>
                </div>
              ) : null}

              {formik.values.id ? (
                <div className="!py-2 bg-white">
                  <h5 className="!mb-3 text-client-50 pb-2.5 w-full border-b border-client-50">
                    BVS Settings
                  </h5>
                  <div className="grid md:grid-cols-2 gap-x-5 !mt-0">
                    <div className="mb-3 md:mb-2">
                      <FormSelectInput
                        errors={errors}
                        name="bvs_settings.cx"
                        label="CX"
                        formik={formik}
                        options={connectcx}
                        labelProp="name"
                        valueProp="id"
                        value={formik.values?.bvs_settings.cx}
                      />
                    </div>
                    <div className="mb-2">
                      <FormInput
                        errors={errors}
                        name="bvs_settings.destination_ip"
                        label="Destination Server IP:Port "
                        formik={formik}
                        value={formik.values?.bvs_settings.destination_ip}
                      />
                    </div>

                    <div className="mb-2">
                      <FormInput
                        errors={errors}
                        name="bvs_settings.dial_out_prefix"
                        label="Dial Out Prefix"
                        formik={formik}
                        value={formik.values?.bvs_settings.dial_out_prefix}
                      />
                    </div>
                    <div>
                      <FormSelectInput
                        errors={errors}
                        name="bvs_settings.customer_card"
                        label="Customer Card"
                        formik={formik}
                        options={[
                          ...ccCards?.customers?.filter(
                            ({ direction }) => direction === "origination"
                          ),
                        ]}
                        valueProp="id"
                        labelProp="name"
                        value={formik.values?.bvs_settings.customer_card}
                      />
                    </div>
                    <div>
                      <FormSelectInput
                        errors={errors}
                        name="bvs_settings.provider"
                        label="Provider"
                        formik={formik}
                        options={ccProviders}
                        valueProp="id"
                        labelProp="name"
                        value={formik.values?.bvs_settings.provider}
                      />
                    </div>
                    <div>
                      <FormSelectInput
                        errors={errors}
                        name="bvs_settings.provider_card"
                        label="Provider Card"
                        formik={formik}
                        options={bvsProviderCardsOption}
                        valueProp="id"
                        labelProp="name"
                        value={formik.values?.bvs_settings.provider_card}
                      />
                    </div>
                    <div className="col-span-2 my-2 flex justify-end items-center space-x-2">
                      <Button
                        text={"Save"}
                        className="py-1.5 px-3 align-middle bg-primary-100 text-white h-[40px]"
                        onClick={saveBVSSetting}
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="w-full absolute flex justify-center bottom-0 !p-5 bg-white border-t">
            <Button
              text="Cancel"
              className="py-1.5 px-3 align-middle bg-menu text-white mr-2"
              onClick={onCancelForm}
            />
            <Button
              text={"Submit"}
              className="py-1.5 px-3 align-middle bg-primary-100 text-white mr-2"
              onClick={formik.handleSubmit}
            />
          </div>
        </div>
      </div>
      {isCustom && <MessageModal onClosemodal={() => setIsCustom(false)} />}
    </>
  );
};

export default AddUpdateForm;
