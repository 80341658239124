import * as React from "react";
import { TextField } from "@mui/material";

const CustomMuiTextInput  = ({ item, applyValue, value }) => {
  const [inputValue, setInputValue] = React.useState(item?.value || "");

  React.useEffect(() => {
    if (value !== undefined) {
      setInputValue(value);
    }
  }, [value]);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);

    // If value is not empty, apply it to the filter model
    if (newValue.trim() !== "") {
      applyValue({
        ...item,
        value: newValue,
      });
    } else {
      // Clear filter if input is empty
      applyValue({
        ...item,
        value: null,
      });
    }
  };

  return (
    <TextField
      label="Filter Value"
      variant="standard"
      fullWidth
      value={inputValue}
      onChange={handleInputChange}
      InputProps={{
        style: { fontSize: "0.8rem"},
      }}
      sx={{
        "& .MuiInputBase-input": {
          fontSize: "0.8rem",
          marginTop: "0.2625rem" 
        },
      }}
    />
  );
};

export default CustomMuiTextInput ;
