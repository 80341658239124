import React, { useEffect, useState } from "react";
import { DotsLoader, FormSelectInput } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { get_industry, industryReset } from "../../../features/IndustrySlice";
import { RxCross2 } from "react-icons/rx";
import {
  FaBusinessTime,
  FaCheck,
  FaChevronUp,
  FaClipboardCheck,
  FaPhoneAlt,
  FaUserFriends,
  FaSync,
} from "react-icons/fa";
import api from "../../../services/api";
import { get_users_drd } from "../../../features/usersSlice";
import {
  generateYearOptions,
  getFormattedDate,
  getFullName,
} from "../../../util/common";
import { useLocation } from "react-router-dom";
import { TablePagination, Button as MUIButton } from "@mui/material";
import CustomFilters from "../../../components/atoms/CustomFilters";
import { MdDateRange, MdLeaderboard } from "react-icons/md";
import SaveFilterModal from "./SaveFilterModal";
import FiltersDropdown from "./FiltersDropdown";
import _ from "lodash";
import { toast } from "react-toastify";
import Picker from "./Pickers";
import Breadcrumbs from "../../../components/molecules/Breadcrumbs";
import FormRadioGroup from "../../../components/molecules/FormRadioGroup";
import { getLeadComapigns } from "../../../services/leadCampaign";
import dayjs from "dayjs";
function LeadInboundReport() {
  const currentYear = new Date().getFullYear();
  const { usersDrd } = useSelector((state) => state.users);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const filterKey = params.get("filterKey"); // Get the unique key from URL
  const filterData = JSON.parse(sessionStorage.getItem(filterKey)) || null;

  const queryParams = new URLSearchParams(location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [record, setRecord] = useState([]);
  const [savedFilters, setSavedFilters] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [leadSource, setLeadSource] = useState([]);

  const [selectedYear, setSelectedYear] = useState(
    filterData && filterData?.selectedYear
      ? filterData?.selectedYear
      : currentYear
  );

  const [editingRecord, setEditingRecord] = useState({});
  const dispatch = useDispatch();
  const [isFilterSave, setIsFilterSave] = useState(false);
  const filteredSummaryData =
    filterData && filterData?.summaryFilter ? filterData?.summaryFilter : [];
  const [summaryFilter, setSummaryFilter] = useState(filteredSummaryData);
  const [queryOptions, setQueryOptions] = useState(
    filterData && filterData?.queryOptions
      ? filterData?.queryOptions
      : {
          groupOp: "AND",
          rules: [
            ...(queryParams.get("did_number")
              ? [
                  {
                    field: "call_payload_did",
                    op: "eq",
                    data: queryParams.get("did_number"),
                  },
                ]
              : []),
          ],
        }
  );
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [visibleRecords, setVisibleRecords] = useState(new Set([]));

  const handleWeekChange = (e) => {
    const weekNumber = parseInt(e);
    setSelectedWeek(weekNumber);
    const selectedWeekData = weeksOfYear.find(
      (week) => week.weekNumber === weekNumber
    );
    setCustomFilters({
      ...customFilters,
      start_date: selectedWeekData.startDate.toISOString().slice(0, 23) + "Z",
      end_date: selectedWeekData.endDate.toISOString().slice(0, 23) + "Z",
    });
    getReport({
      page: paginationModel?.page,
      size: paginationModel.pageSize,
      filters: queryOptions,
      start_date: selectedWeekData.startDate.toISOString().slice(0, 23) + "Z",
      end_date: selectedWeekData.endDate.toISOString().slice(0, 23) + "Z",
    });
    // GET DATA
  };
  const getFirstWeekMonday = (year) => {
    const jan1 = new Date(year, 0, 1);
    const dayOfWeek = jan1.getDay();
    const mondayOffset = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
    const firstMonday = new Date(year, 0, 1);
    firstMonday.setDate(1 + mondayOffset);
    return firstMonday;
  };

  const getDateRangeForWeek = (startDate) => {
    const weekStart = new Date(startDate);
    const weekEnd = new Date(startDate);
    weekEnd.setDate(weekStart.getDate() + 6);
    weekEnd.setHours(23, 59, 59, 999);
    return { startDate: weekStart, endDate: weekEnd };
  };

  // Function to format date range for display
  const formatDateRange = (startDate, endDate) => {
    return `${startDate.toDateString()} - ${endDate.toDateString()}`;
  };

  const generateWeeks = (year) => {
    const weeks = [];
    let weekNumber = 1;

    // Get the Monday of the week containing January 1st
    let currentDate = getFirstWeekMonday(year);

    // Get the last day of the year
    const lastDay = new Date(year, 11, 31);

    // Keep generating weeks until we pass December 31st
    while (currentDate <= lastDay) {
      const { startDate, endDate } = getDateRangeForWeek(currentDate);
      weeks.push({
        weekName: `Week ${weekNumber}: ${formatDateRange(startDate, endDate)}`,
        weekNumber: weekNumber,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      });

      // Move to next Monday
      currentDate.setDate(currentDate.getDate() + 7);
      weekNumber++;
    }

    return weeks;
  };
  const getCurrentWeekNumber = (currentDate, year) => {
    const firstMonday = getFirstWeekMonday(year);
    const diffInMs = currentDate - firstMonday;
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    return Math.floor(diffInDays / 7) + 1;
  };
  // Get the current year and week
  const currentDate = new Date();
  const currentWeek = getCurrentWeekNumber(currentDate, currentYear);
  const [selectedWeek, setSelectedWeek] = useState(
    filterData?.selectedWeek ? filterData?.selectedWeek : currentWeek || 1
  );
  const weeksOfYear = generateWeeks(selectedYear);
  const selectedWeekData = weeksOfYear.find(
    (week) => week.weekNumber === selectedWeek
  );
  const years = generateYearOptions(2024);
  const [customFilters, setCustomFilters] = useState(
    filterData && filterData?.customFilters
      ? filterData?.customFilters
      : {
          filter_by: "Week",
          report_by: "createdAt",
          start_date:
            selectedWeekData.startDate.toISOString().slice(0, 23) + "Z",
          end_date: selectedWeekData.endDate.toISOString().slice(0, 23) + "Z",
        }
  );

  const getReport = async (filter) => {
    setRecord([]);
    const {
      start_date = null,
      end_date = null,
      report_by = null,
    } = filter || {};
    const dateFilter = [
      {
        field: report_by || customFilters?.report_by,
        op: "gte",
        data: start_date || customFilters?.start_date,
      },
      {
        field: report_by || customFilters?.report_by,
        op: "lte",
        data: end_date || customFilters?.end_date,
      },
    ];
    setIsLoading(true);
    const combinedFilters = [
      ...(filter?.filters?.rules || []),
      ...dateFilter,
      ...(filter?.summaryFilter ? filter?.summaryFilter : filteredSummaryData),
    ];
    const payload = {
      ...filter,
      filters: {
        ...filter?.filters,
        rules: combinedFilters || [],
      },
    };
    if (!payload?.filters?.rules?.length) {
      delete payload.filters;
    }
    delete payload?.pageSize;
    delete payload?.start_date;
    delete payload?.end_date;
    delete payload?.summaryFilter;
    try {
      const res = await api.post("api/lead_source/inboundReport", payload);
      if (res.status === 200) {
        setRecord(res.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  const get_filters = async () => {
    try {
      const res = await api.get("api/report_filter_columns/INBOUND_REPORT");
      if (res.status === 200) {
        setSavedFilters(res?.data);
      }
    } catch (err) {
      console.log("👊 ~ constget_filters=async ~ err:", err);
    }
  };
  useEffect(() => {
    if (customFilters?.filter_by === "Week" && !filterData) {
      const currentWeek = getCurrentWeekNumber(currentDate, currentYear);
      setSelectedWeek(currentWeek || 1);
      const weeks = generateWeeks(selectedYear);
      const firstWeekData = weeks[currentWeek - 1];
      setCustomFilters({
        ...customFilters,
        start_date: firstWeekData.startDate.toISOString().slice(0, 23) + "Z",
        end_date: firstWeekData.endDate.toISOString().slice(0, 23) + "Z",
      });
      getReport({
        page: paginationModel?.page,
        size: paginationModel.pageSize,
        filters: queryOptions,
        start_date: firstWeekData.startDate.toISOString().slice(0, 23) + "Z",
        end_date: firstWeekData.endDate.toISOString().slice(0, 23) + "Z",
      });
    } else {
      getReport({
        page: paginationModel?.page,
        size: paginationModel.pageSize,
        filters: queryOptions,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, customFilters?.report_by, customFilters?.filter_by]);
  React.useEffect(() => {
    dispatch(get_users_drd());
    // getReport({
    //   filters: queryOptions,
    //   page: paginationModel?.page,
    //   size: paginationModel.pageSize,
    // });
    get_filters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const filterColumns = [
    {
      headerName: "Lead Number",
      field: "lead_num",
      type: "number",
    },
    {
      headerName: "Lead Source",
      field: "lead_source_meta.lead_source_id",
      type: "select",
      valueOptions: leadSource || [],
      apiEndpoint: "/api/lead_source",
      method: "get",
      label: "full_name",
      value: "_id",
      additionalOperators: [],
    },
    {
      headerName: "Campaign",
      field: "lead_source_meta.campaign_id",
      type: "select",
      valueOptions: campaigns || [],
      apiEndpoint: "/api/leadSourceCampaigns",
      method: "get",
      label: "title",
      value: "_id",
      additionalOperators: [],
    },
    {
      headerName: "Site Name",
      field: "call_payload_domain",
      type: "string",
    },
    {
      headerName: "Created By",
      field: "createdBy",
      type: "select",
      valueOptions: usersDrd || [],
      apiEndpoint: "/api/users",
      method: "get",
      label: "first_name",
      additionallabel: "last_name",
      value: "_id",
      additionalOperators: [],
    },
    {
      headerName: "Name",
      field: "name",
      type: "string",
    },
    {
      headerName: "DID",
      field: "call_payload_did",
      type: "string",
    },
    {
      headerName: "Phone",
      field: "phone",
      type: "string",
    },
    {
      headerName: "Job Amount",
      field: "service_cost",
      type: "string",
    },
    {
      headerName: "Customer City",
      field: "pickup_city",
      type: "string",
    },
    {
      headerName: "Customer State",
      field: "pickup_state",
      type: "string",
    },
    {
      headerName: "Customer Zip",
      field: "pickup_zip",
      type: "string",
    },
    // {
    //   headerName: "Created At",
    //   field: "createdAt",
    //   type: "date",
    // },
    {
      headerName: "Converted At",
      field: "convert_to_job_on",
      type: "date",
    },
    {
      headerName: "Job Status",
      field: "job_created",
      type: "select",
      valueOptions:
        [
          { value: true, label: "Created" },
          { value: false, label: "Not Created" },
        ] || [],
      label: "label",
      value: "value",
      additionalOperators: [],
    },
  ];
  const get_drd = async () => {
    try {
      const source = await api.post("api/lead_source");
      const campaign = await getLeadComapigns();
      if (source.status === 200) {
        setLeadSource(source?.data?.records);
      }
      if (campaign.status === 200) {
        setCampaigns(campaign.data);
      }
    } catch (err) {
      console.error("Error fetching lead sources:", err);
    } finally {
      setIsLoading(false);
    }
  };
  React.useEffect(() => {
    get_drd();
    dispatch(get_industry({ data: { page: 1, size: 1000 } }));
    return () => {
      dispatch(industryReset());
    };
    // eslint-disable-next-line
  }, []);

  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    getReport({
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
    });
  };

  const handleTopPageChange = (page) => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: +page + 1 });
    getReport({
      filters: queryOptions,
      page: +page + 1,
      size: paginationModel.pageSize,
    });
  };
  const handleTopPageSizeChange = (newPageSize) => {
    setPaginationModel({ page: 1, pageSize: newPageSize });
    getReport({
      filters: queryOptions,
      page: 1,
      size: newPageSize,
    });
  };
  const toggleListVisibility = (id) => {
    setVisibleRecords((prevVisibleRecords) => {
      const newVisibleRecords = new Set(prevVisibleRecords);
      if (newVisibleRecords.has(id)) {
        newVisibleRecords.delete(id);
      } else {
        newVisibleRecords.add(id);
      }
      return newVisibleRecords;
    });
  };

  const isOpen = (id) => visibleRecords.has(id);
  const renderDetailItem = (label, value) => (
    <p>
      <b className="!text-black text-xs">{label ?? ""}:</b>{" "}
      <span className="text-xs">{value ?? ""}</span>
    </p>
  );
  const renderHeaderItem = (label, value) => (
    <p className="flex flex-row items-center">
      <b className="text-black w-fit text-nowrap mr-1 text-sm">
        {label ?? ""}:
      </b>
      <span className="text-xs truncate">{value ?? ""}</span>
    </p>
  );
  const renderItemHeader = (label, Icon) => (
    <div className="font-bold border-b border-neutral-300 pb-1 flex items-center gap-x-2 mb-1 text-black">
      {Icon ? <Icon size={18} /> : null}
      {label ?? ""}
    </div>
  );
  // const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;

  const handleFilterChange = (data) => {
    let filters = { ...data };

    if (filters?.rules?.length) {
      filters.rules = filters.rules.map((rule) => {
        if (rule.data && (rule.data === "true" || rule.data === "false")) {
          return { ...rule, data: rule.data === "true" ? true : false };
        }
        return rule;
      });
    }
    setQueryOptions({ ...filters });
  };
  const isAllObjectMatched = () => {
    return savedFilters?.some((obj) => {
      if (obj?.data?.filters?.groupOp !== queryOptions?.groupOp) return false;
      return queryOptions?.rules?.every((rule) =>
        obj?.data?.filters?.rules?.some(
          (objRule) =>
            objRule?.field === rule?.field &&
            objRule?.op === rule?.op &&
            objRule?.data === rule?.data
        )
      );
    });
  };
  const findMatchingObject = () => {
    return _.find(savedFilters, (item) => {
      if (item.data.filters) {
        return _.isEqual(item.data.filters, queryOptions);
      } else {
        return _.isEqual(item.data, queryOptions);
      }
    });
  };

  // const areObjectsMatching = (obj1, obj2) => {
  //   return _.isEqual(obj1, obj2);
  // };
  const areObjectsMatching = (obj1 = [], obj2 = []) => {
    return obj1.every((item1) => obj2.some((item2) => _.isEqual(item1, item2)));
  };

  const sortUserFilters = (filters) => {
    if (!Array.isArray(filters)) {
      return [];
    }
    return filters.sort((a, b) => {
      const aStartsWithAsterisk = a.note?.startsWith("**");
      const bStartsWithAsterisk = b.note?.startsWith("**");

      if (aStartsWithAsterisk && !bStartsWithAsterisk) return -1;
      if (!aStartsWithAsterisk && bStartsWithAsterisk) return 1;
      return 0;
    });
  };
  const areObjectsEqual = (selectedFilter) => {
    return _.isEqual(selectedFilter, queryOptions);
  };
  const handleDeleteFilters = async (id) => {
    const c = window.confirm("Are you sure want to perform this action?");
    if (!c) return;

    setIsLoading(true);
    try {
      const res = await api.delete(`api/report_filter_columns/${id}`);
      if (res.status === 200) {
        toast.success(
          res?.data?.error || res?.data?.message || "Filter saved successfully"
        );
        await get_filters();
      } else {
        toast.error(
          res?.data?.error || res?.data?.message || "Filter couldn't saved"
        );
      }
      setIsLoading(false);
    } catch (err) {
      toast.error(
        err?.response?.data?.error ||
          err?.response?.data?.message ||
          "Filter couldn't saved"
      );
      console.log("🚀 ~ getLatestPosition ~ err:", err);
      setIsLoading(false);
    }
  };
  const getTotalJobCountByName = (name) => {
    const data = record?.systemJobStatusReport || [];
    const found = data?.find((item) => item.name === name);
    return found
      ? {
          totalJobCount: found.totalJobCount,
          totalCallCount: found.totalCallCount || 0,
          totalFormCount: found.totalFormCount || 0,
          _id: found?._id,
        }
      : { totalJobCount: 0, totalCallCount: 0, totalFormCount: 0, _id: null };
  };

  const handleClick = (filter) => {
    const isDuplicate = filteredSummaryData.some(
      (existingFilter) =>
        JSON.stringify(existingFilter) === JSON.stringify(filter)
    );

    if (!isDuplicate) {
      setSummaryFilter([...filteredSummaryData, ...filter]);
    }
    const combinedFilters = {
      customFilters,
      queryOptions,
      selectedWeek,
      selectedYear,
      summaryFilter: isDuplicate
        ? filteredSummaryData
        : [...filteredSummaryData, ...filter],
    };
    const uniqueKey = `filterData_${Date.now()}`; // Create a unique key
    sessionStorage.setItem(uniqueKey, JSON.stringify(combinedFilters));
    window.open(
      `/lead_source/inbound_reports?filterKey=${uniqueKey}`,
      "_blank"
    );
  };
  const summary = [
    {
      label: "Total Calls",
      value:
        (record?.metrics?.recordingsLengthLte30 || 0) +
        (record?.metrics?.recordingsLengthGt30Lte60 || 0) +
        (record?.metrics?.recordingsLengthGt60Lte90 || 0) +
        (record?.metrics?.recordingsLengthGt90Lte120 || 0) +
        (record?.metrics?.recordingsLengthGt120 || 0),
      isCallSummary: true,
      lessThan30: {
        count: record?.metrics?.recordingsLengthLte30 || 0,
        filter: [{ field: "recordings.0.length_in_sec", op: "lte", data: 30 }],
      },
      greaterThan30: {
        count: record?.metrics?.recordingsLengthGt30Lte60 || 0,
        filter: [
          { field: "recordings.0.length_in_sec", op: "gte", data: 31 },
          { field: "recordings.0.length_in_sec", op: "lte", data: 60 },
        ],
      },
      greaterThan60: {
        count: record?.metrics?.recordingsLengthGt60Lte90 || 0,
        filter: [
          { field: "recordings.0.length_in_sec", op: "gte", data: 61 },
          { field: "recordings.0.length_in_sec", op: "lte", data: 90 },
        ],
      },
      greaterThan90: {
        count: record?.metrics?.recordingsLengthGt90Lte120 || 0,
        filter: [
          { field: "recordings.0.length_in_sec", op: "gte", data: 91 },
          { field: "recordings.0.length_in_sec", op: "lte", data: 120 },
        ],
      },
      greaterThan120: {
        count: record?.metrics?.recordingsLengthGt120 || 0,
        filter: [{ field: "recordings.0.length_in_sec", op: "gt", data: 120 }],
      },
    },
    {
      label: "Total Leads",
      value: record?.metrics?.leadNumberCount || 0,
      callCount: record?.metrics?.leadCallCount || 0,
      formCount: record?.metrics?.leadFormCount || 0,
      callKey: [
        { field: "job_created", op: "eq", data: false },
        { field: "recordings.0.length_in_sec", op: "gte", data: 0 },
      ],
      formKey: [
        { field: "job_created", op: "eq", data: false },
        { field: "conversation_id", op: "not", data: null },
      ],
    },
    {
      label: "Total Jobs",
      value: record?.metrics?.jobNumberCount || 0,
      callCount: record?.metrics?.jobCallCount || 0,
      formCount: record?.metrics?.jobFormCount || 0,
      callKey: [
        { field: "job_created", op: "eq", data: true },
        { field: "recordings.0.length_in_sec", op: "gte", data: 0 },
      ],
      formKey: [
        { field: "job_created", op: "eq", data: true },
        { field: "conversation_id", op: "not", data: null },
      ],
    },

    {
      label: "Jobs Submitted",
      value: getTotalJobCountByName("Open Job")?.totalJobCount || 0,
      callCount: getTotalJobCountByName("Open Job")?.totalCallCount || 0,
      formCount: getTotalJobCountByName("Open Job")?.totalFormCount || 0,
      callKey: [
        {
          field: "system_job_status_id",
          op: "eq",
          data: getTotalJobCountByName("Open Job")?._id,
        },
        { field: "conversation_id", op: "eq", data: null },
      ],
      formKey: [
        {
          field: "system_job_status_id",
          op: "eq",
          data: getTotalJobCountByName("Open Job")?._id,
        },
        { field: "conversation_id", op: "not", data: null },
      ],
    },
    {
      label: "Jobs in Progress",
      value: getTotalJobCountByName("Job In Progress")?.totalJobCount || 0,
      callCount: getTotalJobCountByName("Job In Progress")?.totalCallCount || 0,
      formCount: getTotalJobCountByName("Job In Progress")?.totalFormCount || 0,
      callKey: [
        {
          field: "system_job_status_id",
          op: "eq",
          data: getTotalJobCountByName("Job In Progress")?._id,
        },
        { field: "conversation_id", op: "eq", data: null },
      ],
      formKey: [
        {
          field: "system_job_status_id",
          op: "eq",
          data: getTotalJobCountByName("Job In Progress")?._id,
        },
        { field: "conversation_id", op: "not", data: null },
      ],
    },

    {
      label: "Jobs Closed",
      value: getTotalJobCountByName("Closed")?.totalJobCount || 0,
      callCount: getTotalJobCountByName("Closed")?.totalCallCount || 0,
      formCount: getTotalJobCountByName("Closed")?.totalFormCount || 0,
      callKey: [
        {
          field: "system_job_status_id",
          op: "eq",
          data: getTotalJobCountByName("Closed")?._id,
        },
        { field: "conversation_id", op: "eq", data: null },
      ],
      formKey: [
        {
          field: "system_job_status_id",
          op: "eq",
          data: getTotalJobCountByName("Closed")?._id,
        },
        { field: "conversation_id", op: "not", data: null },
      ],
    },

    {
      label: "Closed Amount",
      value: `$${record?.metrics?.serviceCostSum || 0}`,
      callCount: `$${record?.metrics?.serviceCostCall || 0}`,
      formCount: `$${record?.metrics?.serviceCostForm || 0}`,
      callKey: [
        {
          field: "is_closed",
          op: "eq",
          data: true,
        },
        {
          field: "service_cost",
          op: "gt",
          data: 0,
        },
        { field: "recordings.0.length_in_sec", op: "gte", data: 0 },
      ],
      formKey: [
        {
          field: "is_closed",
          op: "eq",
          data: true,
        },
        {
          field: "service_cost",
          op: "gt",
          data: 0,
        },
        { field: "conversation_id", op: "not", data: null },
      ],
    },
  ];
  const handleClearFilter = () => {
    setSummaryFilter([]);
    getReport({
      filters: queryOptions,
      page: 1,
      size: paginationModel.pageSize,
      summaryFilter: [],
    });
  };
  const isWeek = customFilters?.filter_by === "Week";
  const [currentlyPlayingAudio, setCurrentlyPlayingAudio] =
    React.useState(null);
  const handlePlay = async (lead_num) => {
    const audioElement = document.getElementById(lead_num);
    if (audioElement) {
      if (currentlyPlayingAudio && currentlyPlayingAudio !== audioElement) {
        currentlyPlayingAudio.pause();
      }
      audioElement.play();
      setCurrentlyPlayingAudio(audioElement);
    }
  };

  const getSyncCallRecordings = async () => {
    const c = window.confirm(`Are you sure want to sync call recordings?.`);
    if (!c) return;
    try {
      const res = await api.post("/api/lead_source/syncCallRecordings");
      if (res?.status === 200 || res?.status === 201) {
        toast.success(`Data syncing has started successfully.`);
        getReport({
          page: paginationModel?.page,
          size: paginationModel.pageSize,
          filters: queryOptions,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div>
        <Breadcrumbs />
        <div className="flex gap-x-2 flex-col">
          <div className="flex w-full justify-between flex-wrap gap-y-3">
            <div className="relative">
              <FormRadioGroup
                name="filter_by"
                // label="Filter By"
                labelProp="label"
                valueProp="value"
                options={[
                  { value: "Week", label: "Week" },
                  { value: "Date", label: "Date" },
                ]}
                isOnChange={(e) => {
                  setCustomFilters({ ...customFilters, filter_by: e });
                }}
                isCheck={customFilters.filter_by}
                type="text"
                isHorizontal
                isBorder
                isFloat
                className="!h-[36px]"
              />
            </div>
            {isWeek ? (
              <div className="flex gap-x-2 w-auto">
                <div className="">
                  <FormSelectInput
                    name="report_by"
                    label="Report By"
                    value={customFilters.report_by}
                    options={[
                      { label: "Closed", value: "closed_date" },
                      { label: "Created", value: "createdAt" },
                    ]}
                    onChange={(value) =>
                      setCustomFilters({ ...customFilters, report_by: value })
                    }
                    valueProp="value"
                    labelProp="label"
                  />
                </div>
                <div className="">
                  <FormSelectInput
                    name="selectedYear"
                    label="Select a year"
                    value={selectedYear}
                    options={years}
                    onChange={(value) => setSelectedYear(value)}
                    valueProp="value"
                    labelProp="label"
                  />
                </div>
                <div className="w-full">
                  <FormSelectInput
                    name="industry_id"
                    label="Select a week"
                    value={selectedWeek}
                    options={weeksOfYear}
                    onChange={handleWeekChange}
                    valueProp="weekNumber"
                    labelProp="weekName"
                  />
                </div>
              </div>
            ) : null}
          </div>
          <div className="flex w-full mt-2 gap-x-2">
            <div className="flex w-full justify-between">
              <div className="w-full">
                <div
                  className={`flex grid ${
                    isWeek
                      ? "grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-6 2xl:grid-cols-8"
                      : "grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-7"
                  } justify-betwee gap-2 w-full`}
                >
                  {summary?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={`${
                          item?.isCallSummary ? "col-span-2" : ""
                        } flex flex-col text-ml-3 hover:no-underline hover:text-black text-nowrap shadow-report rounded-[1px] bg-white transition-all`}
                      >
                        <div className="flex justify-between items-center bg-primary-100 p-1 w-full text-sm px-2 space-x-1">
                          <div className="flex flex-1 items-center justify-center space-x-1">
                            <span>{item?.label}</span>
                            <span className="font-semibold">
                              ({item?.value})
                            </span>
                          </div>
                          {/* {areObjectsMatching(
                            item?.callKey,
                            filteredSummaryData
                          ) ||
                          areObjectsMatching(
                            item?.formKey,
                            filteredSummaryData
                          ) ||
                          areObjectsMatching(
                            item?.lessThan30?.filter,
                            filteredSummaryData
                          ) ||
                          areObjectsMatching(
                            item?.greaterThan30?.filter,
                            filteredSummaryData
                          ) ||
                          areObjectsMatching(
                            item?.greaterThan60?.filter,
                            filteredSummaryData
                          ) ||
                          areObjectsMatching(
                            item?.greaterThan90?.filter,
                            filteredSummaryData
                          ) ||
                          areObjectsMatching(
                            item?.greaterThan120?.filter,
                            filteredSummaryData
                          ) ? (
                            <span
                              className="cursor-pointer w-5 mr-2 mt-[1px] ml-auto"
                              onClick={handleClearFilter}
                            >
                              <RxCross2 className="w-4 h-4" />
                            </span>
                          ) : null} */}
                        </div>

                        {item.isCallSummary ? (
                          <div className="flex flex-col">
                            {/* Duration Categories */}
                            <div className="grid grid-cols-5 p-1 gap-1">
                              {/* 0-30s */}
                              <button
                                onClick={() =>
                                  handleClick(item?.lessThan30?.filter)
                                }
                                className={`flex flex-col items-center px-2 py-1 rounded-lg transition-all ${
                                  areObjectsMatching(
                                    item?.lessThan30?.filter,
                                    filteredSummaryData
                                  )
                                    ? "bg-primary-100/10 ring-2 ring-primary-100"
                                    : "hover:bg-gray-50"
                                }`}
                              >
                                <span className="text-lg font-semibold">
                                  {item?.lessThan30?.count}
                                </span>
                                <div className="flex items-center gap-1 text-xs text-gray-600">
                                  <div className="w-2 h-2 rounded-full bg-red-500" />
                                  <span>0-30s</span>
                                </div>
                              </button>

                              {/* 31-60s */}
                              <button
                                onClick={() =>
                                  handleClick(item?.greaterThan30?.filter)
                                }
                                className={`flex flex-col items-center px-2 py-1 rounded-lg transition-all ${
                                  areObjectsMatching(
                                    item?.greaterThan30?.filter,
                                    filteredSummaryData
                                  )
                                    ? "bg-primary-100/10 ring-2 ring-primary-100"
                                    : "hover:bg-gray-50"
                                }`}
                              >
                                <span className="text-lg font-semibold">
                                  {item?.greaterThan30?.count}
                                </span>
                                <div className="flex items-center gap-1 text-xs text-gray-600">
                                  <div className="w-2 h-2 rounded-full bg-yellow-500" />
                                  <span>31-60s</span>
                                </div>
                              </button>

                              {/* 61-90s */}
                              <button
                                onClick={() =>
                                  handleClick(item?.greaterThan60?.filter)
                                }
                                className={`flex flex-col items-center px-2 py-1 rounded-lg transition-all ${
                                  areObjectsMatching(
                                    item?.greaterThan60?.filter,
                                    filteredSummaryData
                                  )
                                    ? "bg-primary-100/10 ring-2 ring-primary-100"
                                    : "hover:bg-gray-50"
                                }`}
                              >
                                <span className="text-lg font-semibold">
                                  {item?.greaterThan60?.count}
                                </span>
                                <div className="flex items-center gap-1 text-xs text-gray-600">
                                  <div className="w-2 h-2 rounded-full bg-green-500" />
                                  <span>61-90s</span>
                                </div>
                              </button>

                              {/* 91-120s */}
                              <button
                                onClick={() =>
                                  handleClick(item?.greaterThan90?.filter)
                                }
                                className={`flex flex-col items-center px-2 py-1 rounded-lg transition-all ${
                                  areObjectsMatching(
                                    item?.greaterThan90?.filter,
                                    filteredSummaryData
                                  )
                                    ? "bg-primary-100/10 ring-2 ring-primary-100"
                                    : "hover:bg-gray-50"
                                }`}
                              >
                                <span className="text-lg font-semibold">
                                  {item?.greaterThan90?.count}
                                </span>
                                <div className="flex items-center gap-1 text-xs text-gray-600">
                                  <div className="w-2 h-2 rounded-full bg-blue-500" />
                                  <span>91-120s</span>
                                </div>
                              </button>

                              {/* 120s+ */}
                              <button
                                onClick={() =>
                                  handleClick(item?.greaterThan120?.filter)
                                }
                                className={`flex flex-col items-center px-2 py-1 rounded-lg transition-all ${
                                  areObjectsMatching(
                                    item?.greaterThan120?.filter,
                                    filteredSummaryData
                                  )
                                    ? "bg-primary-100/10 ring-2 ring-primary-100"
                                    : "hover:bg-gray-50"
                                }`}
                              >
                                <span className="text-lg font-semibold">
                                  {item?.greaterThan120?.count}
                                </span>
                                <div className="flex items-center gap-1 text-xs text-gray-600">
                                  <div className="w-2 h-2 rounded-full bg-purple-500" />
                                  <span>120s+</span>
                                </div>
                              </button>
                            </div>
                          </div>
                        ) : (
                          <div className="flex justify-center items-center space-x-2 bg-white w-full h-full min-h-[50px] text-[13px] ">
                            <button
                              type="button"
                              onClick={() => handleClick(item?.callKey)}
                              className={`px-2 !py-3 rounded-md ${
                                areObjectsMatching(
                                  item?.callKey,
                                  filteredSummaryData
                                )
                                  ? // ? "text-menu text-base font-semibold border-b border-b-2 border-b-primary-100"
                                    "bg-primary-100/10 ring-2 ring-primary-100"
                                  : "text-black"
                              }`}
                            >
                              <span
                                className={`${
                                  areObjectsMatching(
                                    item?.callKey,
                                    filteredSummaryData
                                  )
                                    ? "text-menu"
                                    : "text-gray-500"
                                }`}
                              >
                                Calls:
                              </span>
                              <span className={`ml-1 font-semibold`}>
                                {item?.callCount}
                              </span>
                            </button>
                            <span className="text-gray-500">|</span>
                            <button
                              type="button"
                              onClick={() => handleClick(item?.formKey)}
                              className={`px-2 py-1 rounded-md ${
                                areObjectsMatching(
                                  item?.formKey,
                                  filteredSummaryData
                                )
                                  ? "text-menu text-base font-semibold border-b border-b-2 border-b-primary-100"
                                  : "text-black"
                              }`}
                            >
                              <span
                                className={`${
                                  areObjectsMatching(
                                    item?.formKey,
                                    filteredSummaryData
                                  )
                                    ? "text-menu"
                                    : "text-gray-500"
                                }`}
                              >
                                Forms:
                              </span>
                              <span className={`ml-1 font-semibold`}>
                                {item?.formCount}
                              </span>
                            </button>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            {!isWeek ? (
              <div className="w-[380px]">
                <Picker
                  filterData={customFilters}
                  setFiltersData={setCustomFilters}
                  dropdown_menu={[
                    { label: "Created", value: "createdAt" },
                    { label: "Closed", value: "closed_date" },
                  ]}
                  dropdown_label="Report by"
                  valueKey={"report_by"}
                  toKey="end_date"
                  fromKey="start_date"
                  seachButton={true}
                  seachButtonOnClick={() =>
                    getReport({
                      filters: queryOptions,
                      page: 1,
                      size: paginationModel.pageSize,
                    })
                  }
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {isLoading ? <DotsLoader /> : null}
      {isFilterSave ? (
        <SaveFilterModal
          onClose={() => {
            setIsFilterSave(false);
          }}
          filters={queryOptions}
          editingRecord={editingRecord}
          setFilters={(data) => {
            setSavedFilters((prevState) => {
              const index = prevState.findIndex(
                (item) => item._id === data._id
              );
              if (index !== -1) {
                const updatedFilters = [...prevState];
                updatedFilters[index] = data;
                return updatedFilters;
              } else {
                return [...prevState, data];
              }
            });
          }}
        />
      ) : null}
      <div className="bg-white my-3 px-2 border rounded">
        <div className={`flex items-center w-full justify-between`}>
          <div className="flex gap-x-2">
            <div className="position-relative z-[999]">
              <CustomFilters
                handleFilterChange={handleFilterChange}
                handleNewFilter={handleNewFilter}
                filterModel={queryOptions}
                columns={filterColumns}
                onRemove={() =>
                  getReport({
                    page: 1,
                    size: paginationModel.pageSize,
                  })
                }
              />
            </div>
            <FiltersDropdown
              options={sortUserFilters(savedFilters)}
              value={findMatchingObject()}
              areObjectsEqual={(data) => areObjectsEqual(data)}
              handleDeleteFilters={(id) => handleDeleteFilters(id)}
              onEdit={(data) => {
                setEditingRecord(data);
                setIsFilterSave(true);
              }}
              onRemove={() => {
                setQueryOptions({
                  groupOp: "AND",
                  rules: [],
                });
                setPaginationModel({
                  pageSize: paginationModel.pageSize,
                  page: 1,
                });
                getReport({
                  page: 1,
                  size: paginationModel.pageSize,
                });
              }}
              onChange={(data) => {
                const filters = data?.groupOp
                  ? data
                  : data?.filters
                  ? data.filters
                  : { groupOp: "AND", rules: [] };
                setQueryOptions(filters);
                setPaginationModel({
                  pageSize: paginationModel.pageSize,
                  page: 1,
                });
                getReport({
                  filters: filters,
                  page: 1,
                  size: paginationModel.pageSize,
                });
              }}
            />
            <div>
              {queryOptions?.rules?.length && !isAllObjectMatched() ? (
                <div className="flex flex-row space-x-1">
                  <button
                    className="bg-primary-100 text-white w-[25px] h-[25px] rounded-full flex justify-center items-center"
                    onClick={() => setIsFilterSave(true)}
                  >
                    <FaCheck />
                  </button>
                </div>
              ) : null}
            </div>
          </div>

          <div className="flex items-center gap-x-2 justify-start flex-shrink-0">
            <MUIButton
              variant="text"
              onClick={getSyncCallRecordings}
              sx={{ fontSize: "0.75rem" }}
              className="!text-[#042a42] text-sm"
              title="Sync Call Recordings"
            >
              Sync Call Recordings
              <FaSync className="ml-1 w-5" />
            </MUIButton>
            <p className="text-orange-600 font-semibold text-sm">
              {record?.lastSync
                ? `Last synced on ${dayjs(record.lastSync).format(
                    "MMM, D YYYY h:mm A"
                  )}`
                : ""}
            </p>
          </div>
          {record?.totalItems ? (
            <div>
              <TablePagination
                color="primary"
                shape="rounded"
                size="medium"
                showFirstButton
                showLastButton
                count={
                  record?.totalItems
                    ? record?.totalItems
                    : record?.data?.length || 0
                }
                page={paginationModel.page - 1 || 0}
                onPageChange={(e, page) => handleTopPageChange(page)}
                rowsPerPage={paginationModel.pageSize || 0}
                onRowsPerPageChange={(e) =>
                  handleTopPageSizeChange(e.target.value)
                }
                component="div"
                rowsPerPageOptions={[
                  2,
                  25,
                  50,
                  75,
                  100,
                  250,
                  500,
                  1000,
                  1500,
                  2000,
                  record?.totalItems
                    ? record?.totalItems
                    : record?.data?.length || 0,
                ]}
              />
            </div>
          ) : null}
        </div>
        {(record?.data || [])?.map((record, index) => {
          const {
            lead_num,
            job_number,
            createdBy,
            name,
            phone,
            createdAt,
            convert_to_job_on,
            call_payload_did,
            pickup_city,
            pickup_state,
            pickup_zip,
            job_status_id,
            system_job_status_id,
            closed_at_date,
            closed_date,
            call_payload_domain,
            service_cost,
            vendor_id,
            conversation_id = null,
            lead_source_meta = {},
            recordings = [],
          } = record;
          const audioSrc =
            recordings.length > 0 ? recordings[0]?.location : null;
          const { campaign_id, lead_source_id } = lead_source_meta;
          return (
            <div
              className={` bg-white dark:bg-gray-800 last:border-none dark:border-white/20 !py-0 mb-2 shadow-sm border border-neutral-200 mt-2 `}
            >
              <div
                className={` px-6 !py-1.5 flex bg-neutral-100 border-b border-neutral-200 items-center justify-between flex-wrap gap-2`}
                onClick={() => toggleListVisibility(index)}
              >
                <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-x-4 flex w-[calc(100%-50px)]">
                  {renderHeaderItem("Date", getFormattedDate(createdAt))}
                  {renderHeaderItem(
                    "Lead Type",
                    conversation_id ? "Form" : "Call"
                  )}
                  {renderHeaderItem(
                    "Lead Source",
                    lead_source_id?.company_name || ""
                  )}
                  {renderHeaderItem("Vendor", getFullName(vendor_id))}

                  <div className="flex items-center col-span-">
                    {/* <div className="text-[10px]">Recordings</div> */}
                    {audioSrc ? (
                      <audio
                        controls
                        className="h-10 relative border border-neutral-300 rounded-full"
                        onPlay={() => handlePlay(record?._id)}
                        id={record?._id}
                      >
                        <source src={audioSrc} type="audio/mpeg" />
                      </audio>
                    ) : (
                      <span className="text-gray-500 text-[10px]">
                        No Recording Available
                      </span>
                    )}
                  </div>
                  {renderHeaderItem("Customer", name)}
                  {/* {renderHeaderItem("Lead No", lead_num)} */}
                  {renderDetailItem(
                    "Job Status",
                    `${system_job_status_id?.name || ""} ${
                      system_job_status_id && job_status_id ? "/" : ""
                    } ${job_status_id?.name || ""}`
                  )}
                  {renderHeaderItem("Campaign", campaign_id?.title || "")}
                  {renderDetailItem("Amount", service_cost)}
                </div>
                <div>
                  <div className="flex items-center justify-end">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleListVisibility(index);
                      }}
                    >
                      <FaChevronUp
                        className={`w-5 transition-transform transform text-gray-400 ${
                          isOpen(index) && "rotate-180"
                        }`}
                      />
                    </button>
                  </div>
                </div>
              </div>

              <div
                className={`px-6 overflow-hidden transition-max-height duration-500 ease-in-out ${
                  isOpen(index) ? "max-h-screen" : "max-h-0"
                }`}
              >
                <div className="text-sm grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 xl:grid-cols-6 gap-x-4 gap-y-2 border- dark:border-white/20 pt-1 mt-1 pb-2">
                  <div>
                    {renderItemHeader("Customer", FaUserFriends)}
                    {renderDetailItem("Name", name)}
                    {renderDetailItem("Phone", phone)}
                    {renderDetailItem(
                      "Location",
                      `${pickup_city || ""}${
                        pickup_city && pickup_state ? "," : ""
                      } ${pickup_state || ""} ${pickup_zip || ""}`
                    )}
                  </div>
                  <div>
                    {renderItemHeader("Campaign", FaPhoneAlt)}
                    {renderDetailItem("Campaign", call_payload_domain)}
                    {renderDetailItem("Inbound DID", call_payload_did)}
                    {renderDetailItem("Site Name", call_payload_domain)}
                  </div>
                  <div>
                    {renderItemHeader("Dates", MdDateRange)}
                    {renderDetailItem("Created", getFormattedDate(createdAt))}
                    {renderDetailItem(
                      "Converted",
                      getFormattedDate(convert_to_job_on)
                    )}
                    {renderDetailItem(
                      "Closed",
                      getFormattedDate(closed_at_date) ||
                        getFormattedDate(closed_date)
                    )}
                  </div>
                  <div>
                    {renderItemHeader("Lead Info", MdLeaderboard)}

                    {renderDetailItem("Created By", getFullName(createdBy))}
                    {renderDetailItem("Lead Created", lead_num)}
                  </div>
                  <div>
                    {renderItemHeader("Job Info", FaBusinessTime)}
                    {renderDetailItem("Created By", getFullName(createdBy))}
                    {renderDetailItem("Job Created", job_number)}
                    {renderDetailItem(
                      "Job Status",
                      `${system_job_status_id?.name || ""} ${
                        system_job_status_id && job_status_id ? "/" : ""
                      } ${job_status_id?.name || ""}`
                    )}
                  </div>
                  <div>
                    {renderItemHeader("Closed Info", FaClipboardCheck)}
                    {renderDetailItem("Closed Amount", service_cost)}
                    {renderDetailItem("Closed By", getFullName(createdBy))}
                    {renderDetailItem("Vendor", getFullName(vendor_id))}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default LeadInboundReport;
