import React from "react";
import { FormTextArea } from "../../../components";

function AdditionalNotes({ formik }) {
  return (
    <>
      <div className="mt-3 border border-[#F0F0F0] bg-[#FAFAFA] p-3">
        <label className="pb-2 text-[15px]">
          Additional Notes(any specific details for the tech):
        </label>
        <FormTextArea
          rows={5}
          name="extra_fields.notes"
          formik={formik}
          type="text"
        />
      </div>
    </>
  );
}

export default AdditionalNotes;
