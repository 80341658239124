import * as React from "react";
import { Select, MenuItem, InputLabel, FormControl } from "@mui/material";

const CustomMuiSelectInput = ({ item, applyValue, value, options }) => {
  const [selectValue, setSelectValue] = React.useState("");

  React.useEffect(() => {
    if (item?.value) {
      setSelectValue(item?.value);
    }
  }, [item?.value]);

  const handleSelectChange = (event) => {
    const newValue = event.target.value;
    setSelectValue(newValue);

    // If value is selected, apply it to the filter model
    if (newValue !== "") {
      applyValue({
        ...item,
        value: newValue,
      });
    } else {
      // Clear filter if no selection
      applyValue({
        ...item,
        value: null,
      });
    }
  };

  return (
    <FormControl fullWidth variant="standard" sx={{ marginTop: "0.2625rem" }}>
      <InputLabel>Filter Value</InputLabel>
      <Select
        value={selectValue}
        onChange={handleSelectChange}
        label="Filter Value"
        sx={{
          fontSize: "0.8rem",
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={index} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomMuiSelectInput;
