import React, { useState, useRef } from "react";
import { Dropdown, OverlayTrigger, Popover } from "react-bootstrap";
import {
  FaCheckCircle,
  FaCreditCard,
  FaCross,
  FaEdit,
  FaTimes,
  FaTrash,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { toast } from "react-toastify";
import {
  Button,
  DotsLoader,
  FormInput,
  FormSelectInput,
  Modal,
} from "../../../../components";
import {
  add_note,
  get_transactions,
  job_payment_card,
  job_payment_charged,
  job_payment_refund,
  updateNotes,
} from "../../../../features/jobsSlice";
import errorMessage from "../../../../util/errorMessage";
import api from "../../../../services/api";
function PaymentOption(props) {
  const { formik } = props;
  const paymentRef = useRef(null);
  const refundRef = useRef(null);
  const voidRef = useRef(null);
  const dispatch = useDispatch();
  const paymentGateway = useSelector((state) => state.paymentGateway);
  const cardTypes = useSelector((state) => state.cardTypes);
  const months = useSelector((state) => state.months);
  const { jobNotes, transactions } = useSelector((state) => state.jobs);
  const [errors, setErrors] = React.useState([]);
  const [showOverlay, setShowOverlay] = useState(null);
  const [showRefundOverlay, setRefundShowOverlay] = useState(null);
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [showRefund, setShowRefund] = useState(false);
  const [showCaptureVoid, setShowCaptureVoid] = useState(false);
  const [amount, setAmount] = useState();
  const [isLoader, setIsLoader] = useState(false);

  const [isNotes, setIsNotes] = useState(false);
  const params = useParams();
  const { id } = params;
  const handleClickOutside = (event) => {
    if (paymentRef.current && !paymentRef.current.contains(event.target)) {
      setShowPaymentOptions(false);
    }
    if (refundRef.current && !refundRef.current.contains(event.target)) {
      setShowRefund(false);
    }
    if (voidRef.current && !voidRef.current.contains(event.target)) {
      setShowCaptureVoid(false);
    }
  };
  const handleHideDropdown = (event) => {
    if (event.key === "Escape") {
      setShowPaymentOptions(false);
    }
    if (event.key === "Escape") {
      setShowRefund(false);
    }
    if (event.key === "Escape") {
      setShowCaptureVoid(false);
    }
  };

  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);

  const handlePayment = async () => {
    try {
      const payload = {
        id: id,
        card_type_id: formik.values?.card_type_id,
        card_month_id: formik.values?.card_month_id,
        card_number: formik.values?.card_number,
        card_year: formik.values?.card_year,
        card_CVV: formik.values?.card_CVV,
        card_holder_name: formik.values?.card_holder_name,
        card_zip: formik.values?.card_zip,
        card_city: formik.values?.card_city,
        card_state: formik.values?.card_state,
        zelle_auth_number: formik.values?.zelle_auth_number,
        venmo: formik.values?.venmo,
        cash_app: formik.values?.cash_app,
        vendor_pay: formik.values?.vendor_pay,
      };
      const res = await dispatch(job_payment_card(payload));
      if (res?.payload?.status === 200) {
        toast.success("Payment Card Successfully Added");
      } else {
        errorMessage({
          payload: res?.payload,
          action: "Payment Card",
          msg: "added",
        });
      }
    } catch (err) {
      errorMessage({ payload: err, action: "Payment Card", msg: "added" });
    }
  };
  const handleCharge = async (val) => {
    try {
      const res = await dispatch(job_payment_charged({ ...val, job_id: id }));
      if (res?.payload?.status === 200) {
        toast.success(res.payload.data.message);
        setShowOverlay(null);
        dispatch(get_transactions({ id: id, type: "Payment" }));
        if (!val.auth_only) {
          formik.setFieldValue(
            "amount_paid",
            +formik.values.amount_paid + +val.amount
          );
        }
      } else {
        errorMessage({
          payload: res.payload,
          setErrors: setErrors,
          action: "Patment",
          msg: "charged",
        });
      }
    } catch (err) {
      errorMessage({
        payload: err,
        setErrors: setErrors,
        action: "Payment",
        msg: "charged",
      });
    }
  };
  const handleRefund = async (val) => {
    try {
      const res = await dispatch(job_payment_refund({ ...val, job_id: id }));
      if (
        res?.payload?.status === 200 &&
        res.payload.data.jobRefund.transaction_status === "Success"
      ) {
        toast.success(res.payload.data.message);
        setRefundShowOverlay(null);
        dispatch(get_transactions({ id: id, type: "Payment" }));
        formik.setFieldValue(
          "amount_paid",
          +formik.values.amount_paid - +val.amount
        );
      } else {
        errorMessage({
          payload: res.payload?.data?.message || res.payload,
          setErrors: setErrors,
          action: "Payment",
          msg: "refund",
        });
      }
    } catch (err) {
      errorMessage({
        payload: err,
        setErrors: setErrors,
        action: "Patment",
        msg: "refund",
      });
      console.error(err);
    }
  };
  const handleCaptureVoid = async ({ job_payment_id, amount, type }) => {
    const c = window.confirm(
      `Are you sure want to ${type} the  payment of $${amount}?`
    );
    if (!c) return;
    try {
      setIsLoader(true);
      const res = await api.post(
        `/api/jobs/${id}/captureVoid/${job_payment_id}`,
        {
          amount,
          type,
        }
      );
      if (res?.status === 200) {
        toast.success(res.data.message);
        dispatch(updateNotes(res.data.job_note));
        dispatch(get_transactions({ id: id, type: "Payment" }));
        if (type === "capture") {
          formik.setFieldValue(
            "amount_paid",
            +formik.values.amount_paid + +amount
          );
        }
        setShowCaptureVoid(false);
      } else {
        errorMessage({
          payload: res.data.message,
          setErrors: setErrors,
          action: "Payment",
          msg: type,
        });
      }
    } catch (err) {
      errorMessage({
        payload: err?.response?.data.message,
        setErrors: setErrors,
        action: "Patment",
        msg: type,
      });
      console.error(err);
    }
    setIsLoader(false);
  };
  const handleAddNote = async () => {
    try {
      const res = await dispatch(
        add_note({ id: formik.values.job_number, note: formik.values.note })
      );
      if (res?.payload?.status === 200) {
        toast.success("Note Add Successfully");
        formik.setFieldValue("note", "");
        setIsNotes(false);
      } else {
        errorMessage({
          payload: res.payload,
          setErrors: setErrors,
          action: "Note",
          msg: "created",
        });
      }
    } catch (err) {
      errorMessage({
        payload: err,
        setErrors: setErrors,
        action: "Note",
        msg: "created",
      });
    }
  };
  return (
    <div className="mb-3 flex-[0_0_100%]">
      {isLoader ? <DotsLoader /> : null}
      {isNotes && (
        <Modal
          editingRecord={0}
          title="Add Note"
          onCancelModal={() => setIsNotes(false)}
          onSubmit={handleAddNote}
        >
          <FormInput
            errors={errors}
            formik={formik}
            name="note"
            label="Note"
            type="text"
          />
        </Modal>
      )}
      <div className="bg-white md:border md:px-4 m-0 pb-2 rounded !mt-3">
        <div className="flex flex-col md:flex-row items-center gap-3">
          <div className="md:col-2 pl-2">
            <h5 className="">Payment Options</h5>
          </div>
          <div className="!mt-3 relative w-full md:w-auto">
            <Dropdown show={showPaymentOptions}>
              <Dropdown.Toggle
                onClick={() => {
                  setAmount(
                    formik.values.service_cost - formik.values.amount_paid
                  );
                  setShowPaymentOptions(!showPaymentOptions);
                }}
                id="dropdown-basic"
                className="rounded-0 w-full md:w-auto md:min-w-[185px] py-1.5 px-3 align-middle !border-primary-100 !bg-primary-100 !mb-2 focus:!shadow-none"
              >
                Charge Card Via
              </Dropdown.Toggle>

              <Dropdown.Menu
                className="w-auto min-w-[185px] flex flex-row "
                ref={paymentRef}
              >
                {paymentGateway?.drd?.map((val, index) => {
                  return (
                    <OverlayTrigger
                      key={index}
                      trigger={["click", "focus"]}
                      placement="right"
                      rootClose
                      show={showOverlay === index}
                      onEnter={() => {
                        setAmount(
                          formik.values.service_cost - formik.values.amount_paid
                        );
                      }}
                      overlay={
                        <Popover
                          id="popover-basic"
                          className="w-auto min-w-[300px] relative"
                        >
                          <button
                            type="button"
                            className="absolute flex right-0"
                            onClick={() => setShowOverlay(null)}
                          >
                            <FaTimes />
                          </button>

                          <Popover.Title
                            as="h3"
                            className="text-lg border-b-2 border-neutral-100 pb-2 font-pop font-medium"
                          >
                            Charge From {val.title}
                          </Popover.Title>
                          <Popover.Content>
                            <div className="!px-2">
                              <input
                                className="form-control"
                                placeholder="Amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                              />
                              <div
                                className={`flex ${
                                  val.has_auth_feature
                                    ? "justify-between"
                                    : "justify-end"
                                } w-full`}
                              >
                                {val.has_auth_feature ? (
                                  <Button
                                    text="Click to Auth"
                                    className="mt-1 focus:bg-primary"
                                    color="#fff"
                                    onClick={() => {
                                      handleCharge({
                                        payment_gateway_id: val._id,
                                        amount: amount,
                                        auth_only: true,
                                      });
                                    }}
                                    variant="btn_cancel"
                                  />
                                ) : null}
                                <Button
                                  text="Click to Charge"
                                  className="mt-1 focus:bg-primary"
                                  color="#fff"
                                  onClick={() => {
                                    handleCharge({
                                      payment_gateway_id: val._id,
                                      amount: amount,
                                      auth_only: false,
                                    });
                                  }}
                                  variant="btn_submit"
                                />
                              </div>
                            </div>
                          </Popover.Content>
                        </Popover>
                      }
                    >
                      <Dropdown.ItemText
                        onClick={() => setShowOverlay(index)}
                        className="cursor-pointer mt-1 mx-1.5 flex flex-col hover:bg-[#182237] hover:text-white "
                      >
                        <div className="flex flex-row items-center pl-1">
                          <FaCreditCard />
                          <span className="ml-2">{val.title}</span>
                        </div>
                      </Dropdown.ItemText>
                    </OverlayTrigger>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {transactions?.filter(({ payment_type }) => payment_type !== "Auth")
            ?.length > 0 && (
            <div className="!mt-3 relative">
              <Dropdown show={showRefund}>
                <Dropdown.Toggle
                  onClick={() => setShowRefund(!showRefund)}
                  id="dropdown-basic"
                  className="rounded-0 w-auto min-w-[185px] py-1.5 px-3 !border-primary-100 !bg-primary-100 !mb-2 focus:!shadow-none"
                >
                  Refund
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className="w-auto min-w-[185px] flex flex-row "
                  ref={refundRef}
                >
                  {transactions?.map((val, index) => {
                    return val?.payment_type !== "Auth" ? (
                      <>
                        {val.transaction_status === "Success" &&
                          val.balance > 0 && (
                            <Dropdown.ItemText
                              className=" mt-1 mx-1.5 flex flex-col"
                              key={index}
                            >
                              <div className="flex flex-row items-center justify-between pl-1">
                                <div className="flex items-center">
                                  <FaCreditCard />
                                  <span className="ml-2">
                                    Payment ${val.balance}
                                  </span>
                                </div>
                                <OverlayTrigger
                                  trigger={["click", "focus"]}
                                  placement="right"
                                  show={showRefundOverlay === index}
                                  onEnter={() => {
                                    setAmount(val.balance);
                                  }}
                                  overlay={
                                    <Popover
                                      id="popover-basic"
                                      className="relative"
                                    >
                                      <button
                                        type="button"
                                        className="absolute flex right-0"
                                        onClick={() =>
                                          setRefundShowOverlay(null)
                                        }
                                      >
                                        <FaTimes />
                                      </button>
                                      <Popover.Title
                                        as="h3"
                                        className="text-lg border-b-2 border-neutral-100 pb-2 font-pop font-medium"
                                      >
                                        Refund From Payment {val.balance}
                                      </Popover.Title>
                                      <Popover.Content>
                                        <div className="!px-2">
                                          <input
                                            className="form-control"
                                            placeholder="Amount"
                                            value={amount}
                                            onChange={(e) =>
                                              setAmount(e.target.value)
                                            }
                                          />
                                          <div className="flex justify-end w-full">
                                            <Button
                                              text="Click to Refund"
                                              className="mt-1 focus:bg-primary"
                                              color="#fff"
                                              onClick={() => {
                                                handleRefund({
                                                  job_payment_id: val._id,
                                                  amount: amount,
                                                });
                                              }}
                                              variant="btn_submit"
                                            />
                                          </div>
                                        </div>
                                      </Popover.Content>
                                    </Popover>
                                  }
                                  rootClose
                                >
                                  <span
                                    className="cursor-pointer flex items-center"
                                    onClick={() => setRefundShowOverlay(index)}
                                  >
                                    <FaEdit className="my_navIcon hover:text-primary" />
                                  </span>
                                </OverlayTrigger>
                              </div>
                            </Dropdown.ItemText>
                          )}
                      </>
                    ) : null;
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
          {transactions?.filter(({ payment_type }) => payment_type === "Auth")
            ?.length > 0 && (
            <div className="!mt-3 relative">
              <Dropdown show={showCaptureVoid}>
                <Dropdown.Toggle
                  onClick={() => setShowCaptureVoid(!showCaptureVoid)}
                  id="dropdown-basic"
                  className="rounded-0 w-auto min-w-[200px] py-1.5 px-3 !border-primary-100 !bg-primary-100 !mb-2 focus:!shadow-none"
                >
                  Capture & Void
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className="w-auto min-w-[200px] flex flex-row "
                  ref={voidRef}
                >
                  {(
                    transactions?.filter(
                      ({ payment_type }) => payment_type === "Auth"
                    ) || []
                  )?.map((val, index) => {
                    return val?.payment_type === "Auth" ? (
                      <Dropdown.ItemText
                        className=" mt-1 mx-1.5 flex flex-col"
                        key={index}
                      >
                        <div className="flex flex-row items-center justify-between pl-1">
                          <div className="flex items-center">
                            <FaCreditCard />
                            <span className="ml-2">Payment ${val.balance}</span>
                          </div>
                          <span className="cursor-pointer flex items-center">
                            <FaCheckCircle
                              className="my_navIcon hover:text-primary"
                              title="Capture the Payment"
                              onClick={() => {
                                handleCaptureVoid({
                                  job_payment_id: val._id,
                                  amount: val.balance,
                                  type: "capture",
                                });
                              }}
                            />
                            <FaTrash
                              className="my_navIcon hover:text-primary"
                              title="Void the Payment"
                              onClick={() => {
                                handleCaptureVoid({
                                  job_payment_id: val._id,
                                  amount: val.balance,
                                  type: "void",
                                });
                              }}
                            />
                          </span>
                        </div>
                      </Dropdown.ItemText>
                    ) : null;
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          )}
        </div>
        <style jsx>{`
          @media (max-width: 768px) {
            .react-tabs__tab {
              display: block;
            }
          }
        `}</style>
        <div
          className={[
            "md:bg-white md:p-3.7 rounded-xl md:shadow-[0_4px_20px_rgba(59,_89,_153,_0.15)] !mb-2",
          ].join(" ")}
        >
          <div className="flex flex-wrap ">
            <div className="md:px-2 w-full">
              <Tabs>
                <TabList className="pl-0 react-tabs__tab-list border-bottom">
                  <Tab>Credit Card</Tab>
                  <Tab>Zelle</Tab>
                  <Tab>Venmo</Tab>
                  <Tab>CashApp</Tab>
                  <Tab>Vendor Pay</Tab>
                  <Button
                    text="Save"
                    className="btn mr-0 float-right rounded-0 w-full block !mt-4 !mb-4 md:!mr-2 md:!mt-0 md:!mb-0 mb:inline-block md:w-auto"
                    color="#fff"
                    onClick={handlePayment}
                    variant="btn_submit"
                  />
                </TabList>

                <TabPanel>
                  <div className="md:grid grid-cols-12 gap-x-1">
                    <div className="md:px-2 py-0 col-span-4 my-3">
                      <FormSelectInput
                        errors={errors}
                        formik={formik}
                        name="card_type_id"
                        label="Card Type"
                        labelProp="name"
                        valueProp="_id"
                        options={cardTypes?.cardTypesDrd}
                      />
                    </div>
                    <div className="md:px-2 py-0 col-span-4 my-3">
                      <FormInput
                        errors={errors}
                        formik={formik}
                        name="card_number"
                        label="Card Number"
                        type="number"
                      />
                    </div>
                    <div className="md:px-2 py-0 col-span-4 my-3">
                      <FormInput
                        errors={errors}
                        formik={formik}
                        name="card_holder_name"
                        label="Card Holder Name"
                        type="text"
                      />
                    </div>
                    <div className="md:px-2 py-0 col-span-2 my-3">
                      <FormSelectInput
                        errors={errors}
                        formik={formik}
                        name="card_month_id"
                        label="Month"
                        labelProp="fullName"
                        valueProp="_id"
                        options={months?.months || []}
                      />
                    </div>
                    <div className="md:px-2 py-0 col-xs-6 col-span-2 my-3">
                      <FormInput
                        errors={errors}
                        formik={formik}
                        name="card_year"
                        label="Year"
                        type="number"
                      />
                    </div>
                    <div className="md:px-2 py-0 col-span-2 my-3">
                      <FormInput
                        errors={errors}
                        formik={formik}
                        name="card_CVV"
                        label="CVV"
                        type="text"
                      />
                    </div>
                    <div className="md:px-2 py-0 col-span-2 my-3">
                      <FormInput
                        errors={errors}
                        formik={formik}
                        name="card_zip"
                        label="Zip"
                        type="text"
                      />
                    </div>
                    <div className="md:px-2 py-0 col-span-2 my-3">
                      <FormInput
                        errors={errors}
                        formik={formik}
                        name="card_state"
                        label="State"
                        type="text"
                      />
                    </div>
                    <div className="md:px-2 py-0 col-span-2 my-3">
                      <FormInput
                        errors={errors}
                        formik={formik}
                        name="card_city"
                        label="City"
                        type="text"
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="md:px-2 py-0 w-1/2">
                    <FormInput
                      errors={errors}
                      formik={formik}
                      name="zelle_auth_number"
                      label="Authorization Number"
                      type="number"
                    />
                  </div>
                </TabPanel>

                <TabPanel>
                  <div className="md:px-2 py-0 w-1/2">
                    <FormInput
                      errors={errors}
                      formik={formik}
                      name="venmo"
                      label="Venmo"
                      type="text"
                    />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="md:px-2 py-0 w-1/2">
                    <FormInput
                      errors={errors}
                      formik={formik}
                      name="cash_app"
                      label="Cash App"
                      type="text"
                    />
                  </div>
                </TabPanel>
                <TabPanel>
                  <div className="md:px-2 py-0 w-1/2">
                    <FormInput
                      errors={errors}
                      formik={formik}
                      name="vendor_pay"
                      label="Vendor Pay"
                      type="text"
                    />
                  </div>
                </TabPanel>
              </Tabs>
              <div className="flex flex-col md:flex-row mt-3">
                <div className="!px-2 py-0 w-full flex flex-col max-h-[100px] overflow-y-auto">
                  {jobNotes?.length > 0 &&
                    jobNotes
                      ?.filter(({ note_for }) => note_for === "Payment")
                      ?.map((note, index) => {
                        return (
                          <>
                            {note?.note_for === "Payment" && (
                              <span className="text-ellipsis" key={index}>
                                {index + 1}. {note.notes}
                              </span>
                            )}
                          </>
                        );
                      })}
                </div>
                <div className="!px-2 py-0 ">
                  <Button
                    text="Add Note"
                    className="btn !ml-2 float-right rounded-0 w-full md:w-auto"
                    color="#fff"
                    onClick={() => setIsNotes(!isNotes)}
                    variant="btn_submit"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentOption;
