import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { DotsLoader } from "../../../../components";
import MUIDataTable from "../../../../components/molecules/DataTable/muigrid";
import PageHeader from "../../../../components/molecules/PageHeader";
import AddUpdateForm from "./AddUpdateForm";
import api from "../../../../services/api";
import { toast } from "react-toastify";
import { FaTrashAlt } from "react-icons/fa";
import { FaPhoneSlash, FaDownload, FaFileExport } from "react-icons/fa6";
import { HiOutlineRefresh } from "react-icons/hi";
import { FaPlus, FaFileImport, FaSync } from "react-icons/fa";
import { MdAddCall } from "react-icons/md";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { FaSearch } from "react-icons/fa";
import { Button as MUIButton } from "@mui/material";
import DownloadExcelForm from "./DownloadExcelForm";
import ExportDidToCallcenter from "./ExportDidToCallcenter";
import { get_cc_cards, get_cc_providers } from "../../../../features/didSlice";
import { get_industry_drd } from "../../../../features/IndustrySlice";
import { get_dialer_users } from "../../../../features/usersSlice";
import { useSelector, useDispatch } from "react-redux";
import { get_inbound_group } from "../../../../features/inboundGroupSlice";
import Swal from "sweetalert2";
import { get_affiliate } from "../../../../features/callCentersSlice";
import UnregisterDids from "../UnregisterDids";
import ImportDIDsModal from "./ImportDIDsModal";
import AddDidToMsgCenter from "./AddDidToMsgCenter";
import MessageModal from "../../../Merchant/MessageModal";
import AddDidToMerchant from "./AddDidToMerchant";
import { userPermissionNew } from "../../../../util/userPermissionNew";
import CustomMuiTextInput from "./CustomMuiTextInput ";
import CustomMuiSelectInput from "./CustomMuiSelectInput";
function PurchaseDids() {
  let users = localStorage.getItem("user");
  users = users ? JSON.parse(users) : null;
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [callManu, setCallMenu] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isLoader, setIsLoading] = React.useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [didList, setDidList] = useState([]);
  const [isDownloadExcel, setDownloadExcel] = React.useState(false);
  const [isSyncToInteqliquent, setSyncToInteqliquent] = React.useState(false);
  const [isAddingToMS, setIsAddingToMS] = useState(false);
  const { isLoading } = useSelector((state) => state.dids);
  const [isUnregisterDids, setUnregisterDids] = React.useState(false);
  const [isImportForm, setIsImportForm] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [isCustomMessage, setCustomMessage] = React.useState({
    status: false,
    did: null,
  });
  const [isMerchantModalData, setMerchantModalData] = React.useState({
    status: false,
    did: null,
  });
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 100,
    page: 1,
  });
  const [queryOptions, setQueryOptions] = React.useState({});
  const cancelFormHandler = () => {
    setIsEditing(false);
    setDownloadExcel(false);
    setSyncToInteqliquent(false);
    setIsAddingToMS(false);
    setUnregisterDids(false);
    setIsImportForm(false);
  };

  const get_call_menus = async () => {
    try {
      const res = await api.get("/api/cc/dids/options/call_menus");
      if (res.status === 200) {
        setCallMenu(res.data);
      }
    } catch (err) {
      console.log("🚀 ~ constget_call_menus=async ~ err:", err);
    }
  };
  const getDidList = async (filters) => {
    const payload = {
      page: filters.page,
      limit: filters.limit,
      ...(filters.filters || {}),
    };

    setIsLoading(true);
    try {
      const res = await api.post("/api/cc/dids/purchase_did_list", payload);
      if (res.status === 200) {
        setDidList(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    dispatch(get_affiliate());
    dispatch(get_industry_drd());
    dispatch(get_cc_cards());
    dispatch(get_cc_providers());
    dispatch(get_dialer_users());
    dispatch(get_inbound_group());
    get_call_menus();
    getDidList({ page: 1, limit: paginationModel.pageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const openFormHandler = (record) => () => {
    setIsEditing(true);
  };

  const onSelectAll = (checked) => {
    if (checked) {
      const updatedArray = didList?.data?.map((item) => item);
      setSelectedData(updatedArray);
    } else {
      setSelectedData([]);
    }
  };

  const onSingleSelect = ({ checked, data }) => {
    if (isLoading) {
      return toast.error("Please wait until the previous process is complete.");
    }
    try {
      if (checked) {
        setSelectedData((prevSelectedData) => {
          if (!prevSelectedData.some(({ _id }) => _id === data._id)) {
            return [...prevSelectedData, data];
          }
          return prevSelectedData;
        });
      } else {
        setSelectedData((prevSelectedData) => {
          // Remove item from the selected list
          return prevSelectedData.filter(({ _id }) => _id !== data._id);
        });
      }
    } catch (err) {
      console.error("Error in onSingleSelect:", err);
    }
  };

  const isSelected = (data) => {
    return selectedData.some(({ _id }) => _id === data._id);
  };

  const columnDefs = [
    {
      field: "checkbox",
      renderHeader: (params) => (
        <input
          type="checkbox"
          onChange={(e) => onSelectAll(e.target.checked)}
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
          checked={
            didList?.data?.length &&
            selectedData?.length === didList?.data?.length
          }
          disabled={!didList?.data?.length}
        />
      ),
      filterable: false,
      sortable: false,
      width: 60,
      renderCell: (params) => (
        <input
          type="checkbox"
          checked={isSelected(params.row.records)}
          onChange={(e) =>
            onSingleSelect({
              checked: e.target.checked,
              data: params.row.records,
            })
          }
          className={`form-checkbox h-5 w-5 text-primary-100 roundd focus:ring-0 cursor-pointer mr-2`}
        />
      ),
      disableColumnMenu: true,
    },
    { headerName: "#", field: "counter", width: 10, filterable: false },
    {
      headerName: "DID",
      field: "did",
      flex: 1,
      minWidth: 100,
      filterOperators: [
        {
          label: "equals",
          value: "is",
          InputComponent: CustomMuiTextInput,
          getApplyFilterFn: (filterItem) => {
            return (params) => {
              return params.value === filterItem.value;
            };
          },
        },
      ],
    },
    {
      headerName: "DID Type",
      field: "phone_type",
      flex: 1,
      minWidth: 130,
      type: "singleSelect",
      filterOperators: [
        {
          label: "equals",
          value: "is",
          InputComponent: CustomMuiSelectInput,
          InputComponentProps: {
            options: [
              { value: "Call", label: "Call" },
              { value: "SMS", label: "SMS" },
            ],
          },
          getApplyFilterFn: (filterItem) => {
            return (params) => {
              return params.value === filterItem.value;
            };
          },
        },
      ],
      renderCell: (params) => {
        return (
          <>
            <div className="flex flex-col">
              {params?.row?.phone_type === "SMS" ? (
                <>
                  <button
                    onClick={() => updateDIDToMerchant(params)}
                    className="w-full inline-flex items-center justify-start space-x-1"
                    title="Add DID to Merchant"
                  >
                    <span className="flex items-center hover:cursor-pointer text-sm">
                      <span>{params?.value}</span>
                      <MdAddCall className="w-5 h-5 ml-1" />
                    </span>
                  </button>

                  <small
                    onClick={() => handleTriggerSms(params)}
                    title="Trigger Test SMS"
                    className="text-blue-500 hover:cursor-pointer text-xs mt-2"
                  >
                    Click here to Trigger SMS
                  </small>
                </>
              ) : (
                <span>{params?.value}</span>
              )}
            </div>
          </>
        );
      },
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      minWidth: 100,
      filterable: false,
      renderCell: (params) => (
        <div className="flex flex-col">
          {params?.row?.deleted_on ? (
            <span>Deleted On</span>
          ) : (
            params.row.tnStatus
          )}
          {params?.row?.deleted_on ? (
            <span>
              {dayjs(params?.row?.deleted_on).format("ddd, MMM D, YYYY h:mm A")}
            </span>
          ) : null}
        </div>
      ),
    },
    {
      headerName: "Purch. For",
      field: "purchased_for",
      flex: 1,
      minWidth: 100,
      filterOperators: [
        {
          label: "equals",
          value: "is",
          InputComponent: CustomMuiTextInput,
          getApplyFilterFn: (filterItem) => {
            return (params) => {
              return params.value === filterItem.value;
            };
          },
        },
      ],
    },
    {
      headerName: "Provider",
      field: "provider",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      headerName: "Description",
      field: "description",
      flex: 1,
      minWidth: 100,
      filterable: false,
    },
    {
      headerName: "Created At",
      field: "created_at",
      flex: 1,
      minWidth: 120,
      filterable: false,
    },
  ];

  const onFormSubmit = () => {
    setIsEditing(false);
    getDidList({ page: 1, limit: paginationModel.pageSize });
  };

  const onFilterChange = React.useCallback((filterModel) => {
    if (filterModel?.items?.length === 0) {
      getDidList({
        page: 1,
        limit: paginationModel.pageSize,
      });
    }
    const filters = filterModel.items?.map((rule) => ({
      [`${rule.field}`]: rule.value,
    }));

    setQueryOptions((prevState) => {
      const newQueryOptions = filters.reduce((acc, filter) => {
        return { ...acc, ...filter };
      }, {});
      return newQueryOptions;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    getDidList({
      filters: queryOptions,
      page: 1,
      limit: paginationModel.pageSize,
    });
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer>
        {/* <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" /> */}
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Apply filter
        </MUIButton>
        <MUIButton
          variant="text"
          onClick={unregisteredDid}
          startIcon={<FaPhoneSlash className="h-5 w-5" />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Unregister DID from service provider
        </MUIButton>
        <MUIButton
          variant="text"
          onClick={downloadExcel}
          startIcon={<FaDownload className="h-4 w-4" />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Export
        </MUIButton>
        <MUIButton
          variant="text"
          onClick={syncToInteliquent}
          startIcon={<FaFileExport className="h-4 w-4" />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Add DID to CC
        </MUIButton>
        <MUIButton
          variant="text"
          onClick={handleAddToMS}
          startIcon={<FaFileExport className="h-4 w-4" />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
        >
          Add DID to MC
        </MUIButton>
        <MUIButton
          variant="text"
          onClick={handleSyncCCstatus}
          startIcon={<FaSync className="h-4 w-4" />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
          title="Sync Call Center Status"
        >
          Sync CC Status
        </MUIButton>
        <MUIButton
          variant="text"
          onClick={handleBulkDelete}
          startIcon={<FaTrashAlt className="h-4 w-4" />}
          sx={{ fontSize: "0.8125rem" }}
          className={"!text-[#042a42]"}
          title="Delete DIDs from Call Center"
        >
          Delete Selected
        </MUIButton>
      </GridToolbarContainer>
    );
  }

  const unregisteredDid = async () => {
    if (selectedData?.length) {
      setUnregisterDids(true);
    } else {
      toast.error("Please select at least one DID.");
    }
  };
  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    getDidList({
      filters: queryOptions,
      page: +params.page + 1,
      limit: params.pageSize,
    });
  };

  const downloadExcel = async () => {
    if (selectedData?.length) {
      setDownloadExcel(true);
    } else {
      toast.error("Please select at least one DID.");
    }
  };

  const syncToInteliquent = async () => {
    if (selectedData?.length) {
      setSyncToInteqliquent(true);
    } else {
      toast.error("Please select at least one DID.");
    }
  };

  const handleAddToMS = async () => {
    if (selectedData?.length) {
      setIsAddingToMS(true);
    } else {
      toast.error("Please select at least one DID.");
    }
  };

  const exportFormSubmit = () => {
    setSyncToInteqliquent(false);
    getDidList({ page: 1, limit: paginationModel.pageSize });
  };

  const handleBulkDelete = async () => {
    if (!selectedData?.length) {
      toast.error("Please select at least one DID.");
      return;
    }

    Swal.fire({
      title: "Are you sure to perform this action?",
      text: "DIDs will be deleted from the Call Center only.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        popup: "w-90 p-4 text-sm",
        title: "text-lg font-bold",
        content: "text-sm font-semibold",
        confirmButton: "text-sm py-2 px-4",
        cancelButton: "text-sm py-2 px-4",
      },
    }).then(async (result) => {
      if (!result.isConfirmed) return;
      setIsLoading(true);
      try {
        const updatedArray = selectedData.map((data) => ({
          did: "1" + data.did,
        }));

        let searchCondition = [];
        searchCondition.push(
          `did_pattern IN (${updatedArray.map((d) => `"${d.did}"`).join(",")})`
        );

        const searchStr =
          searchCondition.length > 0 ? searchCondition.join(` AND `) : "";

        const res = await api.post(`/api/cc/dids/get_dids`, {
          page: paginationModel?.page,
          size: paginationModel?.pageSize,
          sqlwhere: searchStr,
        });
        if (res?.status === 200 || res?.status === 201) {
          const [did_data, didPattern] = res.data?.data?.reduce(
            (acc, record) => {
              acc[0].push({ did_id: record?.did_id });
              acc[1].push(record?.did_pattern.slice(-10));
              return acc;
            },
            [[], []]
          );

          if (!did_data.length || !didPattern.length) {
            toast.error("No data available for deletion.");
            return;
          }

          try {
            const { data } = await api.post("/api/cc/dids/bulk_delete", {
              did_data: did_data,
              did_patterns: didPattern,
            });

            if (data?.status) {
              setSelectedData([]);
              toast.success(data?.message);
              getDidList({
                filters: queryOptions,
                page: 1,
                limit: paginationModel.pageSize,
              });
            } else {
              toast.error("Data couldn't be deleted");
            }
          } catch (err) {
            toast.error("An error occurred during deletion");
            console.error(err);
          }
        } else {
          toast.error("Failed to retrieve DIDs for deletion");
        }
      } catch (err) {
        toast.error("An error occurred while fetching DIDs");
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    });
  };

  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;

  const CustomButtons = () => {
    return (
      <div className="flex space-x-1">
        <button
          className="py-1.5 !px-3 align-middle bg-primary-100 border-primary-100 text-white float-right flex items-center rounded-0"
          onClick={openFormHandler(0)}
          // disabled={selectedData?.length > 0 ? false : true}
        >
          <FaPlus className="mr-2" />
          Add New
        </button>
        {/* <button
          className="py-1.5 !px-3 align-middle bg-primary-100 border-primary-100 text-white float-right flex items-center rounded-0"
          onClick={() => setIsImportForm(true)}
        >
          <FaFileImport className="mr-2" />
          Import DIDs
        </button> */}
        <button
          className="py-1.5 !px-3 align-middle bg-primary-100 border-primary-100 text-white float-right flex items-center rounded-0"
          onClick={() => {
            getDidList({
              filters: queryOptions,
              page: 1,
              size: paginationModel.pageSize,
            });
            setSelectedData([]);
          }}
        >
          <HiOutlineRefresh className="w-5 h-5" />
        </button>
      </div>
    );
  };

  const reloadListing = () => {
    setIsImportForm(false);
    getDidList({
      filters: queryOptions,
      page: 1,
      limit: paginationModel.pageSize,
    });
  };

  const getRowHeight = (params) => {
    const rowHeight = 40;
    const data = params?.model;
    const numberOfLines = data?.deleted_ondeleted_on
      ? Math.ceil(Object.keys(data?.deleted_on).length / 40)
      : 0;
    const auctionHeight = rowHeight + numberOfLines * 30 + 20;
    return auctionHeight;
  };

  const handleTriggerSms = (params) => {
    setCustomMessage({
      ...isCustomMessage,
      status: true,
      did: "1" + params?.row?.did,
    });
  };

  const updateDIDToMerchant = (params) => {
    getMerchantList();
    setMerchantModalData({
      ...isMerchantModalData,
      status: true,
      did: "1" + params?.row?.did,
    });
  };

  const getMerchantList = async () => {
    setIsLoading(true);
    try {
      const res = await api.get(`/api/merchants/${users?.current_merchant_id}`);
      if (res.status === 200 || res.status === 201) {
        setMerchants(res?.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const handleSyncCCstatus = async () => {
    const c = window.confirm(`Are you sure want to sync Call Center status?`);
    if (!c) return;
    try {
      const res = await api.post("/api/cc/dids/syncDidsWithCC");
      if (res?.status === 200 || res?.status === 201) {
        toast.success(`Call Center syncing status has started successfully.`);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {isLoader ? <DotsLoader /> : null}
      <PageHeader heading="Purchase DIDs" CustomButtons={CustomButtons} />
      {isEditing && (
        <AddUpdateForm
          modalTitle="Purchase DIDs"
          onCancelForm={cancelFormHandler}
          onFormSubmit={onFormSubmit}
        />
      )}

      {isDownloadExcel ? (
        <DownloadExcelForm
          modalTitle="Export Excel"
          onCancelForm={cancelFormHandler}
          selectedData={selectedData}
        />
      ) : null}
      {isUnregisterDids ? (
        <UnregisterDids
          modalTitle="Unregister DID from service provider"
          onCancelForm={cancelFormHandler}
          selectedData={selectedData}
          methodType="purchase_dids"
          onFormSubmit={onFormSubmit}
        />
      ) : null}
      {isSyncToInteqliquent ? (
        <ExportDidToCallcenter
          modalTitle="Export DID To Call Center"
          onCancelForm={cancelFormHandler}
          selectedData={selectedData}
          callManu={callManu}
          exportFormSubmit={exportFormSubmit}
        />
      ) : null}
      {isAddingToMS ? (
        <AddDidToMsgCenter
          modalTitle="Export DID To Message Center"
          onCancelForm={cancelFormHandler}
          selectedData={selectedData}
        />
      ) : null}

      {isImportForm && (
        <ImportDIDsModal
          modalTitle="Import DIDs"
          onCancelForm={cancelFormHandler}
          reloadListing={reloadListing}
        />
      )}
      {isCustomMessage?.status && (
        <MessageModal
          onClosemodal={() =>
            setCustomMessage({ ...isCustomMessage, status: false, did: null })
          }
          isCustomMessage={isCustomMessage}
        />
      )}
      {isMerchantModalData?.status && merchants?._id ? (
        <AddDidToMerchant
          modalTitle="Add DID To Merchant"
          onCancelForm={() => {
            setMerchantModalData({
              ...isMerchantModalData,
              status: false,
              did: null,
            });
            setMerchants([]);
          }}
          selectedData={isMerchantModalData}
          editingRecord={merchants}
        />
      ) : null}
      <div className="bg-white !my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={didList?.data?.map((item, index) => {
            let counter = index + offset + 1;
            let created_at = dayjs(item?.createdAt).format(
              "ddd, MMM D, YYYY h:mm A"
            );
            const {
              did,
              provider,
              phone_type,
              purchased_for,
              deleted_on,
              description,
              tnStatus,
            } = item;
            return {
              records: { ...item },
              counter,
              did,
              provider,
              phone_type,
              purchased_for,
              deleted_on,
              description,
              created_at,
              tnStatus,
            };
          })}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          searchable="No"
          totalItems={didList?.total}
          //isLoading={isLoader}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onFilterModelChange={onFilterChange}
          CustomToolbar={CustomToolbar}
          gridOptions={getRowHeight}
          height="calc(100vh - 260px)"
        />
      </div>
    </>
  );
}

export default PurchaseDids;
